import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  merge,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ParksService } from 'src/app/features/portal/_services/parks.service';
import { PortalRegisterService } from 'src/app/features/portal/_services/portal-register.service';
import { confirmPasswordValidator } from 'src/app/_share/_validators/confirm-password.validator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/_share/_services/notification.service';

@Component({
  selector: 'app-portal-register-uk',
  templateUrl: './portal-register-uk.component.html',
  styleUrls: ['./portal-register-uk.component.scss'],
})
export class PortalRegisterUkComponent implements OnInit {
  selectedPackage: any;
  stateOptions = [
    { label: 'New User', value: 'new' },
    { label: 'Existing User', value: 'existing' },
  ];
  paymentLoading: boolean = false;
  userType: any;
  constructor(
    public fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private stripeService: StripeService,
    private userRegisterService: PortalRegisterService,
    private notificationService: NotificationService,
    private parksService: ParksService,
    private http: HttpClient,
  ) {}

  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  @ViewChild('planDropdown') planDropdown: ElementRef = new ElementRef(null);

  ukPhoneNumberRegex: RegExp = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;

  registrationSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  portalTopupForm = this.fb.group({
    email: ['', Validators.required],
    account_code: ['', Validators.required],
  });

  portalUserRegisterForm = this.fb.group(
    {
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile_number: [
        '',
        [Validators.required, Validators.pattern(this.ukPhoneNumberRegex)],
      ],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirm_password: ['', [Validators.required]],
      // plan: ['', Validators.required],
    },
    { validator: confirmPasswordValidator },
  );

  packages: any[] = [
    // 10 devices - effective from 1st July 2024
    {
      name: '1 Day - 10 Devices',
      value: '1day-10device',
      price: 6,
      enabled: true,
    },
    {
      name: '3 Days - 10 Devices',
      value: '3day-10device',
      price: 14,
      enabled: true,
    },
    {
      name: '7 Days - 10 Devices',
      value: '7day-10device',
      price: 23,
      enabled: true,
    },
    {
      name: '30 Days - 10 Devices',
      value: '30day-10device',
      price: 45,
    },
    {
      name: 'Seasonal - 10 Devices',
      value: 'Season-10device',
      price: 384,
      enabled: true,
    },
  ];

  titles: any[] = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Ms', value: 'Ms' },
    { value: 'Mrs', label: 'Mrs' },
  ];

  customer_signup_options = [
    { label: 'New', value: 'new' },
    { label: 'Existing', value: 'existing' },
  ];

  errorMessage: string = '';
  public cardOptions: StripeCardElementOptions = {
    disableLink: true,
    hidePostalCode: true,
    style: {
      invalid: {
        color: '#FF0000',
      },

      //   base: {
      //     fontWeight: 400,
      //     fontFamily: 'Circular',
      //     fontSize: '14px',
      //     iconColor: '#666EE8',
      //     color: '#002333',
      //     '::placeholder': {
      //       color: '#919191',
      //     },
      //   },
    },

    classes: {
      base: 'p-inputtext',
      complete: 'is-valid',
      empty: 'is-invalid',
      focus: 'is-valid',
      invalid: 'is-invalid',
      webkitAutofill: 'is-valid',
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripe = this.stripeService.stripe;

  paymentAmount: number = 0.0;

  ngOnInit(): void {
    this.errorMessage = '';

    if (this.config.data.dialogDetails.park.name == 'Quarry Walk Park') {
      this.packages = [
        {
          name: '1 Day - 5 Devices',
          value: '1day-5device',
          price: 10,
          enabled: true,
        },
        {
          name: '3 Days - 5 Devices',
          value: '3day-5device',
          price: 15,
          enabled: true,
        },
        {
          name: '7 Days - 5 Devices',
          value: '7day-5device',
          price: 25,
          enabled: true,
        },
      ];
    }
  }

  onRegisterSubmit() {
    console.log('form values', this.portalUserRegisterForm.value);
    console.log('form valid', this.portalUserRegisterForm.valid);

    console.log('form errors', this.portalUserRegisterForm.errors);

    if (this.portalUserRegisterForm.valid) {
      // this.ref.close(this.portalUserRegisterForm.value);
      // todo: add this details to a stripe form
      this.registrationSuccess$.next(true);
      this.planDropdown.nativeElement.focus();

      // this.userRegisterService
      //   .registerUser(this.portalUserRegisterForm.value)
      //   .subscribe(
      //     (res) => {
      //       console.log('res', res);
      //       this.ref.close(this.portalUserRegisterForm.value);
      //     },
      //     (err) => {
      //       console.log('err', err);
      //       this.errorMessage =
      //         err.error.detail.detail + ' - ' + err.error.detail.message;
      //       this.notificationService.failedPortal();
      //     }
      //   );
    } else {
      this.errorMessage = 'Please fill in all required fields correctly';
    }
  }

  paymentForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    amount: [0, Validators.required],
    plan: ['', Validators.required],
    account_code: [''],
  });
  /*
@deprecated
*/
  payOld(): void {
    this.paymentLoading = true;
    this.paymentForm.patchValue({
      name:
        this.portalUserRegisterForm.get('first_name')?.value +
        ' ' +
        this.portalUserRegisterForm.get('last_name')?.value,
      email: this.portalUserRegisterForm.get('email')?.value,
      amount: this.paymentAmount,
    });

    const paymentFormForSubmit = {
      amount: this.paymentAmount,
      currency: 'gbp',
      email: this.portalUserRegisterForm.get('email')?.value,
      name:
        this.portalUserRegisterForm.get('first_name')?.value +
        ' ' +
        this.portalUserRegisterForm.get('last_name')?.value,
      plan: this.selectedPackage.value,
      // change account code to the entered account code
      account_code: '',
    };

    console.log('validity', this.paymentForm.valid);
    console.log('card', this.card);
    if (this.paymentForm.valid) {
      this.createPaymentIntent(paymentFormForSubmit)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: paymentFormForSubmit.name,
                  email: paymentFormForSubmit.email,
                  phone:
                    this.portalUserRegisterForm.get('mobile_number')?.value,
                },
              },
            }),
          ),
        )
        .subscribe((result: any) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            window.alert('Payment was not successful');

            // todo: show customer failed dialog
          } else {
            // The payment has been processed!
            console.log('result', result);
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              console.log('success');

              // this.ref.close(this.portalUserRegisterForm.value);
            }
            console.log('package details', this.selectedPackage);
            // todo: show customer success dialog
            this.parksService
              .buyPackageAndRegisterUser({
                ...this.portalUserRegisterForm.value,
                park_name: this.config.data.dialogDetails.park.name,
                product: this.selectedPackage,
              })
              .subscribe(
                (res) => {
                  console.log('res', res);
                  this.paymentLoading = false;

                  // send data to status dialog
                  this.ref.close({
                    ...res.data,
                    status: res.status,
                    success: true,
                    package: this.getPackageDetails(this.selectedPackage),
                    customer: this.portalUserRegisterForm.value,
                  });
                },
                (err) => {
                  console.log('err', err);
                  this.errorMessage =
                    err.error.detail.detail + ' - ' + err.error.detail.message;
                  this.paymentLoading = false;
                  // this.notificationService.failedPortal();
                },
              );
          }
        });
    } else {
      console.log(this.paymentForm);
    }
  }

  pay(): void {
    this.paymentLoading = true;
    this.errorMessage = ''; // Reset error message at the start
    this.paymentForm.patchValue({
      name: `${this.portalUserRegisterForm.get('first_name')?.value} ${this.portalUserRegisterForm.get('last_name')?.value}`,
      email: this.portalUserRegisterForm.get('email')?.value,
      amount: this.paymentAmount,
    });

    const paymentFormForSubmit = {
      amount: this.paymentAmount,
      currency: 'gbp',
      email: this.portalUserRegisterForm.get('email')?.value,
      first_name: String(
        this.portalUserRegisterForm.get('first_name')?.value || '',
      ).trim(),
      last_name: String(
        this.portalUserRegisterForm.get('last_name')?.value || '',
      ).trim(),
      plan: this.selectedPackage.value,
      mobile_number: String(
        this.portalUserRegisterForm.get('mobile_number')?.value || '',
      ),
      account_code: '',
    };

    if (this.paymentForm.valid) {
      this.parksService
        .buyPackageAndRegisterUser({
          ...this.portalUserRegisterForm.value,
          park_name: this.config.data.dialogDetails.park.name,
          product: this.selectedPackage,
        })
        .pipe(
          switchMap((res) =>
            this.createPaymentIntent(paymentFormForSubmit).pipe(
              switchMap((pi) =>
                this.stripeService
                  .confirmCardPayment(pi.client_secret, {
                    payment_method: {
                      card: this.card.element,
                      billing_details: {
                        name:
                          paymentFormForSubmit.first_name +
                          ' ' +
                          paymentFormForSubmit.last_name,
                        email: paymentFormForSubmit.email,
                        phone:
                          this.portalUserRegisterForm.get('mobile_number')
                            ?.value,
                      },
                    },
                  })
                  .pipe(
                    tap((result: any) => {
                      if (result.error) {
                        console.error(result.error.message);
                        this.errorMessage = result.error.message; // Display error message on the form
                        throw new Error(result.error.message); // Skip further steps on payment failure
                      } else if (result.paymentIntent.status === 'succeeded') {
                        console.log('Payment successful');
                      }
                    }),
                    switchMap(() => {
                      // Return package details on successful payment
                      return of({
                        ...res.data,
                        status: res.status,
                        success: true,
                        package: this.getPackageDetails(this.selectedPackage),
                        customer: this.portalUserRegisterForm.value,
                      });
                    }),
                  ),
              ),
            ),
          ),
          tap((finalResult) => {
            if (finalResult) {
              this.ref.close(finalResult); // Close dialog with the final result if successful
            }
          }),
          catchError((err) => {
            this.errorMessage =
              err.message || 'An error occurred during the process.';
            return of(null); // Handle the error without further processing
          }),
          finalize(() => {
            this.paymentLoading = false; // Ensure loading state is updated regardless of success/failure
          }),
        )
        .subscribe();
    } else {
      this.errorMessage = 'Please check the form for errors.';
      console.log('Form is invalid', this.paymentForm);
    }
  }

  createPaymentIntent(bodyData: any): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/portal/create-payment-intent`, bodyData)
      .pipe(
        map((res) => {
          console.log('res', res);
          return res;
        }),
      );
  }

  onCardChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError!.textContent = event.error.message;
    } else {
      displayError!.textContent = '';
    }
  }

  getPackageDetails(packageValue: string) {
    // console.log('value selected: ', packageValue);
    let packageDetails = { name: '', value: '', price: 0 };
    packageDetails = this.packages.filter((packageItem) => {
      return packageItem.value === packageValue; // Return true or false based on condition
    })[0];
    // console.log('package details: ', packageDetails);
    this.paymentAmount = packageDetails.price;
    return packageDetails;
  }
}
