import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TenantsService } from 'src/app/_share/_services/tenants.service';
import { Tenant } from 'src/app/_share/_models/entities/tenant';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent implements OnInit {
  tenants: any[] = [];
  tenants$: Subject<any[]> = new Subject<any[]>();
  tenantsQuery$: any;

  columns: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
  ];

  constructor(private tenantsServce: TenantsService) {}

  ngOnInit(): void {
    this.tenantsQuery$ = this.tenantsServce
      .getAll()
      .subscribe((tenants: Tenant[]) => {
        this.tenants = tenants;
        this.tenants$.next(this.tenants);
      });
  }

  ngOnDestroy() {
    this.tenantsQuery$.unsubscribe();
  }
}
