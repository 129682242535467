import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private api: ApiService) {}

  getDeviceStatistics() {
    return this.api.getAPI<any>('management/statistics/devices').pipe(
      map((res) => {
        console.log('device statistics', res.body.data);
        return res.body.data;
      })
    );
  }
}
