import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { PortalComponent } from './pages/portal/portal.component';
import { PortalRedirectComponent } from './pages/portal-redirect/portal-redirect.component';
import { PortalProfileComponent } from './components/portal-profile/portal-profile.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SupportComponent } from './pages/support/support.component';
import { CaptiveComponent } from './pages/captive/captive.component';
import { PortalRegisterUkComponent } from './components/portal-register-uk/portal-register-uk.component';
import { PortalFormComponent } from './components/portal-registration/portal-form.component';
import { PortalNavComponent } from './pages/portal-nav/portal-nav.component';
import { RegisterComponent } from './components/register/register.component';

const portalRoutes: Routes = [
  {
    path: 'redirect',
    component: PortalRedirectComponent,
  },

  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: '',
    component: PortalNavComponent,
    children: [
      {
        path: '',
        component: PortalComponent,
        pathMatch: 'full',
      },
      {
        path: 'profile',
        component: PortalProfileComponent,
      },
      {
        path: 'captive',
        component: CaptiveComponent,

        children: [
          {
            path: 'register',

            component: RegisterComponent,
          },
          {
            path: 'confirm',
            component: PortalFormComponent,
          },
          {
            path: 'package',
            component: PortalFormComponent,
          },
          {
            path: 'payment',
            component: PortalFormComponent,
          },
          {
            path: 'activate',
            component: PortalFormComponent,
          },
        ],
      },
      {
        path: ':action',
        component: PortalComponent,
      },
      {
        path: ':action/:step',
        component: PortalComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(portalRoutes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
