import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { LeadsService } from 'src/app/features/crm/_services/leads.service';

@Component({
  selector: 'app-key-individual-form',
  templateUrl: './key-individual-form.component.html',
  styleUrls: ['./key-individual-form.component.scss'],
})
export class KeyIndividualFormComponent implements OnInit {
  @Input() company_id: number = 0;

  keyIndividualForm: FormGroup<any> = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.email),
    phone: new FormControl(''),
    job_title: new FormControl(''),
    company_id: new FormControl(this.company_id, Validators.required),
  });
  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private leadsService: LeadsService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.company_id = this.config.data.company_id;
    if (this.config.data.formdata) {
      this.keyIndividualForm.patchValue(this.config.data.formdata);
    } else {
      this.keyIndividualForm.patchValue({
        company_id: this.company_id,
      });
    }
  }

  ngOnChanges() {
    if (this.config.data.formdata) {
      this.keyIndividualForm.patchValue(this.config.data.formdata);
    } else {
      this.keyIndividualForm.patchValue({
        company_id: this.company_id,
      });
    }
  }

  onKeyIndividualSubmit() {
    console.log('action - form valid:', this.keyIndividualForm.valid);
    console.log('action - form values:', this.keyIndividualForm.value);
    if (this.keyIndividualForm.valid) {
      console.log('key individual value', this.keyIndividualForm.value);

      const keyIndividual = this.keyIndividualForm.value;

      // this..contacts.append(keyIndividual);
      // todo: update in sito

      if (this.config.data.formdata) {
        this.leadsService.updateLeadContact(
          this.config.data.formdata.id,
          this.keyIndividualForm.value
        );
        this.notificationService.successfullyModifiedEntity(
          'update',
          'key individual'
        );
      } else {
        this.leadsService.addLeadContact(this.keyIndividualForm.value);
        this.notificationService.successfullyModifiedEntity(
          'create',
          'key individual'
        );
      }
      this.ref.close();
    }
  }
  onKeyIndividualDelete() {
    if (this.config.data.formdata) {
      this.leadsService.deleteLeadContact(this.config.data.formdata.id);
      this.notificationService.successfullyModifiedEntity(
        'delete',
        'key individual'
      );
      this.ref.close();
    }
  }
}
