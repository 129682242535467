import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions } from 'src/app/_share/_models/entities/actions';

import { LeadsService } from 'src/app/features/crm/_services/leads.service';

import { UserService } from 'src/app/_share/_services/user.service';
import { LeadActionsService } from '../../_services/lead-actions.service';

@Component({
  selector: 'app-actions-form',
  templateUrl: './actions-form.component.html',
  styleUrls: ['./actions-form.component.scss'],
})
export class ActionsFormComponent implements OnInit {
  @Input() lead_id: number = 0;
  @Input() action_owner_id: number = 0;

  groupedActionTypeOptions = this.generateGroupedActionTypeOptions();

  actionForm: FormGroup<any> = new FormGroup({
    action_type: new FormControl('', Validators.required),
    action_details: new FormControl('', Validators.required),
    lead_id: new FormControl(this.lead_id, Validators.required),
    action_owner_id: new FormControl(''),
    next_interaction_date: new FormControl(''),
    called: new FormControl(false, Validators.required),
  });

  constructor(
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private leadsService: LeadsService,
    private userService: UserService,
    private leadActionsService: LeadActionsService,
  ) {}

  ngOnInit(): void {
    this.lead_id = this.config.data.lead_id;
    this.action_owner_id = this.config.data.action_owner_id;

    this.actionForm.patchValue({
      lead_id: this.lead_id,
      action_owner_id: this.action_owner_id,
    });
    this.leadActionsService.getLeadActionsDefinitions();
  }

  onActionTypeChange($event: any) {
    const action_type = $event.value;
    console.log('action_type', action_type);
    if (
      this.leadActionsService.actionDefinitions[action_type]['defaultDetails']
    ) {
      this.actionForm.patchValue({
        action_details:
          this.leadActionsService.actionDefinitions[action_type][
            'defaultDetails'
          ],
      });
    } else {
      this.actionForm.patchValue({
        action_details: '',
      });
    }
  }

  formatDate(date: Date) {
    // dd-mm-yyyy

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Zero-pad the month
    const day = date.getDate().toString().padStart(2, '0'); // Zero-pad the day

    const formattedDate = year + '-' + month + '-' + day;

    return formattedDate;
  }

  generateGroupedActionTypeOptions() {
    const groupedActionTypeOptions: any = {};

    for (const key in Actions) {
      if (Object.prototype.hasOwnProperty.call(Actions, key)) {
        const action = Actions[key as keyof typeof Actions];

        // Determine the group label, icon, and value based on the class properties
        const groupLabel = action.group;
        const groupIcon = action.icon;

        // Create or update the group in groupedActionTypeOptions
        if (!groupedActionTypeOptions[groupLabel]) {
          groupedActionTypeOptions[groupLabel] = {
            label: groupLabel,
            value: groupIcon,
            items: [],
          };
        }

        // Add the action to the group's items
        groupedActionTypeOptions[groupLabel].items.push({
          label: action.toLabelString(),
          value: action.toValueString(),
        });
      }
    }

    // Convert the groupedActionTypeOptions object into an array
    const groupedActionTypeOptionsArray = Object.values(
      groupedActionTypeOptions,
    );

    console.log('groupedActionTypeOptionsArray', groupedActionTypeOptionsArray);
    return groupedActionTypeOptionsArray;
  }

  public getResultStateByKey(key: string): string | null {
    if (this.leadActionsService.actionDefinitions[key]['resultState']) {
      return this.leadActionsService.actionDefinitions[key]['resultState'];
    } else {
      return null;
    }
  }

  onActionFormSubmit() {
    // debugging
    console.log('action - form valid:', this.actionForm.valid);
    console.log('action - form touched:', this.actionForm.errors);
    console.log('action - form values:', this.actionForm.value);

    if (this.actionForm.valid) {
      // todo: push action to lead without recalling get api or submit and refresh for specific lead

      const action_type = this.actionForm.value.action_type;

      const result_state = this.getResultStateByKey(action_type);

      this.leadsService.addLeadAction(this.actionForm.value);

      // update lead status
      if (result_state) {
        this.leadsService.updateLead(this.actionForm.value.lead_id, {
          lead_status: result_state,
          called: this.actionForm.value.called,
          next_interaction_date: this.formatDate(
            this.actionForm.value.next_interaction_date,
          ),
          last_interaction_date: this.formatDate(new Date()),
          lead_owner_id: this.userService.currentUser.id,
        });
      } else {
        this.leadsService.updateLead(this.actionForm.value.lead_id, {
          next_interaction_date: this.formatDate(
            this.actionForm.value.next_interaction_date,
          ),
          last_interaction_date: this.formatDate(new Date()),
          lead_owner_id: this.userService.currentUser.id,
        });
      }

      // this.cacheService.removeItem('activeLeadID');
      this.ref.close();
    }
  }
}
