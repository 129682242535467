<!-- statistics summary -->
<div class="grid col-12">
  <ng-container [ngSwitch]="summaryTopic">
    <ng-container *ngSwitchCase="'device'">
      <div class="col-6">
        <div
          class="surface-card shadow-1 border-gray-900 border-round font-bold"
        >
          <div class="text-center p-2">
            <p>Devices Configured:</p>
            <span class="text-4xl font-bold">{{ summaryData.configured }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div
          class="surface-card shadow-1 border-round border-gray-900 font-bold"
        >
          <div class="text-center p-2">
            <p>Devices Available:</p>
            <span class="text-4xl font-bold">{{ summaryData.available }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div
          class="surface-card shadow-1 border-round border-gray-900 font-bold"
        >
          <div class="text-center p-2">
            <p>Devices Billed:</p>
            <span class="text-4xl font-bold">{{ summaryData.billed }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div
          class="surface-card shadow-1 border-round border-gray-900 font-bold"
        >
          <div class="text-center p-2">
            <p>Devices Notified:</p>
            <span class="text-4xl font-bold">{{ summaryData.notified }}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="shadow-1 border-round font-bold">
          <div class="text-center p-2">
            <p>Total Devices:</p>
            <span class="text-4xl font-bold">{{ summaryData.total }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'region'">
      <div class="col-12">
        <div class="shadow-1 border-round font-bold">
          <!-- <p-gmap
            [options]="mapOptions"
            [style]="{ width: '100%', height: '320px' }"
          ></p-gmap> -->
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
