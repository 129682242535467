import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/_core/_guards/auth.guard';
import { LeadScreenComponent } from './components/lead-screen/lead-screen.component';
import { LeadMainComponent } from './components/lead-main/lead-main.component';

const crmRoutes: Routes = [
  {
    path: '',
    component: LeadScreenComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: ['salesmanager', 'superadmin', 'salesagent'] },
    children: [
      {
        path: '',
        component: LeadMainComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(crmRoutes)],
  exports: [RouterModule],
})
export class CrmRoutingModule {}
