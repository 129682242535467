<div class="flex-1 overflow-y-auto p-2 min-h-full max-h-full h-full">
  <form [formGroup]="bss.businessDetailsForm">
    <div class="grid formgrid p-fluid">
      <div class="field mb-4 col-12 lg:col-12">
        <label for="businessName">Business Name</label>
        <input
          id="businessName"
          [autofocus]="true"
          type="text"
          pInputText
          formControlName="businessName"
          (blur)="bss.businessDetailsForm.get('businessName')!.markAsDirty()"
        />
        <small
          *ngIf="
            bss.businessDetailsForm.get('businessName')?.touched &&
            bss.businessDetailsForm.get('businessName')?.hasError('required')
          "
          id="businessName-help"
          class="p-error"
          >This field is required</small
        >
      </div>
      <!-- end of field -->
      <div class="field mb-4 col-12 lg:col-6">
        <label for="businessTelephone">Business Telephone</label>

        <input
          id="businessTelephone"
          pInputText
          formControlName="businessTelephone"
          type="tel"
          pKeyFilter="num"
          [pattern]="rsaPhoneNumberRegex"
          (blur)="
            bss.businessDetailsForm.get('businessTelephone')!.markAsDirty()
          "
        />
        <small class="text-600 text-800 block mb-2"
          >Telephone/Mobile number starting with 0 or 27</small
        >
        <small
          *ngIf="
            bss.businessDetailsForm
              .get('businessTelephone')!
              .hasError('required') &&
            bss.businessDetailsForm.get('businessTelephone')!.touched
          "
          id="businessTelephone-help"
          class="p-error"
          >This field is required</small
        >
      </div>
      <!-- end of field -->
      <div class="field mb-4 col-12 lg:col-6">
        <label for="businessEmail">Business Email</label>
        <input
          id="businessEmail"
          type="text"
          pInputText
          formControlName="businessEmail"
          pKeyFilter="email"
          (blur)="bss.businessDetailsForm.get('businessEmail')!.markAsDirty()"
        />
        <small class="text-600 text-800 block mb-2"
          >Email address with correct parameters</small
        >
        <small
          *ngIf="
            bss.businessDetailsForm.get('businessEmail')?.touched &&
            bss.businessDetailsForm.get('businessEmail')?.hasError('required')
          "
          id="businessEmail-help"
          class="p-error"
          >This field is required</small
        >
        <small
          *ngIf="
            bss.businessDetailsForm.get('businessEmail')?.touched &&
            bss.businessDetailsForm.get('businessEmail')?.hasError('email')
          "
          id="businessEmail-help"
          class="p-error"
          >Please enter a valid email address</small
        >
      </div>
      <!-- end of field -->

      <div
        formGroupName="businessDetails"
        class="border-1 border-blue-400 border-round m-4 p-4 fadein animation-duration-1000 formgrid grid w-full"
        *ngIf="
          (bss.businessDetailsForm.get('businessName')?.valid &&
            bss.businessDetailsForm.get('businessTelephone')?.valid &&
            bss.businessDetailsForm.get('businessEmail')?.valid) ||
          true
        "
      >
        <p class="text-xl font-semibold text-500 mb-4">
          Business Physical Address
        </p>
        <div class="field mb-4 col-12 lg:col-12">
          <label for="addressLine1">Address Line 1</label>
          <input
            id="addressLine1"
            type="text"
            pInputText
            formControlName="addressLine1"
            (blur)="
              bss.businessDetailsForm
                .get('businessDetails.addressLine1')!
                .markAsDirty()
            "
          />
          <small class="text-600 text-800 block mb-2"
            >Please enter street address details (Street number, street name,
            suburb)
          </small>
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.addressLine1')
                ?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.addressLine1')
                ?.hasError('required')
            "
            id="addressLine1-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="addressLine2">Address Line 2</label>
          <input
            id="addressLine2"
            type="text"
            pInputText
            formControlName="addressLine2"
            (blur)="
              bss.businessDetailsForm
                .get('businessDetails.addressLine2')!
                .markAsDirty()
            "
          />
          <small class="text-600 text-800 block mb-2"
            >Please enter building, premises or complex details (Building name,
            Unit number etc)
          </small>
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="suburb">Suburb</label>
          <input
            id="suburb"
            type="text"
            pInputText
            formControlName="suburb"
            (blur)="
              bss.businessDetailsForm
                .get('businessDetails.suburb')
                ?.markAsDirty()
            "
          />
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.suburb')?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.suburb')
                ?.hasError('required')
            "
            id="suburb-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="city">City</label>
          <input
            id="city"
            type="text"
            pInputText
            formControlName="city"
            (blur)="
              bss.businessDetailsForm.get('businessDetails.city')?.markAsDirty()
            "
          />
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.city')?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.city')
                ?.hasError('required')
            "
            id="city-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="province">Province</label>
          <p-dropdown
            id="province"
            [options]="findFormControlName('province')?.valueOptions || []"
            formControlName="province"
            filterBy="label"
            optionLabel="label"
            optionValue="value"
            placeholder="Select Province"
            (onBlur)="
              bss.businessDetailsForm
                .get('businessDetails.province')
                ?.markAsDirty()
            "
          >
          </p-dropdown>
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.province')
                ?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.province')
                ?.hasError('required')
            "
            id="province-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-3">
          <label for="postalCode">Street/Postal Code</label>
          <input
            id="postalCode"
            type="text"
            pInputText
            formControlName="postalCode"
            minlength="4"
            maxlength="4"
          />
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.postalCode')
                ?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.postalCode')
                ?.hasError('required')
            "
            id="postalCode-help"
            class="p-error"
            >This field is required</small
          >
          <small
            *ngIf="
              bss.businessDetailsForm.get('businessDetails.postalCode')
                ?.touched &&
              bss.businessDetailsForm
                .get('businessDetails.postalCode')
                ?.hasError('minlength')
            "
            id="postalCode-help"
            class="p-error"
            >Street/Postal code must be 4 digits</small
          >
        </div>
        <!-- end of field -->
      </div>
      <!-- end of form group - businessDetails -->

      <div
        formGroupName="primaryContact"
        class="border-1 border-blue-400 border-round m-4 p-4 fadein animation-duration-1000 formgrid grid w-full"
        *ngIf="
          (bss.businessDetailsForm.get('businessName')?.valid &&
            bss.businessDetailsForm.get('businessTelephone')?.valid &&
            bss.businessDetailsForm.get('businessEmail')?.valid) ||
          true
        "
      >
        <p class="text-xl font-semibold text-500 mb-4 col-12">
          Primary Contact Person for Business
        </p>
        <div class="field mb-4 col-12 lg:col-2">
          <label for="title">Primary Contact Title</label>
          <p-dropdown
            id="title"
            [options]="findFormControlName('title')?.valueOptions || []"
            formControlName="title"
            filterBy="label"
            optionLabel="label"
            optionValue="value"
            placeholder="Select Title"
            (onBlur)="
              bss.businessDetailsForm.get('primaryContact.title')?.markAsDirty()
            "
          >
          </p-dropdown>

          <small
            *ngIf="
              bss.businessDetailsForm.get('primaryContact.title')?.touched &&
              bss.businessDetailsForm
                .get('primaryContact.title')
                ?.hasError('required')
            "
            id="title-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-5">
          <label for="primaryContact.firstName"
            >Primary Contact First Name</label
          >
          <input
            id="primaryContact.firstName"
            type="text"
            pInputText
            formControlName="firstName"
            (blur)="
              bss.businessDetailsForm
                .get('primaryContact.firstName')!
                .markAsDirty()
            "
          />
          <small
            *ngIf="
              bss.businessDetailsForm.get('primaryContact.firstName')
                ?.touched &&
              bss.businessDetailsForm
                .get('primaryContact.firstName')
                ?.hasError('required')
            "
            id="firstName-help"
            class="p-error"
            >This field is required</small
          >
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-5">
          <label for="primaryContact.firstName"
            >Primary Contact Last Name</label
          >
          <input
            id="primaryContact.lastName"
            type="text"
            pInputText
            formControlName="lastName"
          />
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="primaryContact.jobTitle">Primary Contact Job Title</label>
          <input
            id="primaryContact.jobTitle"
            type="text"
            pInputText
            formControlName="jobTitle"
          />
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="telephone">Primary Contact Telephone</label>

          <input
            pInputText
            id="telephone"
            type="tel"
            pKeyFilter="num"
            [pattern]="rsaPhoneNumberRegex"
            formControlName="telephone"
            (blur)="
              bss.businessDetailsForm
                .get('primaryContact.telephone')!
                .markAsDirty()
            "
          />
          <small id="telephone-help" class="text-600 text-800 block"
            >Telephone/Mobile number starting with 0 or 27</small
          >

          <small
            *ngIf="
              bss.businessDetailsForm.get('primaryContact.telephone')
                ?.touched &&
              bss.businessDetailsForm
                .get('primaryContact.telephone')
                ?.hasError('required')
            "
            id="telephone-help"
            class="p-error mt-1"
            >This field is required</small
          >
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="email">Primary Contact Email</label>
          <input
            id="email"
            type="text"
            pKeyFilter="email"
            pInputText
            formControlName="email"
            (blur)="
              bss.businessDetailsForm.get('primaryContact.email')?.markAsDirty()
            "
          />

          <small class="text-600 text-800 block mb-2"
            >Email address with correct parameters</small
          >
          <small
            *ngIf="
              bss.businessDetailsForm.get('primaryContact.email')?.touched &&
              bss.businessDetailsForm
                .get('primaryContact.email')
                ?.hasError('required')
            "
            id="email-help"
            class="p-error"
            >This field is required</small
          >
          <small
            *ngIf="
              bss.businessDetailsForm.get('primaryContact.email')?.touched &&
              bss.businessDetailsForm
                .get('primaryContact.email')
                ?.hasError('email')
            "
            id="email-help"
            class="p-error"
            >Please enter a valid email address</small
          >
        </div>
        <!-- end of field -->
      </div>
      <!-- end of form group - primaryContact -->

      <div
        class="field mb-4 col-12 lg:col-12 fadein animation-duration-1000"
        *ngIf="
          (bss.businessDetailsForm.get('businessName')?.valid &&
            bss.businessDetailsForm.get('businessTelephone')?.valid &&
            bss.businessDetailsForm.get('businessEmail')?.valid) ||
          true
        "
      >
        <label for="product">Selected Fibre Product</label>
        <p-dropdown
          [options]="findFormControlName('product')?.valueOptions || []"
          formControlName="product"
          placeholder="Select a Product"
          optionaValue="product_code"
          optionLabel="description"
          [showClear]="true"
        >
          <ng-template pTemplate="selectedItem" let-selectedProduct>
            <div class="align-items-center surface-border grid p-3">
              <span
                class="text-xl text-900 font-medium mb-2 text-center col-12"
                >{{ selectedProduct.description }}</span
              >

              <div class="col-6 text-center">
                <span class="text-blue-500 font-medium text-center text-md"
                  ><i class="pi pi-arrow-down"> </i>
                  {{ selectedProduct.speed_download_mbps }} Mbps</span
                >
                <br />
                <span class="text-600 font-medium text-center">Download </span>
              </div>

              <div class="col-6 text-center">
                <span class="text-green-500 font-medium text-center text-lg"
                  ><i class="pi pi-arrow-up"> </i
                  >{{ selectedProduct.speed_upload_mbps }} Mbps
                </span>
                <br />
                <span class="text-600 font-medium text-center">Upload </span>
                <span></span>
              </div>
              <span
                class="text-2xl text-800 block font-semibold text-center col-12 mb-2"
                >{{
                  selectedProduct.recurring_amount_excl
                    | currency: "ZAR" : "symbol-narrow"
                }}</span
              >
            </div>
          </ng-template>
          <ng-template let-product pTemplate="item">
            <div class="surface-card shadow-1">
              <div class="align-items-center surface-border grid p-3">
                <span
                  class="text-xl text-900 font-medium mb-2 text-center col-12"
                  >{{ product.description }}</span
                >

                <div class="col-6 text-center">
                  <span class="text-blue-500 font-medium text-center text-md"
                    ><i class="pi pi-arrow-down"> </i>
                    {{ product.speed_download_mbps }} Mbps</span
                  >
                  <br />
                  <span class="text-600 font-medium text-center"
                    >Download
                  </span>
                </div>

                <div class="col-6 text-center">
                  <span class="text-green-500 font-medium text-center text-lg"
                    ><i class="pi pi-arrow-up"> </i
                    >{{ product.speed_upload_mbps }} Mbps
                  </span>
                  <br />
                  <span class="text-600 font-medium text-center">Upload </span>
                  <span></span>
                </div>
                <span
                  class="text-2xl text-800 block font-semibold text-center col-12 mb-2"
                  >{{
                    product.recurring_amount_excl | currency: "ZAR" : "symbol"
                  }}
                  <span class="text-2xl text-800">excl VAT</span></span
                >
              </div>
            </div>
          </ng-template></p-dropdown
        >
      </div>
      <!-- end of field -->
    </div>
    <!-- end of formgrid -->
  </form>
</div>
<!-- <div class="grid grid-nogutter justify-content-end">
    <p-button
      label="Previous"
      (onClick)="prevPage()"
      icon="pi pi-angle-left"
      iconPos="left"
    ></p-button>
    <p-button
      label="Next"
      (onClick)="nextPage()"
      icon="pi pi-angle-right"
      iconPos="right"
    ></p-button>
  </div> -->
