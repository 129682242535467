<div class="flex flex-column h-full min-h-full">
  <ng-container>
    <div class="container">
      <div *ngIf="loading; else showTransactions">
        <div class="mb-3">
          <span class="font-bold text-2xl text-900">Loading Details...</span>
        </div>
        <p-progressBar styleClass="align-self-center" mode="indeterminate">
        </p-progressBar>
      </div>

      <ng-template #showTransactions>
        <div *ngIf="isError">
          <p>An error occurred while fetching data</p>
        </div>

        <ng-container *ngIf="transactions$ | async"> </ng-container>
        <!-- todo: move outside of tab view -->
        <share-subscription-action-bar
          [subscription]="subscription[0]"
        ></share-subscription-action-bar>
        <share-subscription-details
          [subscription]="subscription[0]"
        ></share-subscription-details>
        <span class="text-900 font-medium text-3xl px-6">Transactions</span>
        <div class="lg:px-6 px-1 py-5">
          <div class="surface-card p-4 shadow-2 border-round">
            <p-table
              [value]="(transactions$ | async) || []"
              [columns]="columns"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let col of columns">
                    <span class="font-semibold text-md text-color-secondary">{{
                      col.header
                    }}</span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-transaction>
                <tr
                  [pSelectableRow]="transaction"
                  [pContextMenuRow]="transaction"
                >
                  <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.field">
                      <div
                        *ngSwitchCase="'amount_incl'"
                        class="flex align-items-center gap-3"
                      >
                        {{
                          transaction[col.field]
                            | currency
                              : "ZAR"
                              : "symbol-narrow"
                              : "1.2-2"
                              : "en-ZA"
                        }}
                      </div>

                      <div
                        *ngSwitchDefault
                        class="flex align-items-center gap-3"
                      >
                        <p
                          class="mt-0 mb-2 font-medium text-md text-color-primary"
                        >
                          {{ transaction[col.field] }}
                        </p>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
