<div class="lg:px-6 px-1 py-5">
  <div class="surface-card p-4 shadow-2 border-round">
    <span class="p-buttonset">
      <button
        type="button"
        pButton
        icon="pi pi-list"
        label="Show Subscription Actions"
        (click)="overlayPanel.toggle($event)"
      ></button>
    </span>
    <p-overlayPanel #overlayPanel>
      <p-menu #menu [model]="subscriptionActions"></p-menu>
    </p-overlayPanel>

    <!-- <button
        *ngFor="let subscriptionAction of subscriptionActions"
        pButton
        pRipple
        [label]="subscriptionAction.label || ''"
        [icon]="subscriptionAction.icon || ''"
        [ngClass]="subscriptionAction.styleClass || ''"
        (click)="(subscriptionAction.command)"
      ></button> -->
  </div>
</div>
