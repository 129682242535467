<form [formGroup]="actionForm" (ngSubmit)="onActionFormSubmit()">
  <div class="grid formgrid p-fluid justify-content-center align-items-center">
    <div class="field w-12 col-12 md:col-6">
      <label for="action_type">Action Type</label>
      <p-dropdown
        formControlName="action_type"
        [options]="groupedActionTypeOptions"
        [showClear]="true"
        [group]="true"
        [filter]="true"
        filterBy="label"
        placeholder="Select an Action"
        optionLabel="label"
        optionValue="value"
        (onChange)="onActionTypeChange($event)"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <span class="pi {{ group.value }}"></span>
            <span class="pl-2">{{ group.label }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field w-12 col-12 md:col-6">
      <label for="action_details">Action Details</label>
      <textarea
        id="action_details"
        pInputTextarea
        formControlName="action_details"
        [rows]="8"
      >
      </textarea>
    </div>

    <div class="field w-12 col-12 md:col-6">
      <p-checkbox
        id="called"
        [binary]="true"
        [value]="false"
        formControlName="called"
        label="Called?"
      ></p-checkbox>
      <small for="called" class="block"
        >Were you on a call for longer than 30 seconds?</small
      >
    </div>

    <div class="field w-12 col-12 md:col-6">
      <label for="next_interaction_date">Next Interaction Date</label>
      <p-calendar
        class="col-6"
        formControlName="next_interaction_date"
        [touchUI]="true"
        view="date"
        dateFormat="dd-mm-yy"
      ></p-calendar>
    </div>

    <button
      pButton
      type="submit"
      label="Save Action"
      class="p-button-rounded p-button-secondary"
    ></button>
  </div>
</form>
