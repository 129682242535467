import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolAsString',
})
export class BoolAsStringPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value === true) {
      return 'TRUE';
    } else if (value === false) {
      return 'FALSE';
    }
    return '';
  }
}
