import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

import { CacheService } from 'src/app/_core/_services/cache.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { confirmPasswordValidator } from 'src/app/_share/_validators/confirm-password.validator';
import { ApiService } from 'src/app/_core/_services/api.service';

@Component({
  selector: 'app-portal-form',
  templateUrl: './portal-form.component.html',
  styleUrls: ['./portal-form.component.scss'],
})
export class PortalFormComponent implements OnInit {
  uploadedIdFile: any = null;
  uploadedResFile: any = null;
  idUploaded: any = false;
  resUploaded: any = false;
  fileUploadSubscription$: any;
  submitted = false;
  error: any;
  constructor(
    public fb: FormBuilder,
    private ref: DynamicDialogRef,
    private api: ApiService,
    private portalUsersService: PortalUsersService,
    private cacheService: CacheService
  ) {}

  formState = 'register';
  registeredUserId: any = 0;
  registeredUserId$: Subject<any> = new Subject();
  registeredUserEmail: any = '';
  registeredUserNumber: any = '';
  registeredUserPassword: any = '';

  identificationTypes = [
    { label: 'RSA ID', value: 'rsaid' },
    { label: 'Passport (Non RSA citizen)', value: 'passport' },
  ];

  provinces = [
    { label: 'Eastern Cape', value: 'eastern_cape' },
    { label: 'Free State', value: 'free_state' },
    { label: 'Gauteng', value: 'gauteng' },
    { label: 'KwaZulu-Natal', value: 'kwazulu_natal' },
    { label: 'Limpopo', value: 'limpopo' },
    { label: 'Mpumalanga', value: 'mpumalanga' },
    { label: 'North West', value: 'north_west' },
    { label: 'Northern Cape', value: 'northern_cape' },
    { label: 'Western Cape', value: 'western_cape' },
  ];

  portalUserRegisterForm = this.fb.group(
    {
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      identification_reference: ['', Validators.required],
      identification_type: ['', Validators.required],
      mobile_number: ['', Validators.required],
      alternate_contact_number: [''],
      email: ['', [Validators.required, Validators.email]],
      // password: ['', Validators.required],
      // complex_building: [''],
      // street_address: [''],
      // province: [''],
      // postal_code: [''],
      password: ['', Validators.required],
      confirm_password: ['', [Validators.required]],
      // postal_address: [''],
    },
    { validator: confirmPasswordValidator }
  );

  portalUserConfirmForm = this.fb.group({
    portal_end_customer_id: [this.registeredUserId],
    confirmation_pin: ['', Validators.required],
    confirmation_mobile_number: [''],
    confirirmation_email: [''],
    // could add mobile phone number here
  });

  ngOnInit(): void {}

  resendConfirmationOTP() {
    this.portalUsersService
      .resendConfirmationOTP(this.registeredUserId)
      .subscribe((response) => {
        console.log('Response: ', response);
      });
  }

  get f() {
    return this.portalUserRegisterForm.controls;
  }

  // file uploads

  uploadIdFile(event$: any) {
    const file = event$.files[0];
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('portal_end_customer_id', this.registeredUserId);
    formData.append('document_type', 'id');

    this.fileUploadSubscription$ = this.api
      .postFileAPI('portal/user/upload-file', formData)
      .subscribe(
        (response: any) => {
          const data = response.body;
          console.log('File upload response:', data);
        },
        (error: any) => {
          console.error('File upload failed:', error);
          // Add user-friendly error handling or notifications here
        }
      );

    // ... rest of your code

    this.uploadedIdFile = file;
  }
  onIdUpload($event: any) {
    const file = $event.files[0];
    this.idUploaded = null;
  }

  uploadResFile($event: any) {
    throw new Error('Method not implemented.');
  }
  onResUpload($event: any) {
    const file = $event.files[0];
    this.resUploaded = null;
  }

  onRegisterFormSubmit() {
    this.submitted = true;
    console.log('Form valid: ', this.portalUserRegisterForm.valid);
    console.log('Form values: ', this.portalUserRegisterForm.value);
    console.log('Original form values: ', this.portalUserRegisterForm);
    console.log('Form errors: ', this.portalUserRegisterForm.errors);
    if (this.portalUserRegisterForm.valid) {
      this.portalUsersService
        .addPortalUser(this.portalUserRegisterForm.value)
        .subscribe(
          (response) => {
            console.log('Response: ', response.body.data);

            this.registeredUserId = response.body.data.id;
            this.registeredUserNumber =
              this.portalUserRegisterForm.value.mobile_number;

            this.formState = 'confirm';

            this.portalUserConfirmForm.patchValue({
              portal_end_customer_id: this.registeredUserId,
              confirmation_mobile_number: this.registeredUserNumber,
            });

            // todo: change state instead of closing the dialog
          },
          (error) => {
            console.error('Error: ', error);
            this.error = error.error.detail;
            this.submitted = false;
          }
        );
    }
  }

  onConfirmFormSubmit() {
    console.log('Form valid: ', this.portalUserConfirmForm.valid);
    console.log('Form values: ', this.portalUserConfirmForm.value);
    console.log('Original form values: ', this.portalUserConfirmForm);

    if (this.portalUserConfirmForm.valid) {
      this.portalUsersService
        .confirmPortalUser(this.portalUserConfirmForm.value)
        .subscribe((response) => {
          console.log('Response: ', response);

          // this.ref.close();
          this.formState = 'registered';
          // todo: add notification for successful addition
          // this.messageService.add({
          //   severity: 'success',
          //   summary: 'Success',
          //   detail: 'Registration successful',
          // });
        });
    }
  }
}
