<div *ngIf="leadStats$ | async as statsData">
  <div class="flex flex-column flex-auto">
    <div class="grid">
      <!-- over all stats -->
      <div class="col-12">
        <div
          class="surface-card shadow-2 border-round flex p-3 flex-column md:flex-row"
        >
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <i class="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">New Leads</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              statsData.new
            }}</span>
            <!-- <div class="flex align-items-center">
              <i class="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
              <span class="text-pink-500 font-medium">-25</span>
            </div> -->
          </div>
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">Qualified Leads</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              statsData.qualified
            }}</span>
            <!-- <div class="flex align-items-center">
              <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
              <span class="text-green-500 font-medium">+15</span>
            </div> -->
          </div>
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <i class="pi pi-users text-cyan-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">Opportunity Leads</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              statsData.opportunity
            }}</span>
            <!-- <div class="flex align-items-center">
              <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
              <span class="text-green-500 font-medium">+%12</span>
            </div> -->
          </div>
          <div
            class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
          >
            <div class="flex align-items-center mb-3">
              <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">Closed-Win Leads</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              statsData["closed-win"]
            }}</span>
            <!-- <div class="flex align-items-center">
              <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
              <span class="text-green-500 font-medium">+20</span>
            </div> -->
          </div>
          <div class="flex-auto p-3">
            <div class="flex align-items-center mb-3">
              <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
              <span class="text-500 font-medium">Closed-Loss Leads</span>
            </div>
            <span class="block text-900 font-medium mb-4 text-xl">{{
              statsData["closed-loss"]
            }}</span>
            <!-- <div class="flex align-items-center">
              <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
              <span class="text-green-500 font-medium">+20</span>
            </div> -->
          </div>
        </div>
      </div>

      <ng-container *ngIf="actionStats$ | async as actionStatsData">
        <!-- daily totals -->

        <div class="col-12">
          <div class="surface-card shadow-2 border-round p-4 h-full">
            <div class="flex align-items-center justify-content-between mb-3">
              <div class="text-900 font-medium text-xl">Daily Statistics</div>
            </div>
            <p-chart
              type="bar"
              height="20vh"
              [data]="actionStatsData.chart"
            ></p-chart>
          </div>
        </div>

        <!-- calls stats -->
        <div class="col-12">
          <div class="surface-card shadow-2 border-round p-4 h-full">
            <div class="flex align-items-center justify-content-between mb-3">
              <div class="text-900 font-medium text-xl">
                Individual Daily Statistics
              </div>
            </div>
            <ul
              class="list-none p-0 m-0 overflow-y-scroll max-w-screen"
              style="height: 40vh; max-width: 100vw"
            >
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
                *ngFor="let dayStats of actionStatsData.stats | nestedKeyvalue"
              >
                <div class="flex flex-row align-items-center mr-0 lg:mr-5">
                  <div class="flex-grow-1">
                    <span class="text-500 font-medium">{{ dayStats.key }}</span>
                  </div>
                  <div
                    class="surface-card border-1 border-round border-500 p-4 mx-4 flex-grow-0 w-full min-w-full"
                    *ngFor="
                      let agentStats of dayStats.value
                        | nestedKeyvalue as agentStatsData
                    "
                  >
                    <span class="text-900 font-medium text-blue-700 text-1xl">{{
                      agentStats.key | uppercase
                    }}</span>
                    <ul
                      class="mt-1 list-none p-0 mx-0"
                      *ngFor="
                        let actionStats of agentStats.value | nestedKeyvalue
                      "
                    >
                      <li class="flex align-items-center">
                        <span
                          style="width: 1rem; height: 1rem"
                          class="border-round bg-indigo-500 mr-3 flex-shrink-0"
                        ></span>
                        <span class="text-md font-medium text-90">{{
                          actionStats.key | uppercase
                        }}</span>
                        <span class="text-600 text-md font-medium ml-auto">{{
                          actionStats.value
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div
                  class="flex flex-row align-items-start mr-0 lg:mr-5"
                  *ngFor="
                    let agentStats of dayStats.value
                      | nestedKeyvalue as agentStatsData
                  "
                >
                  <div class="surface-card shadow-2 border-round p-4 w-full">
                    <div class="surface-border border-1 border-round p-3 mb-4">
                      <span class="text-900 font-medium text-1xl">{{
                        agentStats.key | uppercase
                      }}</span>
                    </div>
                    <ul
                      class="mt-1 list-none p-0 mx-0"
                      *ngFor="
                        let actionStats of agentStats.value | nestedKeyvalue
                      "
                    >
                      <li class="flex align-items-center">
                        <span
                          style="width: 1rem; height: 1rem"
                          class="border-round bg-indigo-500 mr-3 flex-shrink-0"
                        ></span>
                        <span class="text-md font-medium text-90">{{
                          actionStats.key | uppercase
                        }}</span>
                        <span class="text-600 text-md font-medium ml-auto">{{
                          actionStats.value
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <span class="text-900 font-medium block mb-2"></span>
                  <a class="text-primary cursor-pointer"> </a>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >{{ dayStats.key }}</span
                > -->
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
