import { Component, OnInit } from '@angular/core';
import { LeadStatsService } from 'src/app/features/crm/_services/lead-stats.service';

@Component({
  selector: 'app-crm-stats',
  templateUrl: './crm-stats.component.html',
  styleUrls: ['./crm-stats.component.scss'],
})
export class CrmStatsComponent implements OnInit {
  updates: any;
  // websocketSubscription: any;
  constructor(
    private leadStatsService: LeadStatsService // private webSocketService: WebSocketService
  ) {}

  leadStats$ = this.leadStatsService.leadStats$;
  actionStats$ = this.leadStatsService.actionStats$;

  basicOptions: any;

  ngOnInit(): void {
    this.leadStatsService.getStatistics();

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    // this.websocketSubscription = this.webSocketService
    //   .subscribeToUpdates()
    //   .subscribe(
    //     (update: any) => {
    //       // Handle updates here
    //       this.updates = update;
    //       console.log(update);
    //     },
    //     console.error,
    //     console.log
    //   );

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  convertActionsToStats(actionStats: any) {
    const stats = actionStats.stats.map((stat: any) => {});
  }

  getAgents(actionStats: any) {}

  ngOnDestroy() {
    if (this.leadStats$) {
      this.leadStats$.unsubscribe();
    }

    if (this.actionStats$) {
      this.actionStats$.unsubscribe();
    }
  }
}
