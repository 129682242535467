import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ParksService } from 'src/app/features/portal/_services/parks.service';
import { PortalRegisterService } from 'src/app/features/portal/_services/portal-register.service';

import { SignupSuccessDialogComponent } from './signup-success-dialog/signup-success-dialog.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  errorMessage: string = '';
  results: any[] = [];
  invalidDates: Date[] = [];
  constructor(
    private fb: FormBuilder,
    public dialogService: DialogService,
    private http: HttpClient,
    private userRegsisterService: PortalRegisterService,
    private parksService: ParksService,
  ) {}

  parks: any[] = [];
  parks$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  signupForm: any = this.fb.group({
    title: ['Mr', [Validators.required]],
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    // home address
    // address: ['', [Validators.required]],
    customer_signup_type: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    mobile_number: ['', [Validators.required]],
    pitch_chalet_lodge_no: ['', [Validators.required]],
    park_name: ['', [Validators.required]],
    wifi_service: ['', [Validators.required]],
    install_option: ['', [Validators.required]],
    desired_activation_date: ['', [Validators.required]],
    comments_requests: ['', [Validators.required]],
    permission_access: [false],
    permission_marketing: [false],
    permission_costs: [false, Validators.requiredTrue],
  });

  notARobot: boolean = false;
  submitLoading: boolean = false;

  ukPhoneNumberRegex: RegExp = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;

  parkOptions: any[] = [
    {
      value: 'Barnstones Caravan Park',
      label: 'Barnstones Caravan Park',
      type: 'fttc',
    },
    {
      value: 'Belle Aire Holiday Park',
      label: 'Belle Aire Holiday Park',
      type: 'enterprise',
    },

    {
      value: 'Diglea Caravan Park',
      label: 'Diglea Caravan Park',
      type: 'enterprise',
    },
    {
      value: 'Faweather Grange Holiday Park',
      label: 'Faweather Grange Holiday Park',
      type: 'fttc',
    },
    {
      value: 'Fields End Water Caravan Park',
      label: 'Fields End Water Caravan Park',
      type: 'fttc',
    },
    {
      value: 'Friary Farm Caravan Park',
      label: 'Friary Farm Caravan Park',
      type: 'enterprise',
    },
    {
      value: 'Harleyford Estate and Marina',
      label: 'Harleyford Estate and Marina',
      type: 'enterprise',
    },
    {
      value: 'Heathland Beach Caravan Park',
      label: 'Heathland Beach Caravan Park',
      type: 'enterprise',
    },
    {
      value: 'Jennings Caravan Park',
      label: 'Jennings Caravan Park',
      type: 'enterprise',
    },
    {
      value: 'Milton Haven Caravan Park',
      label: 'Milton Haven Caravan Park',
      type: 'fttc',
    },
    {
      value: 'Orchard Holiday Park',
      label: 'Orchard Holiday Park',
      type: 'fttc',
    },
    {
      value: 'Pinewoods Holiday Park',
      label: 'Pinewoods Holiday Park',
      type: 'enterprise',
    },
    {
      value: 'Pioneer Holiday Park',
      label: 'Pioneer Holiday Park',
      type: 'enterprise',
    },
    {
      value: 'Pine Trees Leisure Park',
      label: 'Pine Trees Leisure Park',
      type: 'fttc',
    },
    {
      value: 'Quarry Walk Park',
      label: 'Quarry Walk Park',
      type: 'enterprise',
    },
    {
      value: 'Seadell Chalet Park',
      label: 'Seadell Chalet Park',
      type: 'enterprise',
    },
    {
      value: 'Tall Trees Caravan Park',
      label: 'Tall Trees Caravan Park',
      type: 'enterprise',
    },
    {
      value: 'Willingcott Valley Holiday Park',
      label: 'Willingcott Valley Holiday Park',
      type: 'enterprise',
    },
    {
      value: 'Wyndham Holiday Park',
      label: 'Wyndham Holiday Park',
      type: 'fttc',
    },
  ];

  installOptions: any[] = [
    {
      value: 'kit',
      label: 'Installation of Wi-Fi equipment required ',
      price: 270,
    },
    {
      value: 'self',
      label: 'Self-installation of Wi-Fi equipment',
      price: 185,
    },
    {
      value: 'already-installed',
      label: 'Wi-Fi equipment already installed',
      price: 0,
    },
  ];

  serviceOptions: any[] = [
    {
      value: 'fttc-browsing-1.8',
      label: 'Web Browsing Service',
      type: 'fttc',
    },
    {
      value: 'fttc-streaming-10',
      label: 'Streaming Service',
      type: 'fttc',
    },
    {
      value: 'enterprise-browsing-1.8',
      label: 'Web Browsing Service',
      type: 'enterprise',
    },
    {
      value: 'enterprise-streaming-15',
      label: 'Streaming Service',
      type: 'enterprise',
    },
    {
      value: 'enterprise-streaming-30',
      label: 'Streaming Service Plus',
      type: 'enterprise',
    },
    {
      value: 'enterprise-streaming-15',
      label: 'Streaming Service',
      type: 'enterprise',
    },
    {
      value: 'enterprise-streaming-30',
      label: 'Streaming Service Plus',
      type: 'enterprise',
    },
    {
      value: 'enterprise-browsing-1.8',
      label: 'Web Browsing Service',
      type: 'enterprise-hispeed',
    },
    {
      value: 'enterprise-streaming-50',
      label: 'Streaming Service',
      type: 'enterprise-hispeed',
    },
    {
      value: 'enterprise-streaming-100',
      label: 'Streaming Service Plus',
      type: 'enterprise-hispeed',
    },
  ];

  controlList: any[] = [
    {
      name: 'title',
      label: 'Title',
      description: 'Please select your title',
      value: 'Please select your title',
      type: 'dropdown',
      width: 2,
      filter: false,
      options: [
        { value: 'Mr', label: 'Mr' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Mrs', label: 'Mrs' },
      ],
      validators: { required: true },
    },
    {
      name: 'first_name',
      label: 'First Name',
      description: 'Enter your first name',
      value: '',
      type: 'text',
      width: 5,
      validators: { required: true },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      description: 'Enter your last name',
      value: '',
      type: 'text',
      width: 5,
      validators: { required: true },
    },
    // {
    //   name: 'address',
    //   label: 'Address',
    //   description: 'Enter your address',
    //   value: '',
    //   type: 'text',

    //   validators: { required: true },
    // },

    {
      name: 'email',
      label: 'Email',
      description: 'Enter your email address',
      value: '',
      type: 'email',
      width: 6,
      validators: { required: true, email: true },
    },
    {
      name: 'mobile_number',
      label: 'Mobile Number',
      description: 'Enter your mobile number',
      value: '',
      type: 'tel',
      width: 6,
      validators: { required: true },
    },
    {
      name: 'customer_signup_type',
      label: 'Are you signing up as a new customer or an existing customer?',
      description: 'Choose your sign-up option',
      value: '',
      type: 'dropdown',
      width: 6,
      validators: { required: true },
      options: [
        { value: 'new', label: 'New Customer' },
        { value: 'existing', label: 'Existing Customer' },
      ],
    },
    {
      name: 'park_name',
      label: 'Park Name',
      description: 'Select the park name',
      value: 'Choose a Park',
      type: 'autocomplete',
      width: 6,
      filter: true,
      validators: { required: true },
      options: this.parkOptions,
    },
    {
      name: 'pitch_chalet_lodge_no',
      label: 'Pitch/Chalet/Lodge No',
      description: 'Enter your pitch, chalet, or lodge number',
      value: '',
      type: 'text',
      width: 6,
      validators: { required: true },
    },

    {
      name: 'wifi_service',
      label: 'WiFi Service',
      description:
        'Select your preferred wifi service plan - Make sure that you have chosen your park name first',
      value: '',
      type: 'dropdown',
      filter: false,
      validators: { required: true },
      options: this.serviceOptions,
    },
    {
      name: 'install_option',
      label: 'Install Option',
      description: 'Select your preferred installation option',
      value: 'Select an option',
      type: 'dropdown',
      filter: false,
      validators: { required: true },
      options: this.installOptions,
    },
    {
      name: 'desired_activation_date',
      label: 'Desired Activation Date',
      description: 'Select your desired activation date',
      value: '',
      type: 'date',
      validators: { required: true },
    },
    {
      name: 'comments_requests',
      label: 'Comments/Requests',
      description:
        'Please provide as much detail as you can of your requirements',
      value: '',
      type: 'textarea',
      validators: { required: true },
    },
    {
      name: 'permission_access',
      label:
        'Access Permission (Do you give permission to enter caravan for installation?)',
      description:
        "Benington Networks is committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you",
      value: false,
      type: 'checkbox',
      validators: { required: true },
    },
    {
      name: 'permission_marketing',
      label: 'I agree to receive other communications from Benington Networks.',
      description:
        'By clicking submit below, you consent to allow Benington Networks to store and process the personal information submitted above to provide you the content requested.',
      value: false,
      type: 'checkbox',
      validators: {},
    },
    {
      name: 'permission_costs',
      label:
        'By Submitting this Order Form, you hereby accepted - All costs agreed upon',
      description: '',
      value: false,
      type: 'checkbox',
      validators: { required: true },
    },
  ];

  formErrors: boolean = false;

  ngOnInit(): void {
    this.errorMessage = '';
    this.parksService.getParks().subscribe((parks: any) => {
      this.parks = parks;
      this.parks$.next(parks);
      this.parkOptions = this.convertToOptions(parks);

      console.log('parks', this.parkOptions);
    });

    const today = new Date();

    this.invalidDates = [today];

    for (let i = 1; i < 8; i++) {
      const invalidDate = new Date();
      invalidDate.setDate(today.getDate() + i);
      this.invalidDates.push(invalidDate);
    }
  }

  checkForErrors(): void {
    this.formErrors = false;
    if (this.signupForm.valid) {
      this.formErrors = true;
    }
  }
  onSignUpSubmit() {
    this.errorMessage = '';
    console.log('form valid: ', this.signupForm.valid);
    console.log('form value: ', this.signupForm.value);
    this.submitLoading = true;

    if (this.signupForm.valid) {
      console.log('Form submitted successfully');

      const formValue = this.signupForm.value;
      formValue.desired_activation_date =
        formValue.desired_activation_date.toDateString();

      this.userRegsisterService.signupUser(formValue).subscribe(
        (res) => {
          console.log('res', res);
          // show success dialog
          this.dialogService.open(SignupSuccessDialogComponent, {
            data: {},
            header: 'Sign-up successful',
            width: '70%',
            height: '70%',
            closeOnEscape: true,
            closable: true,
          });
          this.submitLoading = false;
          this.signupForm.reset();
        },
        (err) => {
          console.log('err', err);
          this.checkForErrors();
          this.errorMessage =
            err.error.detail.detail + ' - ' + err.error.detail.message;
          this.submitLoading = false;
        },
      );
    } else {
      console.log('form is invalid');
      this.signupForm.markAllAsTouched();

      for (const control of Object.keys(this.signupForm.controls)) {
        this.signupForm.get(control)?.markAsDirty();
      }
      this.submitLoading = false;
    }
  }

  filterServiceOptions(): any[] {
    const selectedParkName = this.signupForm.get('park_name').value;

    const selectedParkId = this.parkOptions.find(
      (option) => option.label === selectedParkName,
    )?.value;

    const selectedParkType = this.parkOptions.find(
      (option) => option.value === selectedParkId,
    )?.type;

    return this.serviceOptions.filter(
      (option) => option.type === selectedParkType,
    );
  }

  convertToOptions(parks: any[]): any[] {
    return parks.map((park) => {
      return {
        value: park.id,
        label: park.name,
        type: park.description.toLowerCase(),
      };
    });
  }

  search(event: any) {
    console.log(event.query);
    if (event.query.length < 4) {
      this.results = [];
    } else {
      const resultsTemp = this.parkOptions
        .filter((option) => {
          return option.label.toLowerCase().includes(event.query.toLowerCase());
        })
        .map((result) => {
          return result.label;
        });

      this.results = resultsTemp;
    }
  }

  setValiditity($event: any) {
    console.log('captcha event: ', $event);
    this.notARobot = true;
  }
}
