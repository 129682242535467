import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';

import { CacheService } from 'src/app/_core/_services/cache.service';
import { PortalFilesService } from 'src/app/features/portal/_services/portal-files.service';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';
import { PortalTransactionsService } from 'src/app/features/portal/_services/portal-transactions.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { PortalFiles } from 'src/app/_share/_models/portal/interfaces';
import { PortalSubscriptions } from 'src/app/_share/_models/portal/portal-subscriptions.model';

@Component({
  selector: 'app-portal-profile',
  templateUrl: './portal-profile.component.html',
  styleUrls: ['./portal-profile.component.scss'],
})
export class PortalProfileComponent implements OnInit {
  activeProfileTab = 'profile';

  portalSubscription: any;
  portalSubscription$: Subject<PortalSubscriptions> =
    new Subject<PortalSubscriptions>();

  portalCustomer: any;
  portalCustomer$: Subject<any> = new Subject<any>();

  portalTransactions: any;
  portalTransactions$: Subject<any> = new Subject<any>();

  portalCustomerFiles: any[] = [];
  portalCustomerFiles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  items: MenuItem[] = [];
  transactionColumns: any[] = [
    { field: 'transaction_date', header: 'Date' },
    { field: 'transaction_description', header: 'Description' },
    { field: 'reference', header: 'Reference' },
    { field: 'amount_incl', header: 'Payments' },
    { field: 'amount_incl', header: 'Bills' },
    { field: 'transaction_method', header: 'Transaction Method' },
    { field: 'transaction_type', header: 'Transaction Type' },

    { field: 'status', header: 'Status' },
  ];

  constructor(
    private portalSubscriptionService: PortalSubscriptionService,
    private portalUsersService: PortalUsersService,
    private portalTransactionsService: PortalTransactionsService,
    private portalFilesService: PortalFilesService,
    private cacheService: CacheService,
  ) {}

  ngOnInit(): void {
    this.items = [
      { label: 'Overview', icon: 'pi pi-home' },
      { label: 'Members', icon: 'pi pi-users' },
      { label: 'Jobs', icon: 'pi pi-search' },
      { label: 'Profile', icon: 'pi pi-user' },
      { label: 'Settings', icon: 'pi pi-cog' },
    ];
    const portalUser = this.cacheService.getItem('portalUser');

    this.portalSubscriptionService
      .getPortalSubscriptionByCustomer(
        portalUser && portalUser.portal_end_customer_id,
      )
      .subscribe((res) => {
        // test if list
        if (res && res.length > 0) {
          res = res[0];
        }
        this.portalSubscription = res;
        this.portalSubscription$.next(res);
        console.log('portalSubscription: ', this.portalSubscription);
      });

    this.portalUsersService
      .getPortalUserById(portalUser && portalUser.portal_end_customer_id)
      .subscribe((res) => {
        this.portalCustomer = res;
        this.portalCustomer$.next(res);
        console.log('portalCustomer: ', this.portalCustomer);
      });

    // fixme: portal - get list of transactions
    this.portalTransactionsService
      .getPortalTransactionsByEndCustomerId(
        portalUser && portalUser.portal_end_customer_id,
      )
      .subscribe((data: any) => {
        this.portalTransactions = data;
        this.portalTransactions$.next(this.portalTransactions);
        console.log('portalTransactions: ', this.portalTransactions);
      });

    this.getFiles(portalUser && portalUser.portal_end_customer_id);
  }

  getFiles(portal_customer_id: number) {
    this.portalFilesService
      .getPortalFiles(Number(portal_customer_id))
      .subscribe((data: PortalFiles[]) => {
        this.portalCustomerFiles = data;
        this.portalCustomerFiles$.next(this.portalCustomerFiles);
      });
  }

  payTransaction(transaction: any, override?: string) {
    const portalUser = this.cacheService.getItem('portalUser');
    this.portalTransactionsService
      .getPortalTransactionPayLink(
        transaction,
        portalUser && portalUser.portal_end_customer_id,
        override || '',
      )
      .subscribe((res) => {
        console.log('pay link: ', res);
        if (res && res.paymentRedirect) {
          window.location.href = res.paymentRedirect;
        }
      });
  }

  activateSubscription() {
    const portalUser = this.cacheService.getItem('portalUser');
    let subscriptionActivation = {
      // ONLY FOR TESTING
      device_reference: 'C8:8D:83:2E:B9:E5',
    };
    if (this.cacheService.getItem('mac')) {
      console.log('mac: ', this.cacheService.getItem('mac'));
      const devcieReference = this.cacheService.getItem('mac');
      subscriptionActivation = {
        // ONLY FOR TESTING
        device_reference: devcieReference,
      };
    } else {
      console.log('mac not found');
    }

    this.portalSubscription$.subscribe((data) => {
      console.log('subscription: ', data);
    });

    this.portalSubscriptionService
      .activatePortalSubscription(
        this.portalSubscription && this.portalSubscription.id,
        subscriptionActivation,
      )
      .subscribe((res) => {
        console.log('activateSubscription: ', res);
        if (res && res.status === 'success') {
          this.portalSubscriptionService
            .getPortalSubscriptionByCustomer(
              portalUser && portalUser.portal_end_customer_id,
            )
            .subscribe((data) => {
              console.log('portalSubscriptionActivation: ', data);
            });
        }
      });
  }

  cancelSubscription() {
    const portalUser = this.cacheService.getItem('portalUser');
    this.portalSubscriptionService
      .cancelPortalSubscription(
        this.portalSubscription && this.portalSubscription.id,
      )
      .subscribe((res) => {
        console.log('cancelSubscription: ', res);
        if (res && res.status === 'success') {
          this.portalSubscriptionService
            .getPortalSubscriptionByCustomer(
              portalUser && portalUser.portal_end_customer_id,
            )
            .subscribe((data) => {
              console.log('portalSubscriptionCancellation: ', data);
            });
        }
      });
  }
}
