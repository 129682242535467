<div class="overflow-y-scroll" style="max-height: 65vh">
  <div class="surface-section px-4 py-4 md:px-2 lg:px-4">
    <div
      class="border-blue-600 font-medium text-2xl p-3 mb-1 text-900"
      style="border-left: 8px solid"
    >
      Company Calling Script
    </div>
    <!-- <div class="text-600 text-sm mb-3">
      <span>Updated </span>
      <span class="font-bold">April 6, 2021 (3 days ago)</span>
      <span> by </span>
      <span class="font-bold">Johann Piek</span>
    </div> -->

    <div class="line-height-3 text-md text-700 mb-3">
      Good day, I am calling from Stem Connect
    </div>
    <!-- <div class="mb-5">
    <p-tag value="New" class="mr-2"></p-tag>
    <p-tag value="Technology" severity="success" class="mr-2"></p-tag>
    <p-tag value="Education" severity="warning"></p-tag>
  </div> -->
    <div class="line-height-3 text-md text-700 mb-4">
      I hope you're having a great day. I was wondering if you can help me. Are
      you the right person to speak to in connection with possibly setting up a
      meeting with you IT or Financial manager to have an open discussion on
      your WiFi and Fibre connection?
    </div>
    <div class="line-height-3 text-md text-700 mb-4">
      <p-tag
        value="Correct Key Individual?"
        severity="success"
        class="block mr-2 mt-3 text-md"
      ></p-tag>
      We are currently working extensively in your area so I'd like to schedule
      a brief meeting with one of our experienced sales representatives to
      discuss how we can tailor a package to suit your requirements.
      <p-tag
        value="Benefits"
        severity="success"
        class="block mr-2 mt-3 text-md"
      ></p-tag>
      Here are some key benefits of our internet services:
      <ul class="mb-4 text-md ml-6 p-0">
        <li class="mb-3">
          Symmetrical download and upload speeds to suit your needs.
        </li>
        <li class="mb-3">A variety of plans to fit your budget.</li>
        <li class="mb-3">Outstanding Customer support.</li>
        <li>Penalty matrix for SLA not being met.</li>
      </ul>
      <p-tag
        value="Incorrect Key Individual?"
        severity="danger"
        class="block mr-2 mt-3 text-md"
      ></p-tag>
      Can I speak with the correct person?
    </div>
    <!-- <blockquote class="line-height-3 text-xl text-600 mb-4 py-0 mx-0 px-4">
    “In short, GPU.js is a JavaScript acceleration library that can be used for
    general-purpose computations on GPUs using JavaScript. It supports browsers,
    Node.js and TypeScript.”
  </blockquote> -->
    <div class="line-height-3 text-md text-700 mb-4">
      <p-tag
        value="Qualification Questions"
        severity="warning"
        class="block mr-2 mt-3 text-md"
      ></p-tag
      >To ensure we make the most of your time, could you please answer a couple
      of quick questions?
      <ul class="mb-4 text-md ml-6 p-0">
        <li class="mb-3">
          How satisfied are you with your current internet or voice service?
        </li>
        <li class="mb-3">Who is your current ISP?</li>
        <li class="mb-3">
          How satisfied are you with your current internet or voice service?
        </li>
        <li class="mb-3">
          What specific challenges or concerns do you have with your current
          provider?
        </li>
        <li class="mb-3">
          What is your primary uses for internet? (e.g., voice, fibre, trading,
          etc.)
        </li>
        <li>How many people use the internet at the office/business?</li>
      </ul>
    </div>

    <!-- <img
      src="assets/images/blocks/content/content-1.jpg"
      alt="Image"
      class="w-full border-round"
    /> -->
    <!-- <span class="block text-700 line-height-3 mt-2"
      >Id porta nibh venenatis cras sed felis eget velit.</span
    > -->

    <!-- <div class="text-2xl text-900 mb-4">
    Massa vitae tortor condimentum lacinia
  </div> -->
    <div class="line-height-3 text-md text-700 mb-4">
      <p-tag
        value="Closing"
        severity="warning"
        class="block mr-2 mt-3 text-md"
      ></p-tag>
      If you have any immediate questions or concerns, please don't hesitate to
      ask. We're here to provide you with the best internet service experience
      possible. Thank you for considering Stem Connect. We look forward to
      connecting with you soon and improving your internet experience. Have a
      great day!
    </div>
  </div>
</div>
