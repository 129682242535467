<div class="font-medium text-3xl text-900 mb-3">Customer Files</div>
<ng-container *ngIf="files?.length === 0; else showFiles">
  <div class="text-500 mb-5">No files uploaded for this customer</div>
</ng-container>

<ng-template #showFiles>
  <ul class="list-none p-0 m-0 border-top-1 border-300">
    <li
      *ngFor="let file of files"
      class="flex align-items-center py-3 px-2 flex-wrap surface-ground"
    >
      <div class="text-500 w-full md:w-4 font-medium">
        {{ file.unique_file_reference }}
      </div>
      <div class="text-500 w-full md:w-4 font-medium">
        {{ file.original_filename }}
      </div>
      <div class="text-900 w-full md:w-4">
        <button
          (click)="
            downloadFile(
              file.unique_file_reference || '',
              file.original_filename || ''
            )
          "
          pButton
          icon="pi pi-download"
          class="p-button p-button-link"
          [label]="file?.unique_file_reference || ''"
        ></button>
      </div>
    </li>
  </ul>
</ng-template>
