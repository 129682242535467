import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadScreenComponent } from './components/lead-screen/lead-screen.component';
import { FormsModule } from '@angular/forms';
import { CrmRoutingModule } from './crm-routing.module';

import { LeadMainComponent } from './components/lead-main/lead-main.component';

import { LeadDetailComponent } from './components/lead-detail/lead-detail.component';
import { LeadContactComponent } from './components/lead-contact/lead-contact.component';
import { LeadTimelineComponent } from './components/lead-timeline/lead-timeline.component';

import { KeyIndividualFormComponent } from './components/key-individual-form/key-individual-form.component';
import { LeadScriptComponent } from './components/lead-script/lead-script.component';

import { PrimeModule } from 'src/app/prime.module';
import { ShareModule } from 'src/app/_share/share.module';
import { ActionsFormComponent } from './components/actions-form/actions-form.component';

@NgModule({
  declarations: [
    LeadScreenComponent,
    LeadMainComponent,
    LeadDetailComponent,
    LeadContactComponent,
    LeadTimelineComponent,

    KeyIndividualFormComponent,
    LeadScriptComponent,
    ActionsFormComponent,
  ],
  imports: [
    //angular
    CommonModule,
    FormsModule,
    ShareModule,

    // app

    CrmRoutingModule,

    // primeng
    PrimeModule,
  ],
  exports: [],
  providers: [],
})
export class CrmModule {}
