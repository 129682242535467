<div class="grid flex flex-row justify-content-center">
  <div class="flex flex-column align-items-center w-12 lg:w-3">
    <h3>Account Status</h3>
    <ng-container
      *ngIf="subscriptionStatistics$ | async as stats; else loadingAccounts"
    >
      <p-chart
        [data]="stats.account_status_data"
        type="pie"
        width="20vw"
        height="25vh"
      ></p-chart>
    </ng-container>
    <ng-template #loadingAccounts>
      <p-skeleton width="20vh" height="25vh"></p-skeleton>
    </ng-template>
  </div>
  <div class="flex flex-column align-items-center w-12 lg:w-3">
    <h3>Subscription Status</h3>
    <ng-container
      *ngIf="
        subscriptionStatistics$ | async as stats;
        else loadingSubscriptionAccounts
      "
    >
      <p-chart
        [data]="stats.subscription_status_data"
        type="pie"
        width="20vw"
        height="25vh"
        [responsive]="true"
      ></p-chart>
    </ng-container>
    <ng-template #loadingSubscriptionAccounts>
      <p-skeleton width="20vh" height="25vh"></p-skeleton>
    </ng-template>
  </div>
  <div class="flex flex-column align-items-center w-12 lg:w-6">
    <h3>Subscription Activity (per week)</h3>
    <ng-container
      *ngIf="
        subscriptionStatistics$ | async as stats;
        else loadingSubscriptionAccounts
      "
    >
      <p-chart
        [data]="stats.subscriptions_per_week_data"
        type="bar"
        width="40vw"
        height="25vh"
      ></p-chart>
    </ng-container>
    <ng-template #loadingSubscriptionAccounts>
      <p-skeleton width="40vh" height="25vh"></p-skeleton>
    </ng-template>
  </div>
</div>
