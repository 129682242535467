<app-share-form
	[formState]="formState"
	[form]="portalUserRegisterForm"
	(submit)="onRegisterFormSubmit()"
	[error]="error"
></app-share-form>
<div
	class=""
	*ngIf="this.formState == 'confirm'"
>
	<form
		[formGroup]="portalUserConfirmForm"
		(ngSubmit)="onConfirmFormSubmit()"
	>
		<div class="grid formgrid p-fluid">
			<div class="field mb-4 col-12 md:col-6">
				<label
					for="confirmation_pin"
					class="font-medium text-900"
					>OTP</label
				>
				<input
					id="confirmation_pin"
					formControlName="confirmation_pin"
					type="text"
					pInputText
				/>
			</div>
			<div class="md:col-6"></div>
			<div class="field mb-4 col-12 md:col-3">
				<p>Did not recieve an OTP?</p>
				<button
					pButton
					label="Resend OTP"
					(click)="resendConfirmationOTP()"
					type="button"
				></button>
			</div>

			<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
			<div class="col-12">
				<button
					pButton
					pRipple
					label="Submit User"
					class="w-auto mt-3"
				></button>
			</div>
		</div>
	</form>
</div>
<div *ngIf="formState == 'registered'">
	<h2>
		You have successfully been registered as a user. You can now sign in using
		your credentials or continue your purchase.
	</h2>
</div>
