import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LazyLoadEvent, MenuItem } from 'primeng/api';

import { EntityService } from 'src/app/features/main-application/_services/entity.service';

import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() data: any;
  @Input() columns: any;
  @Input() activeTab: any;
  @Input() pageAmount: number = 100;
  private refreshTrigger = new Subject<void>();
  loading: boolean = true;

  @Output() messageEvent = new EventEmitter<any>();

  sndMessage(data: any) {
    this.messageEvent.emit(data);
  }

  @Output() selectedRowCMObservable$: EventEmitter<any> =
    new EventEmitter<any>();

  environment: any = environment;

  controlIDTypeMap: any = {
    vlan_id: { key: 'vlan', descriptorFields: 'description' },
    vno_id: { key: 'vno', descriptorFields: 'name' },
    region_id: { key: 'region', descriptorFields: 'name' },
    isp_id: { key: 'isp', descriptorFields: 'name' },
    product_id: { key: 'product', descriptorFields: 'name' },
    contact_id: { key: 'contact', descriptorFields: 'name' },
    site_id: { key: 'site', descriptorFields: 'name' },
    end_customer_id: { key: 'endcustomer', descriptorFields: 'name' },
    device_id: { key: 'device', descriptorFields: 'name' },
    equipment_type_id: { key: 'equipmenttype', descriptorFields: 'model' },
    end_customer_circuit_id: {
      key: 'endcustomercircuit',
      descriptorFields: 'circuit_no',
    },
  };

  selectedRow: any;
  menuItems: MenuItem[];
  selectedRowCM: any;

  globalFilterFields: any = [];

  constructor(
    private entityService: EntityService,
    private api: ApiService,
    private router: Router,
  ) {
    this.menuItems = [
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          if (this.selectedRowCM) {
            this.selectedRowCMObservable$.emit(this.selectedRowCM);
          }
        },
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: (event) => {
          if (this.selectedRowCM) {
            this.entityService.deleteSelectedRowCM(
              this.activeTab.key,
              this.selectedRowCM.id,
            );
          }
        },
      },
    ];
  }

  ngOnInit(): void {
    this.getAllFieldsForGlobalFilter();
    this.loadData();
  }

  loadData() {
    this.data.subscribe((res: any) => {
      this.loading = true;
    });

    this.loading = false;
  }

  convertEncodingToFile(fileBytes: Blob) {
    const file = new FileReader();
    file.readAsDataURL(fileBytes);
    file.onload = (event) => {};
  }

  onContextMenu($event: Event) {
    throw new Error('Method not implemented.');
  }
  onRowSelect($event: any) {
    console.log(this.selectedRow);
    this.messageEvent.emit(this.selectedRow);
  }

  getInputValue(e: Event): any {
    const inputelement = e.target as HTMLInputElement;
    return inputelement.value;
  }

  clearFilterInputValue() {
    const inputelement = document.getElementsByName(
      'globalFilter',
    )[0] as HTMLInputElement;
    inputelement.value = '';
  }

  getValueFromRelationship(path: string, object: any) {
    if (path && object) {
      return path.split('.').reduce((o, p) => o && o[p], object);
    }
  }

  navigateToRelationship(path: string, object: any) {
    if (path && object) {
      const relatedEntityName = path.split('.')[0];
      const relatedEntityNamePath = path.split('.')[0].replace(/_/g, '');
      const relatedEntityId = object[relatedEntityName].id;

      // todo: implement scroll to or view the related entity
      this.router.navigate([relatedEntityNamePath]);
    }
  }

  getValueFromRelationshipEntity(path: string, object: any) {
    if (path && object) {
      // todo: improve, might break in the future
      const entityPlural = path.split('.')[0] + 's';

      return object[entityPlural];
    }
  }

  getEntityNameFromPath(path: string) {
    if (path) {
      return path.split('.')[0];
    } else {
      return '';
    }
  }

  getFieldNameFromPath(path: string) {
    if (path) {
      return path.split('.')[1];
    } else {
      return '';
    }
  }

  getDescriptorFromPath(object: any, path: string) {
    // //console.log(object, path);

    const entityName = this.getEntityNameFromPath(path);
    const fieldName = this.getFieldNameFromPath(path);

    return object[fieldName];
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  getAllFieldsForGlobalFilter() {
    this.globalFilterFields = [];
    this.columns.forEach((column: any) => {
      if (column.field) {
        this.globalFilterFields.push(column.field);
      }
      if (column.relatedField) {
        this.globalFilterFields.push(column.relationshipFields);
      }
    });
  }

  downloadFile(toc_file_reference: string) {
    //console.log(toc_file_reference);
    this.api
      .getFileAPI('download-file', toc_file_reference)
      .subscribe((response: any) => {
        // //console.log(data);
        // this.convertEncodingToFile(data);
        const mimetype = 'application/pdf';
        const file = response.body;
        const downloadFile = new File([file], `${toc_file_reference}.pdf`, {
          type: mimetype,
        });
        const url = window.URL.createObjectURL(downloadFile);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      });
  }

  clearFilter(table: any) {
    table.clear();
    this.clearFilterInputValue();
  }

  getSeverityFromStatus(status: string) {
    switch (status) {
      case 'new':
        return 'warning';
      case 'processed':
        return 'success';
      case 'error':
        return 'danger';
      default:
        return 'danger';
    }
  }

  getSeverityFromBool(configured: boolean) {
    if (configured) {
      return 'success';
    } else {
      return 'danger';
    }
  }

  getLength(array: any) {
    if (array) {
      return array.length;
    }
  }
}
