import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/_core/_services/api.service';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss'],
})
export class ShareFormComponent implements OnInit {
  submitted = false;
  loading = false;
  idUploaded: any = false;
  resUploaded: any = false;
  uploadedIdFile: any = null;
  uploadedResFile: any = null;
  fileUploadSubscription$: any;

  @Input() error: any;
  @Input() formState: string = 'register';
  @Input() identificationTypes: any = [
    { label: 'RSA ID', value: 'rsaid' },
    { label: 'Passport (Non RSA citizen)', value: 'passport' },
  ];
  @Input() form: FormGroup = new FormGroup({});

  formFields: any[] = [
    {
      controlName: 'first_name',
      label: 'First Name',
      type: 'text',
      errorMessage: '* Please enter your first name',
      validators: { required: true },
    },
    {
      controlName: 'last_name',
      label: 'Last Name',
      type: 'text',
      errorMessage: '* Please enter your last name',
      validators: { required: true },
    },
    {
      controlName: 'identification_type',
      label: 'Identification Type',
      type: 'radio',
      errorMessage: '* Please select your identification type',
      validators: { required: true },
    },
    {
      controlName: 'identification_reference',
      label: 'Identification/Passport Number',
      type: 'text',
      errorMessage: '* Please enter your identification number',
      validators: { required: true },
    },
    {
      controlName: 'mobile_number',
      label: 'Cellphone/Mobile Number',
      type: 'text',
      errorMessage: '* Please enter your mobile number',
      validators: { required: true },
    },
    {
      controlName: 'alternate_contact_number',
      label: 'Alternate Contact Number',
      type: 'text',
    },
    {
      controlName: 'email',
      label: 'Email',
      type: 'text',
      errorMessage: '* Please enter your email address',
      validators: { required: true },
    },
    {
      controlName: 'street_address',
      label: 'Street Address',
      type: 'textarea',
    },
    {
      controlName: 'postal_address',
      label: 'Postal Address',
      type: 'textarea',
    },
  ];

  constructor(private fb: FormBuilder, private api: ApiService) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      if (this.error) {
        this.error = null;
      }
      this.submitted = false;
    });
  }

  get f() {
    return this.form.controls;
  }

  uploadIdFile(event$: any) {
    const file = event$.files[0];
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('portal_end_customer_id', this.registeredUserId);
    formData.append('document_type', 'id');

    this.fileUploadSubscription$ = this.api
      .postFileAPI('portal/user/upload-file', formData)
      .subscribe(
        (response: any) => {
          const data = response.body;
          console.log('File upload response:', data);
        },
        (error: any) => {
          console.error('File upload failed:', error);
          // Add user-friendly error handling or notifications here
        }
      );

    // ... rest of your code

    this.uploadedIdFile = file;
  }

  onIdUpload($event: any) {
    const file = $event.files[0];
    this.idUploaded = null;
  }

  uploadResFile($event: any) {
    throw new Error('Method not implemented.');
  }

  onResUpload($event: any) {
    const file = $event.files[0];
    this.resUploaded = null;
  }

  onSubmit() {
    this.submitted = true;
  }
}
