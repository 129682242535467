import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalCustomersService {
  constructor(private api: ApiService) {}

  getPortalCustomers() {
    return this.api.getAPI('portal/management/customers').pipe(
      map((res: any) => {
        return res.body.data;
      })
    );
  }
}
