/* tslint:disable */

/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

import { PortalSubscriptions } from './portal-subscriptions.model';

export type IdentificationType = 'passport' | 'rsaid';
export type SubscriptionInitType = 'onceoff' | 'onceoff_and_recurring';
export type PaymentMethod = 'creditcard' | 'cash';
export type SubscriptionApplicationType = 'manual' | 'salesagent';
export type SubscriptionStatus =
  | 'new_sales_lead'
  | 'pending_installation'
  | 'active'
  | 'suspended_payment'
  | 'cancelled';

export interface PortalConfirmationCreate {
  portal_end_customer_id?: number;
  confirmation_email?: string;
  confirmation_mobile_number?: string;
  confirmation_pin?: string;
  new_password: string;
}
export interface PortalEndCustomer {
  first_name: string;
  last_name: string;
  identification_reference: string;
  identification_type: string;
  mobile_number: string;
  email: string;
  alternate_contact_number?: string;
  complex_building?: string;
  unit_number?: string;
  street_address?: string;
  postal_address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  password?: string;
  id?: number;
  active?: boolean;
  confirmed?: boolean;
  hashedpassword?: string;
}
export interface PortalEndCustomerBase {
  first_name: string;
  last_name: string;
  identification_reference: string;
  identification_type: string;
  mobile_number: string;
  email: string;
  alternate_contact_number?: string;
  complex_building?: string;
  unit_number?: string;
  street_address?: string;
  postal_address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  password?: string;
}
export interface PortalEndCustomerCreate {
  first_name: string;
  last_name: string;
  /**
   * Passport or RSA ID number
   */
  identification_reference: string;
  identification_type: IdentificationType;
  mobile_number: string;
  email: string;
  alternate_contact_number?: string;
  complex_building?: string;
  unit_number?: string;
  street_address?: string;
  postal_address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  /**
   * Password for the customer
   */
  password: string;
}
export interface PortalEndCustomerManagement {
  first_name: string;
  last_name: string;
  identification_reference: string;
  identification_type: string;
  mobile_number: string;
  email: string;
  alternate_contact_number?: string;
  complex_building?: string;
  unit_number?: string;
  street_address?: string;
  postal_address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  id?: number;
  active?: boolean;
  confirmed?: boolean;
  portal_files?: PortalFiles;
}
export interface PortalFiles {
  portal_end_customer_id: number;
  document_type: string;
  id?: number;
  unique_file_reference?: string;
  original_filename?: string;
  date_uploaded?: string;
  file_type?: string;
  file_size?: number;
}
export interface PortalFilesCreate {
  portal_end_customer_id: number;
  document_type: string;
}
export interface PortalPasswordResetCreate {
  confirmation_email?: string;
  confirmation_mobile_number?: string;
  confirmation_pin?: string;
  new_password: string;
}
export interface PortalProducts {
  product_type: string;
  product_category?: string;
  name: string;
  description: string;
  recurring_price_excl?: number;
  recurring_price_incl?: number;
  recurring_description?: string;
  speed_down_mbps?: number;
  speed_up_mbps?: number;
  currency: string;
  onceoff_price_excl?: number;
  onceoff_price_incl?: number;
  onceoff_description?: string;
  renewal_type: string;
  duration_days?: number;
  id?: number;
}

export interface PortalProductsUpdate {
  product_type: string;
  name: string;
  description: string;
  recurring_price_excl?: number;
  recurring_price_incl?: number;
  recurring_description?: string;
  speed_down_mbps?: number;
  speed_up_mbps?: number;
  currency: string;
  onceoff_price_excl?: number;
  onceoff_price_incl?: number;
  onceoff_description?: string;
  renewal_type: string;
  duration_days?: number;
  id?: number;
  available?: boolean;
}
export interface PortalProductsCreate {
  product_type: string;
  name: string;
  description: string;
  recurring_price_excl?: number;
  recurring_price_incl?: number;
  recurring_description?: string;
  speed_down_mbps?: number;
  speed_up_mbps?: number;
  currency: string;
  onceoff_price_excl?: number;
  onceoff_price_incl?: number;
  onceoff_description?: string;
  renewal_type: string;
  duration_days?: number;
}
export interface PortalServiceActivation {
  serial_number?: string;
  mac_address: string;
  speed_down_mbps: number;
  speed_up_mbps: number;
  customer_first_name?: string;
  customer_last_name?: string;
  customer_mobile_number?: string;
  customer_email?: string;
}

export interface PortalSubscriptionsCreate {
  subscription_initialisation_type?: SubscriptionInitType & string;
  preferred_payment_method?: PaymentMethod & string;
  billing_auto_renew: boolean;
  application_type?: SubscriptionApplicationType & string;
  device_reference?: string;
  portal_product_id: number;
  portal_end_customer_id: number;
}
export interface PortalSubscriptionsUpdate {
  payment_method?: string;
  auto_renew?: boolean;
  portal_product_id?: number;
  subscription_status?: SubscriptionStatus;
  payment_status?: string;
}
export interface PortalTransactions {
  portal_end_customer_id: number;
  portal_subscription_id?: number;
  portal_product_id?: number;
  transaction_date?: string;
  transaction_date_due: string;
  amount_excl: number;
  amount_incl: number;
  currency: string;
  transaction_type: string;

  status: string;
  reference: string;
  refrence_external?: string;
  transaction_description?: string;
  transaction_method?: string;
  id?: number;
  portal_end_customer?: PortalEndCustomer;
  portal_subscription?: PortalSubscriptions;
  portal_product?: PortalProducts;
}
export interface PortalTransactionsCreate {
  portal_end_customer_id: number;
  portal_subscription_id?: number;
  portal_product_id?: number;
  transaction_date?: string;
  transaction_date_due: string;
  amount_excl: number;
  amount_incl: number;
  currency: string;
  transaction_type: string;
  status: string;
  reference: string;
  refrence_external?: string;
  transaction_description?: string;
  transaction_method?: string;
}
export interface PortalTransactionsUpdate {
  status?: string;
  external_reference?: string;
  transaction_method?: string;
  transaction_date?: string;
}
