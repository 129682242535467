<label for="pageamount">Page Amount</label>
<br />
<p-dropdown
  [options]="pageAmountOptions"
  [(ngModel)]="pageAmount"
  id="pageamount"
></p-dropdown>
<br />
<br />
<button pButton type="button" (click)="changePageAmount()">Change</button>
