<div class="overflow-y-scroll" style="max-height: 65vh">
  <div class="surface-section px-4 py-4 md:px-2 lg:px-4">
    <div
      class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900"
      style="border-left: 8px solid"
    >
      Lead History
    </div>
    <p-divider></p-divider>
    <div class="flex gap-1 flex-column-reverse md:flex-row">
      <div class="flex-auto p-fluid w-12">
        <p-timeline [value]="lead && lead.actions" align="alternate">
          <ng-template pTemplate="marker" let-action>
            <span class="">
              <i [ngClass]="'pi pi-hashtag'"></i>
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-action>
            <div class="flex flex-row">
              <div
                class="flex-auto p-fluid border-1 p-2 border-round border-400"
              >
                <div class="text-900 font-semibold text-blue-600 text-lg">
                  {{ action.action_type | uppercase }}
                </div>
                <div class="text-800 text-lg">
                  {{ action.date_created | dateAsAgo }}
                </div>
                <div class="text-500 text-sm">
                  {{ action.date_created | date : "yyyy/MM/dd HH:mm:ss" }}
                </div>
                <p class="align-items-center">
                  {{ action.action_details }}
                </p>
                <div class="text-500 text-xs">
                  {{ action.action_owner && action.action_owner.first_name }}
                  {{ action.action_owner && action.action_owner.last_name }}
                </div>
              </div>
            </div>
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
</div>
