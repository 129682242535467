import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalSubscriptionService {
  constructor(private api: ApiService) {}

  getPortalSubscriptions() {
    return this.api.getAPI('portal/management/subscriptions').pipe(
      map((res: any) => {
        console.log('res subs ', res);
        return res.body.data;
      }),
    );
  }

  getPortalSubscriptionByCustomer(portalCustomerId: number) {
    return this.api
      .getAPI(`portal/management/subscriptions/customer/${portalCustomerId}`)
      .pipe(map((res: any) => res.body.data));
  }
  getPortalSubscriptionStatistics() {
    return this.api
      .getAPI(`portal/management/statistics/subscriptions`)
      .pipe(map((res: any) => res.body));
  }

  getPortalSubscriptionById(portalSubscriptionId: number) {
    return this.api
      .getAPI(`portal/subscriptions/${portalSubscriptionId}`)
      .pipe(map((res: any) => res.body.data));
  }

  activatePortalSubscription(
    portalSubscriptionId: number,
    portalSubscriptionActivation: any,
  ) {
    return this.api.postAPI(
      `portal/subscriptions/activate/${portalSubscriptionId}`,
      portalSubscriptionActivation,
    );
  }

  updatePortalSubscription(
    portalSubscriptionId: number,
    portalSubscriptionUpdate: any,
  ) {
    return this.api.patchAPI(
      `portal/management/subscriptions/update/${portalSubscriptionId}`,
      portalSubscriptionUpdate,
    );
  }

  cancelPortalSubscription(portalSubscriptionId: number) {
    return this.api.postAPI(
      `portal/subscriptions/cancel/${portalSubscriptionId}`,
      {},
    );
  }
}
