<div class="surface-card shadow-1 border-round p-4">
  <div class="grid" style="max-width: 100vw">
    <div class="col-12">
      <div class="grid">
        <div class="col-12">
          <p-tabView>
            <!-- <p-tabPanel header="Overview"> </p-tabPanel> -->
            <p-tabPanel *ngFor="let tab of this.tabs" [header]="tab.name">
              <div class="grid" style="max-width: 100vw">
                <ng-container [ngSwitch]="tab.key">
                  <ng-container *ngSwitchCase="'overview'">
                    <div
                      class="justify-content-center align-items-center text-center h-full min-h-full flex flex-column"
                    >
                      <h2 class="flex-1">Check back soon! Work in progress</h2>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'products-and-promotions'">
                    <!-- col - 3  -->
                    <div class="col-12 md:col-0"></div>
                    <!-- col - 6 -->
                    <div class="col-9 md:col-6">
                      <div
                        class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
                      >
                        <div class="font-medium text-3xl text-900">
                          Products
                        </div>
                        <div class="mt-3 md:mt-0">
                          <button
                            pButton
                            pRipple
                            label="Add"
                            class="p-button-outlined mr-2"
                            icon="pi pi-plus"
                          ></button>
                        </div>
                      </div>
                      <ng-container *ngFor="let category of categories">
                        <div class="grid">
                          <p-divider class="col-12">
                            <div class="text-800 font-medium text-3xl">
                              {{ category.name }}
                            </div>
                          </p-divider>
                          <!-- products -->

                          <div
                            class="col-12 md:col-6 xl:col-4 p-3"
                            *ngFor="let product of products"
                          >
                            <!-- todo: implement categories -->
                            <div
                              class="surface-card shadow-2 border-round p-4"
                              *ngIf="product.product_category === category.key"
                            >
                              <div
                                class="align-items-center surface-border grid"
                              >
                                <span
                                  class="text-xl text-900 font-medium mb-2 text-center col-12"
                                  >{{ product.name }}</span
                                >
                                <span
                                  class="text-2xl text-800 block font-semibold text-center col-12"
                                  >{{
                                    product.recurring_price_incl
                                      | currency: "NAD" : "symbol"
                                  }}</span
                                >
                                <span
                                  class="text-600 font-medium col-12 text-center"
                                  >{{
                                    product.product_type === "fixed"
                                      ? "Month-to-Month"
                                      : ""
                                  }}</span
                                >
                                <!-- <span
                                class="text-600 font-medium col-12 text-center"
                                >Duis Aute Irure</span
                              >
                              <span
                                class="text-600 font-medium col-12 text-center"
                                >Duis Aute Irure</span
                              > -->
                                <div
                                  class="col-6 border-right-1 border-gray-400 text-center"
                                >
                                  <span
                                    class="text-blue-500 font-medium text-center text-lg"
                                    ><i class="pi pi-arrow-down"> </i>
                                    {{ product.speed_down_mbps }} Mbps</span
                                  >
                                  <br />
                                  <span class="text-600 font-medium text-center"
                                    >Download
                                  </span>
                                </div>

                                <div
                                  class="col-6 border-left-1 border-gray-400 text-center"
                                >
                                  <span
                                    class="text-green-500 font-medium text-center text-lg"
                                    ><i class="pi pi-arrow-up"> </i
                                    >{{ product.speed_up_mbps }} Mbps
                                  </span>
                                  <br />
                                  <span class="text-600 font-medium text-center"
                                    >Upload
                                  </span>
                                  <span></span>
                                </div>
                                <div class="col-12 pt-3">
                                  <button
                                    pButton
                                    type="button"
                                    label="View"
                                    class="p-button-raised p-button-rounded w-full"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <!-- col - 4 -->
                    <div class="col-12 md:col-3">
                      <div
                        class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
                      >
                        <div class="grid">
                          <div class="col-12">
                            <div class="font-medium text-3xl text-900">
                              Promotions
                            </div>
                            <div class="mt-3 md:mt-0">
                              <button
                                pButton
                                pRipple
                                label="Add"
                                class="p-button-outlined mr-2"
                                icon="pi pi-plus"
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- grid -->
                      <div class="grid">
                        <div class="col-12">
                          <p-orderList
                            [value]="promotions"
                            [listStyle]="{ 'max-height': '30rem' }"
                            header="List of Promotions"
                            filterBy="name"
                            filterPlaceholder="Filter by name"
                            [dragdrop]="true"
                          >
                            <ng-template pTemplate="header">
                              <span
                                class="text-xl text-900 font-medium mb-2 text-center col-12"
                                >Promotions</span
                              >
                            </ng-template>
                            <ng-template let-promotion pTemplate="item">
                              <div class="product-item text-2xl">
                                <div class="image-container">
                                  <!-- <img
                                      src="assets/showcase/images/demo/product/{{
                                        product.image
                                      }}"
                                      [alt]="product.name"
                                      class="product-image"
                                    /> -->
                                </div>
                                <div class="product-list-detail">
                                  <h5 class="mb-2">{{ promotion.name }}</h5>

                                  <div class="product-category">
                                    <p-chip
                                      icon="pi pi-check"
                                      [label]="promotion.promotion_type"
                                    ></p-chip>
                                  </div>
                                  <br />
                                  <span
                                    >{{ promotion.dateStart }}
                                    {{ promotion.dateEnd }}</span
                                  >
                                </div>
                                <div class="product-list-action">
                                  <h6 class="mb-2">${{ promotion.price }}</h6>
                                </div>
                              </div>
                            </ng-template>
                          </p-orderList>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'isps'">
                    <!-- col - 3  -->
                    <!-- <div class="col-12 md:col-0"></div> -->
                    <!-- col - 6 -->
                    <div class="col-9 md:col-3">
                      <div
                        class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
                      >
                        <div class="font-medium text-3xl text-900">ISPs</div>
                        <div class="mt-3 md:mt-0">
                          <button
                            pButton
                            pRipple
                            label="Add"
                            class="p-button-outlined mr-2"
                            icon="pi pi-plus"
                          ></button>
                        </div>
                      </div>
                      <ng-container
                        *ngIf="customers$ | async as customers; else loading"
                      >
                        <p-listbox
                          [options]="(customers$ | async) || []"
                          [(ngModel)]="selectedCustomer"
                          optionLabel="name"
                        >
                          <ng-template let-customer pTemplate="item">
                            <div
                              class="surface-card shadow-2 border-round p-4 mb-3 w-full"
                            >
                              <div class="grid">
                                <div class="col-12">
                                  <h3 class="text-900">{{ customer.name }}</h3>
                                  <p class="text-600">{{ customer.email }}</p>
                                  <p class="text-600">
                                    <a
                                      href="{{ customer.website }}"
                                      target="_blank"
                                      >{{ customer.website }}</a
                                    >
                                  </p>
                                  <p class="text-600">
                                    Tax Reference: {{ customer.tax_reference }}
                                  </p>
                                  <p class="text-600">Postal Address:</p>
                                  <ul class="list-none p-0 m-0">
                                    <li>{{ customer.postal_address_01 }}</li>
                                    <li>{{ customer.postal_address_02 }}</li>
                                    <li>{{ customer.postal_address_03 }}</li>
                                    <li>{{ customer.postal_address_04 }}</li>
                                    <li>{{ customer.postal_address_05 }}</li>
                                  </ul>
                                  <p class="text-600">Delivery Address:</p>
                                  <ul class="list-none p-0 m-0">
                                    <li>{{ customer.delivery_address_01 }}</li>
                                    <li>{{ customer.delivery_address_02 }}</li>
                                    <li>{{ customer.delivery_address_03 }}</li>
                                    <li>{{ customer.delivery_address_04 }}</li>
                                    <li>{{ customer.delivery_address_05 }}</li>
                                  </ul>
                                  <p class="text-600">ID: {{ customer.id }}</p>
                                  <p class="text-600">
                                    Active: {{ customer.active ? "Yes" : "No" }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-listbox>
                      </ng-container>
                      <ng-template #loading>
                        <div
                          class="flex flex-column align-items-center justify-content-center"
                        >
                          <p-progressSpinner
                            style="width: 50px; height: 50px"
                          ></p-progressSpinner>
                          <p>Fetching customers...</p>
                        </div>
                      </ng-template>
                    </div>

                    <!-- col - 4 -->
                    <div class="col-3 md:col-6">
                      <div
                        class="flex flex-column md:align-items-center md:justify-content-between md:flex-row"
                      >
                        <!-- <div class="grid">
                          <div class="col-12">
                            <div class="font-medium text-3xl text-900">
                              Promotions
                            </div>
                            <div class="mt-3 md:mt-0">
                              <button
                                pButton
                                pRipple
                                label="Add"
                                class="p-button-outlined mr-2"
                                icon="pi pi-plus"
                              ></button>
                            </div>
                          </div>
                        </div> -->
                      </div>

                      <!-- grid -->
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </p-tabPanel></p-tabView
          >
        </div>
      </div>
    </div>
  </div>
</div>
