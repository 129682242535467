import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LeadActionsService {
  actionDefinitions: any;

  constructor(private http: HttpClient) {}

  getLeadActionsDefinitions() {
    this.http
      .get('/assets/action-definitions.json')
      .subscribe((actionData: any) => {
        this.actionDefinitions = actionData;
        console.log(this.actionDefinitions);
      });
  }
}
