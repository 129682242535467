import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeS'
})
export class RemoveSPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) {
      return value;
    }
    return value.endsWith('s') ? value.slice(0, -1) : value;
  }

}
