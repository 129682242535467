import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  transform(size: number, dp: number = 1) {
    const threshold = 1024;
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (size < threshold) {
      return size + ' B';
    }

    let u = -1;
    const r = 10 ** dp;

    do {
      size /= threshold;
      ++u;
    } while (
      Math.round(Math.abs(size) * r) / r >= threshold &&
      u < units.length - 1
    );

    return size.toFixed(dp) + ' ' + units[u];
  }
}
