import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map } from 'rxjs';
import { ApiService } from 'src/app/_core/_services/api.service';
import { phoneNumberValidator } from 'src/app/_share/_validators/phone-number.validator';

@Injectable({
  providedIn: 'root',
})
export class BusinessSalesService {
  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
  ) {}

  businessSalesProducts: any[] = [];

  activeStepIndex: number = 0;
  loading: boolean = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.loading,
  );

  submitted = false;
  submitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  qualifyingForm: FormGroup = this.fb.group({
    internetUsage: [undefined, [Validators.required]],
    ifInternetUsage: this.fb.group({
      internetType: [''],
      provider: [''],
      contractType: [''],
      ifContractType: this.fb.group({
        contractPeriod: [''],
      }),
      connectionSpeeds: [''],
      usersDaily: [0],
      monthlyCost: [0],
      usage: [''],
      failover: [undefined],
      infrastructureAwareness: [undefined],
      costVsSpeedVsReliability: [''],
    }),
    voipOrTelephony: [''],
    fibreQuotation: [undefined, [Validators.required]],
    ifFibreQuotation: this.fb.group({
      reason: [''],
      contactInFuture: [undefined],
      ifContactInFuture: this.fb.group({
        emailAddressContactInFuture: [''],
        contactNumberContactInFuture: [''],
        notesContactInFuture: [''],
      }),
    }),
  });

  businessDetailsForm: FormGroup = this.fb.group({
    businessName: ['', [Validators.required]],
    businessTelephone: ['', [Validators.required, phoneNumberValidator('ZA')]],
    businessEmail: ['', [Validators.required, Validators.email]],
    businessDetails: this.fb.group({
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      suburb: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      // country: [''],
    }),
    primaryContact: this.fb.group({
      title: ['', [Validators.required]],
      jobTitle: [''],
      firstName: ['', [Validators.required]],
      lastName: [''],
      telephone: ['', [Validators.required, phoneNumberValidator('ZA')]],
      email: ['', [Validators.required, Validators.email]],
    }),
    product: ['', [Validators.required]],
  });

  createNewBusinessSale(data: any) {
    // console.log('newData', data);

    return this.apiService.postAPI('business-sales/create-sale', data);
  }

  getBusinessSalesProducts() {
    return this.apiService.getAPI('business-sales/products').pipe(
      map((res) => {
        return res.body.results;
      }),
    );
  }
}
