import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nestedKeyvalue',
})
export class NestedKeyvaluePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return value;

    return Object.keys(value).map((key) => ({
      key: key,
      value: value[key],
    }));
  }
}
