import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-captive',
  templateUrl: './captive.component.html',
  styleUrls: ['./captive.component.scss'],
})
export class CaptiveComponent implements OnInit {
  items: { label: string; routerLink: string }[] = [];

  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Register',
        routerLink: 'register',
      },
      {
        label: 'Confirm Details',
        routerLink: 'confirm',
      },
      {
        label: 'Choose Package',
        routerLink: 'package',
      },
      {
        label: 'Payment',
        routerLink: 'payment',
      },
      {
        label: 'Activate',
        routerLink: 'activate',
      },
    ];
  }
}
