<div class="lg:px-3 px-1 py-5">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">
      <span class="text-blue-600">{{ tab.name | removeS }} Details</span>
    </div>

    <ul class="list-none p-0 m-0 border-300">
      <li
        *ngFor="let dataField of deviceFieldsToDisplay; let i = index"
        [ngClass]="{ 'surface-ground': i % 2 !== 0 }"
        class="flex align-items-center py-3 px-2 flex-wrap border-bottom-1 border-300"
      >
        <div class="text-500 w-full md:w-6 font-medium text-center">
          {{ dataField.header }}
        </div>
        <div class="text-900 w-full md:w-6 text-center">
          <ng-container [ngSwitch]="dataField.type">
            <span *ngSwitchCase="'text'">{{
              getFieldValue(dataField.field)
            }}</span>
            <span *ngSwitchCase="'date'">{{
              getFieldValue(dataField.field) | date : "YYYY-MM-dd"
            }}</span>
            <span *ngSwitchCase="'number'">{{
              getFieldValue(dataField.field)
            }}</span>
            <ng-container *ngSwitchCase="'status'">
              <p-tag
                styleClass="p-2 text-base"
                *ngIf="getFieldValue(dataField.field) as status"
                [value]="status | uppercase"
              >
              </p-tag>
            </ng-container>
            <span *ngSwitchDefault>{{ getFieldValue(dataField.field) }}</span>
          </ng-container>
        </div>
      </li>
      <li class="flex align-items-center py-3 px-2 flex-wrap">
        <div class="text-900 w-full md:w-6">
          <div class="card">
            <!-- <h4>Temperature °C:</h4> -->
            <p-chart
              type="line"
              [data]="trafficData"
              [options]="trafficOptions"
            ></p-chart>
          </div>
        </div>
        <div class="text-900 w-full md:w-6">
          <div class="card">
            <!-- <h4>Ethernet Status:</h4> -->
            <p-chart
              type="line"
              [data]="signalData"
              [options]="signalOptions"
            ></p-chart>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div>
  <div class="lg:px-6 px-1 py-5">
    <div class="surface-card p-4 shadow-2 border-round">
      <span class="p-buttonset">
        <button
          *ngFor="let entityAction of entityActions"
          pButton
          pRipple
          [label]="entityAction.label || ''"
          [icon]="entityAction.icon || ''"
          [ngClass]="entityAction.styleClass || ''"
        ></button>
      </span>
    </div>
  </div>
</div>
