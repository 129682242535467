<div class="flex flex-column h-full min-h-full">
  <div class="w-full" *ngIf="!(portalCustomer$ | async)">
    <span class="m-4 font-bold text-2xl text-900">Loading Data...</span>
    <p-progressBar
      styleClass="align-self-center m-4"
      *ngIf="!(portalCustomer$ | async)"
      mode="indeterminate"
    >
    </p-progressBar>
  </div>
  <ng-container *ngIf="portalCustomer$ | async as portalCustomerDetails">
    <app-share-user-customer-details
      [customer]="portalCustomerDetails"
    ></app-share-user-customer-details>
  </ng-container>
  <ng-container *ngIf="portalSubscription$ | async">
    <share-subscription-details
      [subscription]="portalSubscription[0]"
    ></share-subscription-details>
  </ng-container>
  <div
    *ngIf="portalCustomer$ | async as portalCustomerDetails"
    class="lg:px-6 px-1 py-5"
  >
    <div class="surface-card p-4 shadow-2 border-round">
      <share-user-files [files]="files"></share-user-files>
    </div>
  </div>
</div>
