<div class="surface-section p-2 justify-content-start w-full z-auto">
  <div *ngIf="userService.currentUser$ | async as currentUser">
    <div
      class="surface-overlay shadow-2 flex relative lg:static justify-content-between mb-1"
      style="min-height: 42px"
    >
      <a
        pRipple
        class="cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700"
        pStyleClass="@next"
        enterClass="hidden"
        leaveToClass="hidden"
        [hideOnOutsideClick]="true"
      >
        <i class="pi pi-bars text-2xl"></i>
      </a>
      <div
        class="hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
      >
        <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
          <li class="h-full">
            <a
              pRipple
              class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent transition-duration-150 py-3 lg:py-0 px-3"
              style="text-decoration: none"
              (click)="menu.show($event)"
            >
              <span class="text-blue-900 pi pi-building mr-2"></span>
              <span class="font-medium text-blue-900">{{
                getCurrentTenantName(
                  currentUser.tenant_id,
                  currentUser.tenants || []
                )
              }}</span>
              <p-menu
                #menu
                [popup]="true"
                [model]="
                  this.convertTenantsToMenuItems(currentUser.tenants || [])
                "
              ></p-menu>
            </a>
          </li>
          <ng-container *ngFor="let menuItem of menuItems">
            <li
              class="h-full"
              *hasRoles="menuItem.rolesWithAccess; for: currentUser"
            >
              <a
                pRipple
                class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent transition-duration-150 py-3 lg:py-0 px-3"
                routerLinkActive="border-blue-500 text-blue-500"
                [routerLink]="menuItem.routerLink"
                [routerLinkActiveOptions]="{ exact: true }"
                style="text-decoration: none"
              >
                <span class="pi {{ menuItem.icon }} mr-2"></span>
                <span class="font-medium white-space-nowrap">{{
                  menuItem.label
                }}</span>
              </a>
            </li>
          </ng-container>
          <!-- <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
        >
          <span class="pi pi-users mr-2"></span>
          <span class="font-medium">Customers</span>
        </a>
      </li>
      <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
        >
          <span class="pi pi-calendar mr-2"></span>
          <span class="font-medium">Calendar</span>
        </a>
      </li>
      <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
        >
          <span class="pi pi-home mr-2"></span>
          <span class="font-medium">Stats</span>
        </a>
      </li> -->
        </ul>
      </div>

      <a
        pRipple
        class="cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700"
        pStyleClass="@next"
        enterClass="hidden"
        leaveToClass="hidden"
        [hideOnOutsideClick]="true"
      >
        <i class="pi pi-ellipsis-v text-xl"></i>
      </a>
      <div
        class="hidden lg:flex lg:justify-content-end absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
      >
        <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
          <li class="h-full" *ngIf="false">
            <a
              pRipple
              class="cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150"
            >
              <span class="pi pi-book mr-2 lg:mr-0"></span>
              <span class="font-medium inline lg:hidden">Documentation</span>
            </a>
          </li>
          <li class="h-full" *ngIf="false">
            <a
              pRipple
              class="cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150"
            >
              <span class="pi pi-inbox mr-2 lg:mr-0"></span>
              <span class="font-medium inline lg:hidden">Inbox</span>
            </a>
          </li>
          <li class="h-full" *ngIf="false">
            <a
              pRipple
              class="cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150"
            >
              <span class="pi pi-bell mr-2 lg:mr-0"></span>
              <span class="font-medium inline lg:hidden">Notifications</span>
            </a>
          </li>
          <li class="h-full relative">
            <a
              pRipple
              class="cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150"
              pStyleClass="@next"
              enterClass="hidden"
              enterActiveClass="scalein"
              leaveToClass="hidden"
              leaveActiveClass="fadeout"
              [hideOnOutsideClick]="true"
            >
              <!-- <img
            src="assets/images/blocks/avatars/circle/avatar-f-1.png"
            class="lg:mr-0"
            style="width: 28px; height: 28px"
          /> -->

              <span class="mx-2 font-medium text-900"
                >{{ currentUser.first_name }} {{ currentUser.last_name }}</span
              >
              <!-- <div *hasRoles="[roles.superadmin]; for: currentUser">
              Kan jy my sien?
            </div> -->
              <small class="text-600 mx-1">{{
                currentUser.role?.description || ""
              }}</small>
              <i class="pi pi-angle-down"></i>
            </a>
            <div
              class="hidden static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0"
            >
              <ul class="list-none p-0 m-0">
                <li *ngIf="false">
                  <a
                    pRipple
                    class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3"
                  >
                    <span class="pi pi-user mr-2"></span>
                    <span class="font-medium">Profile</span>
                  </a>
                </li>
                <li>
                  <a
                    pRipple
                    class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3"
                    [routerLink]="['/settings']"
                    style="text-decoration: none"
                  >
                    <span class="pi pi-cog mr-2"></span>
                    <span class="font-medium">Settings</span>
                  </a>
                </li>
                <li>
                  <a
                    pRipple
                    class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3"
                    (click)="
                      authenticationService.logout();
                      userService.clearCurrentLoggedInUser()
                    "
                  >
                    <span class="pi pi-sign-out mr-2"></span>
                    <span class="font-medium">Sign Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<p-toast key="main"></p-toast>
