import { Component, OnInit } from '@angular/core';
import { PortalActionsService } from 'src/app/features/portal/_services/portal-actions.service';
import { BehaviorSubject } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  userActions: any[] = [];
  userActions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  isError: boolean = false;
  isLoading: boolean = true;

  columns: any[] = [
    { field: 'action_date', header: 'Action Date' },
    { field: 'action_description', header: 'Action Description' },
    { field: 'action_type', header: 'Action Type' },
    { field: 'id', header: 'ID' },
    { field: 'portal_end_customer_id', header: 'Customer ID' },
  ];

  constructor(private portalActionsService: PortalActionsService) {}

  ngOnInit(): void {
    this.portalActionsService.getUserActions().subscribe(
      (res: any) => {
        this.userActions = res;
        this.userActions$.next(this.userActions);
        this.isLoading = false;
      },
      (error) => {
        console.log('Error ', error);
        this.isError = true;
      }
    );
  }

  clear(table: Table) {
    table.clear();
  }

  getNumberofColumns() {
    return this.columns.length;
  }
}
