import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-timeline',
  templateUrl: './lead-timeline.component.html',
  styleUrls: ['./lead-timeline.component.scss'],
})
export class LeadTimelineComponent implements OnInit, OnChanges {
  @Input() lead: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.lead) {
      this.lead.actions.sort((a: any, b: any) => {
        return (
          new Date(b.date_created).getTime() -
          new Date(a.date_created).getTime()
        );
      });
    }
  }
}
