import { EStates } from './states';

export class Actions {
  static readonly note = new Actions(
    'note',
    'Note',
    'pi-paperclip',
    'Note',
    EStates.new,
  );
  static readonly call_key_individual = new Actions(
    'call-key-individual',
    'Call',
    'pi-phone',
    'Call with Key Individual',
    EStates.qualified,
  );
  static readonly call_back_convenient = new Actions(
    'call-back-convenient',
    'Call',
    'pi-phone',
    'Call back when convenient',
    EStates.new,
  );
  static readonly call_back_contract_expired = new Actions(
    'call-back-contract-expired',
    'Call',
    'pi-phone',
    'Call back when contract expires',
    EStates.qualified,
  );
  static readonly apointment_made_key_individual = new Actions(
    'appointment-made-key-individual',
    'Appointment',
    'pi-calendar',
    'Appointment made with Key Individual',
    EStates.opportunity,
  );
  static readonly email_received_key_individual = new Actions(
    'email-received-key-individual',
    'Email',
    'pi-envelope',
    'Email received from Key Individual',
    EStates.qualified,
  );
  static readonly quote_sales_key_individual = new Actions(
    'quote-sales_key-individual',
    'Quote',
    'pi-money-bill',
    'Quote sent to Key Individual by Sales',
    EStates.qualified,
  );
  static readonly quote_accepted_key_individual = new Actions(
    'quote-accepted-key-individual',
    'Quote',
    'pi-money-bill',
    'Quote accepted by Key Individual',
    EStates.closed_win,
  );

  static readonly not_interested = new Actions(
    'not-interested',
    'Not Interested',
    'pi-ban',
    'Not Interested',
    EStates.closed_loss,
  );

  static readonly bad_lead = new Actions(
    'bad-lead',
    'Not Interested',
    'pi-ban',
    'Bad Lead',
    EStates.closed_loss,
  );

  private constructor(
    private readonly key: string,
    public readonly group: string,
    public readonly icon: string,
    public readonly value: string,
    public readonly resultState: EStates,
  ) {}

  toLabelString(): string {
    return this.value;
  }

  toValueString(): string {
    return this.key;
  }
}
