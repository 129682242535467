import { Injectable } from '@angular/core';
import { ApiService } from '../../../../_core/_services/api.service';
import { Isp } from '../../../../_share/_models/entities/schema';
import { map, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IspsService {
  ispsQuery$: any;
  isps: Isp[];

  isps$: Subject<Isp[]> = new Subject<Isp[]>();

  constructor(public api: ApiService) {
    this.isps = [];
  }

  getIsps(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Isp>('isp', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addIsp(value: any) {
    const new_value = { ...value };
    for (let i = 0; i < new_value.vnos.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.vnos[i] = { id: new_value.vnos[i] };
    }
    this.ispsQuery$ = this.api.postAPI<Isp>('isp', new_value);
    return this.ispsQuery$;
  }

  updateIsp(id: any, value: any) {
    const new_value = { ...value };
    for (let i = 0; i < new_value.vnos.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.vnos[i] = { id: new_value.vnos[i] };
    }
    // console.log('new_value', new_value);

    this.ispsQuery$ = this.api.putAPI<Isp>('isp', id, new_value);
    return this.ispsQuery$;
  }

  deleteIsp(isp_id: number) {
    this.ispsQuery$ = this.api
      .deleteAPI<Isp>('isp', isp_id)
      .subscribe((res) => {
        //console.log('res', res);
      });
  }
}
