import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/_core/_services/api.service';
import { TableColumns } from 'src/app/_share/_models/components/table-columns.model';

import { EntityService } from 'src/app/features/main-application/_services/entity.service';

interface JsonFormValidators {
  min?: number;
  max?: number;
  required?: boolean;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: boolean;
  maxLength?: boolean;
  pattern?: string;
  nullValidator?: boolean;
}
interface JsonFormControlOptions {
  min?: string;
  max?: string;
  step?: string;
  icon?: string;
}
interface JsonFormControls {
  name: string;
  label: string;
  value: string;
  type: string;
  options?: JsonFormControlOptions;
  required: boolean;
  validators: JsonFormValidators;
}

export interface JsonFormData {
  controls: JsonFormControls[];
  columns: TableColumns[];
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnChanges {
  @Input() jsonForm: any;
  @Output() formEvent = new EventEmitter<FormGroup>();
  @Output() myForm: FormGroup = this.fb.group({});
  @Output() myFormArray: FormArray = this.fb.array([]);

  @ViewChild('toc_file') tocFileUpload: any;
  // todo: consider using formarray
  // https://blog.angular-university.io/angular-form-array/
  tab: any;

  selectOptions: any[] = [];
  formData: any;
  selectedOptions: any;

  multiselectOptions: any[] = [];

  tabEntityMap = {
    vlan: 'Vlans',
    vno: 'Vnos',
    region: 'Regions',
    isp: 'Isps',
    product: 'Products',
    contact: 'Contacts',
    site: 'Sites',
    endcustomer: 'EndCustomers',
    device: 'Devices',
    equipmenttype: 'EquipmentTypes',
    endcustomercircuit: 'EndCustomerCircuits',
  };

  controlIDTypeMap: any = {
    vlan_id: { key: 'vlan', descriptorFields: 'description' },
    vno_id: { key: 'vno', descriptorFields: 'name' },
    region_id: { key: 'region', descriptorFields: 'name' },
    isp_id: { key: 'isp', descriptorFields: 'name' },
    product_id: { key: 'product', descriptorFields: 'name' },
    contact_id: { key: 'contact', descriptorFields: 'name' },
    site_id: { key: 'site', descriptorFields: 'name' },
    end_customer_id: { key: 'endcustomer', descriptorFields: 'name' },
    device_id: { key: 'device', descriptorFields: 'name' },
    equipment_type_id: { key: 'equipmenttype', descriptorFields: 'model' },
    end_customer_circuit_id: {
      key: 'endcustomercircuit',
      descriptorFields: 'circuit_no',
    },
  };

  controlPluralTypeMap: any = {
    // TESTING HERE!!!!
    vnos: { key: 'vno', descriptorFields: 'name' },
    equipment_types: { key: 'equipmenttype', descriptorFields: 'model' },
  };

  uploadedFile: any = null;
  uploadProgress: number = 0;

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private entityService: EntityService,
    private ref: DynamicDialogRef,
    private api: ApiService,
  ) {}

  /**
   * @function
   * @name getValueFromId
   * @description
   * Get the value from the id
   * @param id
   * @returns
   */
  getValueFromId(id: number) {
    return this.selectOptions.find((x) => x.value === id).label;
  }

  ngOnInit(): void {
    this.jsonForm = this.config.data.schema;
    this.formData = this.config.data.formdata;
    this.tab = this.config.data.tab;

    this.createForm(this.jsonForm.controls);
    this.populateFormData(this.formData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jsonForm'].firstChange) {
      this.createForm(changes['jsonForm'].currentValue.controls);
    }
  }

  ngAfterViewInit() {
    // //console.log(this.tocFileUpload.files);
  }

  createFormArray() {
    return this.fb.array([]);
  }

  createForm(controls: JsonFormControls[]) {
    // takes controls form scheme definition and creates form controls
    //console.log('controls', controls);
    for (const control of controls) {
      const validatorsToAdd = [];
      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }

      this.myForm.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd),
      );
    }
  }

  populateFormData(formData: any) {
    // fixme: this throws away data if it is not in the form
    if (formData != null) {
      for (const controlName of Object.keys(formData)) {
        if (controlName in this.controlPluralTypeMap) {
          const dataSelected = this.mapDataToSelectedOptions(
            formData[controlName],
          );

          // todo: implement a retrieval service that gets the full data
          this.myForm.controls[controlName]?.setValue(dataSelected);
        } else {
          this.myForm.controls[controlName]?.setValue(formData[controlName]);
        }
      }
    }
  }

  getSelectOptions(controlName: string, controlType: string) {
    // todo: improve but works for now - make generic for all select options, derive from schema defintions
    let options: any = [];
    if (controlType === 'multiselect') {
      for (const control of this.jsonForm.controls) {
        let dataKey = '';
        let dataMapObject = { key: '', descriptorFields: '' };
        if (controlName) {
          // //console.log('controlName', controlName);
          dataMapObject = this.controlPluralTypeMap[controlName];
          dataKey = dataMapObject?.key;
          // //console.log('dataMapObject', dataMapObject);
        }
        const dataList = this.entityService.getEntityData(dataKey);

        const optionsList = this.mapDataToOptions(dataList, dataMapObject);

        options = optionsList;
      }
    } else if (controlType === 'select') {
      for (const control of this.jsonForm.controls) {
        if (controlName.includes('_id')) {
          let dataKey = '';
          let dataMapObject = { key: '', descriptorFields: '' };
          if (controlName) {
            dataMapObject = this.controlIDTypeMap[controlName];
            dataKey = dataMapObject?.key;
          }

          const dataList = this.entityService.getEntityData(dataKey);

          const optionsList = this.mapDataToOptions(dataList, dataMapObject);

          options = optionsList;
        } else if (
          control.name.includes('port_options') ||
          control.name.includes('toc_status')
        ) {
          // fixme: improve this logic
          options = control?.options;
        }
      }
    }
    return options;
  }

  mapDataToOptions(dataList: any, dataMapObject: any): any {
    return dataList.map((data: any) => {
      return { label: data[dataMapObject.descriptorFields], value: data.id };
    });
  }

  mapDataToSelectedOptions(dataList: any): any {
    return dataList.map((data: any) => {
      return data.id;
    });
  }

  onUpload($event: any) {
    //console.log('start of onUpload');
    // // convert file to base64 encoding
    const file = $event.files[0];
    //console.log('file', file);

    this.uploadedFile = null;
    //console.log('end of onUpload');
    // return [file];
  }

  uploadFile(event$: any) {
    const file = event$.files[0];
    // //console.log('file', file);

    const formData = new FormData();

    formData.append('toc_file', file);

    this.api.postFileAPI('upload-file', formData).subscribe((response: any) => {
      //console.log('response', response);

      const data = response.body;

      // fixme: this is a hack to get the file reference into the form
      // todo: disable the controls
      // fixme: disabling the controls leaves out the value from the form submission
      this.myForm.controls['toc_file_reference']?.setValue(data.uniqueId);
      // this.myForm.controls['toc_file_reference']?.disable();

      this.myForm.controls['original_filename']?.setValue(
        data.originalFileName,
      );
      // this.myForm.controls['original_filename']?.disable();
    });

    this.uploadedFile = file;
    this.tocFileUpload.files = [];

    // return [file];
  }

  onFormSubmit() {
    //console.log('Form valid: ', this.myForm.valid);
    //console.log('Form values: ', this.myForm.value);
    //console.log('Original form values: ', this.formData);

    if (this.myForm.valid) {
      // todo: possibly introduce entityrelationship calls form here, using the relationship service

      if (this.formData != null) {
        // editing an existing entity

        this.entityService.updateEntity(
          this.tab.key,
          this.formData.id,
          this.myForm.value,
          this.formData,
        );
      } else {
        // adding a new entity
        this.entityService.addEntity(this.tab.key, this.myForm.value);
      }
      if (this.ref) {
        // closes the dialog/modal
        this.ref.close();
      }
    } else {
      // todo: show error message if form is invalid
      //console.log('Form invalid');
      throw new Error('Form invalid');
    }
  }
}
