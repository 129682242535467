import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-sales-view',
  templateUrl: './business-sales-view.component.html',
  styleUrls: ['./business-sales-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessSalesViewComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['business']);
  }
}
