import { Component, OnInit, Input } from '@angular/core';
import { PortalEndCustomer } from '../../_models/portal/interfaces';

interface CustomerField {
  field: keyof PortalEndCustomer;
  header: string;
  type: 'text' | 'date' | 'string' | 'number' | 'currency' | 'status';
}

@Component({
  selector: 'app-share-user-customer-details',
  templateUrl: './share-user-customer-details.component.html',
  styleUrls: ['./share-user-customer-details.component.scss'],
})
export class ShareUserCustomerDetailsComponent implements OnInit {
  customerFieldsToDisplay: CustomerField[] = [
    { field: 'first_name', header: 'Name', type: 'text' },
    { field: 'last_name', header: 'Surname', type: 'text' },
    { field: 'mobile_number', header: 'Mobile Number', type: 'text' },
    { field: 'email', header: 'Email', type: 'text' },
  ];

  @Input() customer: PortalEndCustomer | undefined;

  constructor() {}

  ngOnInit(): void {}
}
