import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControls } from 'src/app/_share/_models/forms/form-controls.interface';
import { BusinessSalesService } from '../../_services/business-sales.service';

@Component({
  selector: 'app-business-sales-questions',
  templateUrl: './business-sales-questions.component.html',
  styleUrls: ['./business-sales-questions.component.scss'],
})
export class BusinessSalesQuestionsComponent implements OnInit {
  submitQualifyingQuestions() {
    throw new Error('Method not implemented.');
  }

  submitted: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public bss: BusinessSalesService,
  ) {}

  questionControls: FormControls[] = [
    {
      name: 'internetUsage',
      label: 'Are you currently making use of internet at your business?',
      value: '',
      valueOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'internetType',
      label: 'If yes, please specify what type:',
      value: '',
      valueOptions: [
        { label: 'Fibre', value: 'fibre' },
        { label: 'Microwave', value: 'microwave' },
        { label: 'Fixed LTE/5G', value: 'fixedlte-5g' },
        { label: 'Mobile', value: 'mobile' },
        { label: 'Satellite', value: 'satellite' },
        { label: 'ADSL', value: 'adsl' },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'provider',
      label: 'Who is your current provider?',
      value: '',
      required: true,
      type: 'text',
    },
    {
      name: 'contractType',
      label: 'Are you currently in contract or on a month to month?',
      value: '',
      valueOptions: [
        { label: 'In contract', value: 'contract' },
        { label: 'Month to month', value: 'month-to-month' },
      ],
      required: true,
      type: 'radio',
    },

    {
      name: 'contractPeriod',
      label: 'If in contract, what is the period remaining on your contract?',
      value: '',
      required: true,
      type: 'text',
    },
    {
      name: 'connectionSpeeds',
      label: 'What are your current connection speeds?',
      value: '',
      required: true,
      type: 'text',
    },

    {
      name: 'usersDaily',
      label: 'How many users do you have on your internet daily?',
      value: '',
      required: true,
      type: 'text',
    },
    {
      name: 'monthlyCost',
      label: 'What are you currently paying per month on your internet?',
      value: '',
      required: true,
      type: 'text',
    },

    {
      name: 'usage',
      label: 'What are you mainly using your internet for?',
      value: '',
      required: true,
      type: 'checkbox',
      valueOptions: [
        { label: 'Email Communication', value: 'email' },
        {
          label: 'Video Conferencing (e.g., Zoom, Teams)',
          value: 'video_conferencing',
        },
        { label: 'Web Browsing', value: 'web_browsing' },
        {
          label: 'Cloud Services (e.g., Google Drive, Dropbox)',
          value: 'cloud_services',
        },
        { label: 'E-commerce/Online Transactions', value: 'e_commerce' },
        { label: 'Social Media Management', value: 'social_media_management' },
        {
          label: 'Streaming Media (e.g., YouTube, Netflix)',
          value: 'streaming_media',
        },
        {
          label: 'Remote Work Access (e.g., VPN, Remote Desktop)',
          value: 'remote_work_access',
        },
        { label: 'File Downloads/Uploads', value: 'file_transfers' },
        {
          label: 'Online Marketing and Advertising',
          value: 'online_marketing',
        },
        {
          label: 'Customer Support Tools (e.g., Chat, CRM)',
          value: 'customer_support',
        },
        {
          label: 'IoT Devices (e.g., Smart Cameras, Sensors)',
          value: 'iot_devices',
        },
      ],
    },
    {
      name: 'failover',
      label: 'Do you have a failover?',
      value: '',
      valueOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'infrastructureAwareness',
      label: 'Are you aware of the new infrastructure in your area?',
      value: '',
      valueOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'costVsSpeedVsReliability',
      label: 'What is more important for you, cost or bandwidth/speeds?',
      value: '',
      required: true,
      valueOptions: [
        { label: 'Cost', value: 'yes' },
        { label: 'Bandwidth and Speed', value: 'bandwidth-speed' },
        {
          label: 'Reliability and Robustness',
          value: 'reliability-robustness',
        },
      ],
      type: 'radio',
    },
    {
      name: 'voipOrTelephony',
      label: 'Are you making use of an internet telephone service or VoIP?',
      value: '',
      valueOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'fibreQuotation',
      label: 'Would you be interested in a quotation for Fibre?',
      value: '',
      valueOptions: [
        { label: 'Yes, interested', value: true },
        { label: 'No, not interested', value: false },
      ],
      required: true,
      type: 'radio',
    },
    {
      name: 'reason',
      label: 'If no, what is the reason?',
      value: '',
      required: true,
      type: 'text',
    },
    {
      name: 'contactInFuture',
      label: 'Can we contact you in the future?',
      value: '',
      valueOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      required: true,
      type: 'radio',
    },

    // {
    //   question: 'Are you currently making use of internet at your business?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'If yes, please specify what type:',
    //   type: 'radio',
    //   options: ['Fibre', 'Microwave', 'LTE', 'Mobile', 'ADSL'],
    // },
    // {
    //   question: 'Who is your current provider?',
    //   type: 'text',
    // },
    // {
    //   question: 'Are you currently in contract or on a month to month?',
    //   type: 'radio',
    //   options: ['In contract', 'Month to month'],
    // },
    // {
    //   question:
    //     'If in contract, what is the period remaining on your contract?',
    //   type: 'text',
    // },
    // {
    //   question: 'What are your current connection speeds?',
    //   type: 'text',
    // },
    // {
    //   question: 'How many users do you have on your internet daily?',
    //   type: 'text',
    // },
    // {
    //   question: 'What are you currently paying per month on your internet?',
    //   type: 'text',
    // },
    // {
    //   question: 'What are you mainly using your internet for?',
    //   type: 'text',
    // },
    // {
    //   question: 'Do you have a failover?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'Are you aware of the new infrastructure in your area?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'What is more important for you, cost or bandwidth/speeds?',
    //   type: 'text',
    // },
    // {
    //   question: 'Would you be interested in a quotation for Fibre?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'If no, what is the reason?',
    //   type: 'text',
    // },
    // {
    //   question: 'Can we contact you in the future?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'Are you making use of an internet telephone service or VoIP?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
    // {
    //   question: 'Would you be interested in a quotation for Fibre?',
    //   type: 'radio',
    //   options: ['Yes', 'No'],
    // },
  ];

  ngOnInit(): void {
    console.log('BusinessSalesQuestionsComponent init');
    this.bss.activeStepIndex = 0;
  }

  findFormControlName(controlName: string) {
    return this.questionControls.find(
      (control) => control.name === controlName,
    );
  }
}
