<div class="flex flex-column h-full min-h-full max-h-full">
  <div
    class="px-3 sm:px-7 flex flex-wrap align-items-stretch justify-content-between relative lg:border-bottom-1 surface-border"
    style="height: 64px; background-color: #0e2c45"
  >
    <div class="lg:hidden flex"></div>
    <div
      id="nav-2"
      class="hidden lg:flex absolute lg:static left-0 top-100 z-1 shadow-2 lg:shadow-none w-full lg:w-auto py-3 lg:py-0"
      style="background-color: #0e2c45"
    >
      <ul class="list-none p-0 m-0 flex flex-column lg:flex-row"></ul>
    </div>

    <div class="flex align-items-center justify-content-center py-3">
      <img
        src="https://stemconnect.net/za/wp-content/uploads/sites/4/images/logo.png"
        alt="Image"
        height="32"
      />
    </div>
    <div class="lg:flex w-full lg:w-auto hidden py-3 lg:py-0">
      <ul class="list-none p-0 m-0 flex w-full" style="min-height: 30px"></ul>
    </div>
  </div>

  <!-- screen body -->

  <!-- sub-nav -->
  <app-sub-nav
    rootPath=""
    *ngIf="authenticationService.userValue"
  ></app-sub-nav>

  <!-- rest of body -->
  <div
    class="flex-1 overflow-y-auto surface-section p-3 justify-content-start w-full h-full"
  >
    <router-outlet></router-outlet>
  </div>

  <div
    class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
  >
    <span class="text-600">© STEM Connect {{ currentYear }}</span>
  </div>
</div>
