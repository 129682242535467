import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/_share/_services/notification.service';

@Component({
  selector: 'app-signup-success-dialog',
  templateUrl: './signup-success-dialog.component.html',
  styleUrls: ['./signup-success-dialog.component.scss'],
})
export class SignupSuccessDialogComponent implements OnInit {
  checkedCredentials: boolean = false;
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    console.log('Config: ', this.config);
  }

  dismissDialog() {
    if (this.checkedCredentials) {
      this.ref.close(this.checkedCredentials);
    }
  }
  copyToClipboard() {
    if (this.checkedCredentials) {
      const username = this.config.data.accountdata?.solidcode;
      const password = this.config.data.accountdata?.customer?.password;

      this.clipboard.copy('username: ' + username + '\npassword: ' + password);

      this.notificationService.successfulCredentialCopy();
    }
  }
}
