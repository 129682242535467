<div class="flex flex-column">
  <ng-container *ngIf="userService.currentUser$ | async as currentUser">
    <div class="flex-1">
      <a
        pButton
        class="p-button p-button-success p-button-outlined mr-2"
        label="Go to Portal"
        [href]="
          getPortalUrl(
            currentUser.portal_tenant_id,
            currentUser.portal_tenants || []
          )
        "
        target="_blank"
        style="text-decoration: none"
      ></a>
    </div>

    <div class="flex-1 justify-content-end">
      <h3 class="text-600 text-left">
        {{
          getCurrentPortalTenantName(
            currentUser.portal_tenant_id,
            currentUser.portal_tenants || []
          )
        }}
      </h3>
      <button
        *ngIf="(currentUser.portal_tenants || []).length > 1"
        pButton
        class="p-button p-button-success p-button-outlined"
        label="Change Portal"
        (click)="portalTenantMenu.toggle($event)"
      ></button>

      <p-menu
        #portalTenantMenu
        [model]="
          this.convertTenantsToMenuItems(currentUser.portal_tenants || [])
        "
        [popup]="true"
        [style]="{ width: '12rem' }"
      ></p-menu>
    </div>
  </ng-container>
</div>
