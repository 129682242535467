import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  subscriptions: any[] = [];
  subscriptions$: Subject<any[]> = new Subject<any[]>();
  isLoading: boolean = true;
  isError: boolean = false;

  columns: any[] = [
    { field: 'subscription_account_reference', header: 'Account Reference' },
    { field: 'date_activated', header: 'Start Date' },
    { field: 'date_billing_next', header: 'Next Billing Date' },
    // { field: 'billing_cycle_type', header: 'Billing Cycle' },
    { field: 'subscription_reference', header: 'Subscription Reference' },
    { field: 'date_initialised', header: 'Date Initialised' },
    { field: 'date_activated', header: 'Date Activated' },
    { field: 'subscription_status', header: 'Service Status' },
    { field: 'account_status', header: 'Account Status' },
  ];

  constructor(
    private portalSubscriptionsService: PortalSubscriptionService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.retrievePortalSubscriptions();
  }

  retrievePortalSubscriptions() {
    this.isLoading = true;
    this.portalSubscriptionsService.getPortalSubscriptions().subscribe(
      (res: any) => {
        this.subscriptions = res;
        this.subscriptions$.next(this.subscriptions);
        this.isLoading = false;
      },
      (error) => {
        console.log('Error ', error);
        this.isLoading = false;
        this.isError = true;
      },
    );
  }

  showSubscriptionDetailsDialog(subscription: any) {
    const ref = this.dialogService.open(SubscriptionDetailsComponent, {
      data: {
        subscription: subscription,
      },
      width: '70%',
    });
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  clear(table: Table) {
    table.clear();
  }

  getAccountSeverity(status: string): string {
    switch (status) {
      case 'paid':
        return 'success';
      case 'unpaid':
        return 'danger';
      case 'overdue':
        return 'warning';
      default:
        return 'info';
    }
  }

  getSubscriptionSeverity(status: string): string {
    switch (status) {
      case 'active':
        return 'success';
      case 'suspended_payment':
        return 'danger';
      case 'cancelled':
        return 'warning';
      default:
        return 'info';
    }
  }
}
