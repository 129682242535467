import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeadStatsService {
  leadStatsQuery$: any;

  leadStats: any[] = [];
  leadStats$: Subject<any> = new Subject<any>();

  actionStats: any[] = [];
  actionStats$: Subject<any> = new Subject<any>();

  isStatsLoading$: Subject<boolean> = new Subject<boolean>();

  constructor(public api: ApiService) {}

  getStatistics() {
    this.isStatsLoading$.next(true);
    this.getStatisticsLeads();
    this.getStatisticsActions();
  }

  getStatisticsLeads() {
    this.api.getAPI<any>('crm/statistics/leads/get').subscribe((res) => {
      this.leadStats = res.body;
      this.leadStats$.next(this.leadStats);
      this.isStatsLoading$.next(false);
    });
  }

  getStatisticsActions() {
    this.api.getAPI<any>('crm/statistics/actions/get').subscribe((res) => {
      this.actionStats = res.body;
      console.log('action - stats', this.actionStats);
      this.actionStats$.next(this.actionStats);
      this.isStatsLoading$.next(false);
    });
  }
}
