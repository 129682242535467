<div *ngIf="rowData">
  <div [ngSwitch]="selectedTab">
    <div *ngSwitchCase="'Devices'">
      <h4>
        <strong class="pr-3">Status:</strong
        >{{ rowData.equipment_type.manufacturer }}
      </h4>
      <h4>
        <strong class="pr-3">Manufacturer:</strong
        >{{ rowData.equipment_type.manufacturer }}
      </h4>
      <h4>
        <strong class="pr-3">Model:</strong>{{ rowData.equipment_type.model }}
      </h4>
      <h4>
        <strong class="pr-3">Wifi Capable:</strong
        >{{ rowData.equipment_type.wifi_capable }}
      </h4>
      <div>
        <div class="card h-20rem">
          <!-- <h4>Temperature °C:</h4> -->
          <p-chart
            type="line"
            [data]="trafficData"
            [options]="basicOptions"
          ></p-chart>
        </div>
        <div class="card">
          <!-- <h4>Ethernet Status:</h4> -->
          <p-chart
            type="line"
            [data]="signalData"
            [options]="basicOptions"
          ></p-chart>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'End Customer Circuits'">
      <h4><strong class="pr-3">Wifi SSID:</strong>{{ rowData.wifi_ssid }}</h4>
      <h4>
        <strong class="pr-3">Mac Address:</strong
        >{{ circuit_device.mac_address }}
      </h4>
      <h4>
        <strong class="pr-3">Manufacturer:</strong>
        {{ equipmentTypes.manufacturer }}
      </h4>
      <h4>
        <strong class="pr-3">Model:</strong>
        {{ equipmentTypes.model }}
      </h4>
      <div class="card">
        <h4>Freq per band:</h4>
        <p-chart
          type="line"
          [data]="trafficData"
          [options]="basicOptions"
        ></p-chart>
      </div>
      <div class="card">
        <h4>Ethernet Status:</h4>
        <p-chart
          type="line"
          [data]="signalData"
          [options]="basicOptions"
        ></p-chart>
      </div>
    </div>
    <div *ngSwitchCase="'ISPs'">
      <h4><strong class="pr-3">Selected ISP:</strong>{{ rowData.name }}</h4>
      <!-- I'm not sure about the contact and account details -->
      <h4><strong class="pr-3">Contact:</strong></h4>
      <p-divider></p-divider>
      <h2>Account Information</h2>
      <p-divider></p-divider>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</div>
