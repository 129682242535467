import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-sales-script',
  templateUrl: './business-sales-script.component.html',
  styleUrls: ['./business-sales-script.component.scss'],
})
export class BusinessSalesScriptComponent implements OnInit {
  questions = [
    {
      headerText: 'What is fibre internet?',
      bodyText: '',
      body: [
        {
          question:
            'Can you explain what fibre internet is and how it differs from other types of internet connections?',
          answer: `
            <p>Fiber internet uses fibre-optic cables to transmit data as light signals, which allows for much higher speeds and more reliable connections compared to DSL or cable. Fiber is less affected by interference and supports higher bandwidth, making it a superior choice for high-demand environments.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Speed and Bandwidth',
      bodyText: '',
      body: [
        {
          question: 'What internet speeds do you offer with your fibre plans?',
          answer: `
            <p>Our fibre plans offer:</p>
            <ul>
              <li>20 Mbps for 1–10 users</li>
              <li>50 Mbps for 15–25 users</li>
              <li>150 Mbps for 25–50 users</li>
              <li>250 Mbps for 50+ users</li>
            </ul>
            <p>These speeds cater to varying business needs and software applications.</p>
            <p>Our business fibre offers a <strong>1:3 contention ratio</strong> as opposed to a broadband contention ratio of <strong>1:10</strong>.</p>
          `,
        },
        {
          question:
            'Is the speed symmetrical (equal upload and download speeds)?',
          answer: `
            <p>Yes, our fibre plans offer <strong>symmetrical speeds</strong>, providing equal upload and download speeds for efficient data handling.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Reliability and Uptime',
      bodyText: '',
      body: [
        {
          question: 'How reliable is your fibre internet service?',
          answer: `
            <p>Fiber internet is known for its reliability, with our service offering a <strong>99.5% uptime guarantee</strong> to minimize business disruptions.</p>
          `,
        },
        {
          question:
            'Do you offer any Service Level Agreements (SLAs) for uptime?',
          answer: `
            <p>Yes, we offer <strong>Standard Business SLAs</strong> that include performance metrics such as uptime and response times for resolving any issues.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Installation and Setup',
      bodyText: '',
      body: [
        {
          question:
            'What is involved in the installation process, and how long does it take?',
          answer: `
            <p>Installation involves laying fibre-optic cables to your premises and setting up equipment. The process typically takes around 2 weeks, depending on location and infrastructure. Landlord consent forms will be provided as part of a document pack.</p>
          `,
        },
        {
          question: 'Are there any installation fees?',
          answer: `
            <p>Installation is free of charge. If any additional construction is required, we will provide a quote accordingly.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Cost and Contracts',
      bodyText: '',
      body: [
        {
          question:
            'What are the costs associated with your fibre internet plans?',
          answer: `
            <p>Our plans are priced as follows (All prices Excluding VAT), with the first 3 months free:</p>
            <ul>
              <li>20 Mbps – R1499</li>
              <li>50 Mbps – R1999</li>
              <li>150 Mbps – R2499</li>
              <li>250 Mbps – R2999</li>
            </ul>
            <p>Contractual periods commence after the initial 3 months free period.</p>
          `,
        },
        {
          question: 'Do you require long-term contracts?',
          answer: `
            <p>We offer <strong>24-month contracts</strong>, but if preferred, a <strong>12-month contract</strong> can be arranged at the same price.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Scalability',
      bodyText: '',
      body: [
        {
          question: 'Can your service scale with my business as it grows?',
          answer: `
            <p>Yes, our fibre internet plans are designed to be scalable, allowing you to easily upgrade your speed and bandwidth as your business expands. Upgrades will be in accordance with our standard pricing.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Customer Support',
      bodyText: '',
      body: [
        {
          question: 'What kind of customer support do you offer?',
          answer: `
            <p>We provide <strong>24/7 customer support</strong> with dedicated business support teams. Support requests can be logged via phone or electronically.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Security',
      bodyText: '',
      body: [
        {
          question: 'How secure is your fibre internet service?',
          answer: `
            <p>Fiber internet is naturally more secure than other connections due to the technology used. We also offer additional security features, such as firewalls, for enhanced protection.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Compatibility with Existing Infrastructure',
      bodyText: '',
      body: [
        {
          question:
            'Will your fibre service be compatible with our existing network infrastructure?',
          answer: `
            <p>Yes, our technical team will check compatibility with your current network setup. This includes providing a new router and ONT, which is free of charge and covered under warranty.</p>
          `,
        },
      ],
    },
    {
      headerText: 'Additional Services',
      bodyText: '',
      body: [
        {
          question: 'What additional services do you offer?',
          answer: `
            <p>We include <strong>2 SIP trunks for voice</strong> in this connectivity. This means we offer 2 digital voice lines for telephone use, with separate hardware purchases required. A representative will contact you for more details on the hardware.</p>
          `,
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
