import { Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { ApiService } from '../../../../_core/_services/api.service';
import { Device } from 'src/app/_share/_models/entities/schema';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  devicesQuery$: any;

  devices: any[] = [];
  devices$: Subject<any[]> = new Subject<any[]>();

  constructor(public api: ApiService) {}

  getDevices(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }

    return this.api.getAPI<Device[]>('device', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getDeviceById(id: any) {
    return this.api.getAPI<any>(`device/${id}`);
  }

  getDeviceBySerialNo(serial_no: any) {
    console.log('Serial No: ', serial_no);
    //remove spaces form the serial_number
    serial_no = serial_no.replace(/\s/g, '');
    return this.api.getAPI<any>(`devices/get/${serial_no}`);
  }

  addDevice(value: any) {
    const new_value = {
      ...value,
    };
    console.log('new_value', new_value);

    this.devicesQuery$ = this.api.postAPI<any>('device', new_value);
    return this.devicesQuery$;
  }

  updateDevice(id: any, value: any) {
    const new_value = {
      ...value,
    };
    this.devicesQuery$ = this.api.putAPI<any>('device', id, new_value);

    return this.devicesQuery$;
  }

  deleteDevice(id: any) {
    this.api.deleteAPI<any>('device', id).subscribe((res) => {});
  }
}
