import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BusinessSalesService } from '../../_services/business-sales.service';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_share/_services/notification.service';

@Component({
  selector: 'app-business-sales',
  templateUrl: './business-sales.component.html',
  styleUrls: ['./business-sales.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessSalesComponent implements OnInit {
  steps: MenuItem[] = [];
  forms: any;
  showFaq: boolean = false;

  @ViewChild('formContainer', { static: true }) formContainer!: ElementRef;

  constructor(
    private router: Router,
    public bss: BusinessSalesService,
    private el: ElementRef,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.steps = [
      {
        label: 'Questions',
        routerLink: 'questions',
      },
      { label: 'Business Information', routerLink: 'info' },
      {
        label: 'Summary',
        routerLink: 'summary',
      },
    ];

    this.forms = {
      questions: this.bss.qualifyingForm,
      info: this.bss.businessDetailsForm,
    };

    this.bss.getBusinessSalesProducts().subscribe((res) => {
      this.bss.businessSalesProducts = res;
      console.log('businessSalesProducts', this.bss.businessSalesProducts);
    });

    this.bss.qualifyingForm
      .get('fibreQuotation')
      ?.valueChanges.subscribe((value) => {
        if (value === false) {
          this.bss.qualifyingForm
            .get('ifFibreQuotation.contactInFuture')
            ?.setValidators([Validators.required]);
        } else {
          this.bss.qualifyingForm
            .get('ifFibreQuotation.contactInFuture')
            ?.clearValidators();
        }
        this.bss.qualifyingForm
          .get('ifFibreQuotation.contactInFuture')
          ?.updateValueAndValidity();
      });

    this.bss.qualifyingForm
      .get('ifFibreQuotation.contactInFuture')
      ?.valueChanges.subscribe((value) => {
        if (value === true) {
          this.bss.qualifyingForm
            .get(
              'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture',
            )
            ?.setValidators([Validators.required, Validators.email]);
          this.bss.qualifyingForm
            .get(
              'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture',
            )
            ?.setValidators([Validators.required]);
        } else {
          this.bss.qualifyingForm
            .get(
              'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture',
            )
            ?.clearValidators();
          this.bss.qualifyingForm
            .get(
              'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture',
            )
            ?.clearValidators();
        }
        this.bss.qualifyingForm
          .get('ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture')
          ?.updateValueAndValidity();
        this.bss.qualifyingForm
          .get(
            'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture',
          )
          ?.updateValueAndValidity();
      });
  }

  checkFormValidity(routerLink: string) {
    if (this.forms[routerLink].valid) {
      return true;
    } else {
      this.forms[routerLink].markAllAsTouched();
      for (const key in this.forms[routerLink].controls) {
        this.forms[routerLink].controls[key].markAsDirty();
      }
      console.log('form errors', this.forms[routerLink].errors);
      this.scrollToFirstInvalidControl();
      this.notificationService.invalidFields();
      return false;
    }
  }

  nextPage() {
    if (this.bss.activeStepIndex < this.steps.length - 1) {
      if (
        !this.checkFormValidity(this.steps[this.bss.activeStepIndex].routerLink)
      ) {
        return;
      }

      if (
        this.bss.activeStepIndex === 0 &&
        this.bss.qualifyingForm.valid &&
        this.bss.qualifyingForm.get('ifFibreQuotation.contactInFuture')?.value
      ) {
        console.log(
          'contactInFuture',
          this.bss.qualifyingForm.get('ifFibreQuotation.contactInFuture')
            ?.value,
        );
        this.bss.qualifyingForm
          .get('ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture')
          ?.setValidators([Validators.required, Validators.email]);
        this.bss.qualifyingForm
          .get(
            'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture',
          )
          ?.setValidators([Validators.required]);
        return;
      }

      this.router.navigate([
        'business',
        this.steps[this.bss.activeStepIndex + 1].routerLink,
      ]);
      this.bss.activeStepIndex++;
    }
  }

  prevPage() {
    if (this.bss.activeStepIndex > 0) {
      this.router.navigate([
        'business',
        this.steps[this.bss.activeStepIndex - 1].routerLink,
      ]);
      this.bss.activeStepIndex--;
    }
  }

  hasPrev() {
    if (this.bss.activeStepIndex > 0) {
      return true;
    } else {
      return false;
    }
  }

  hasNext() {
    return true;
    if (this.bss.activeStepIndex < this.steps.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  canSubmit() {
    if (
      this.bss.activeStepIndex === 2 ||
      (this.bss.qualifyingForm.valid &&
        this.bss.qualifyingForm.get('fibreQuotation')?.value === false)
    ) {
      return true;
    } else {
      return false;
    }
  }

  submitBusinessSale() {
    let formIndex = 0;
    if (this.bss.activeStepIndex === 2) {
      formIndex = 1;
    } else {
      formIndex = this.bss.activeStepIndex;
    }
    if (!this.checkFormValidity(this.steps[formIndex].routerLink)) {
      return;
    }

    const newData = {
      internet_usage:
        this.bss.qualifyingForm.get('internetUsage')?.value || false,
      internet_type:
        this.bss.qualifyingForm.get('ifInternetUsage.internetType')?.value ||
        '',
      provider:
        this.bss.qualifyingForm.get('ifInternetUsage.provider')?.value || '',
      contract_type:
        this.bss.qualifyingForm.get('ifInternetUsage.contractType')?.value ||
        '',
      contract_period_months:
        this.bss.qualifyingForm.get(
          'ifInternetUsage.ifContractType.contractPeriod',
        )?.value || 0,
      users_daily:
        this.bss.qualifyingForm.get('ifInternetUsage.usersDaily')?.value || 0,
      monthly_cost:
        this.bss.qualifyingForm.get('ifInternetUsage.monthlyCost')?.value || 0,
      usage: (
        this.bss.qualifyingForm.get('ifInternetUsage.usage')?.value || []
      ).toString(),
      failover:
        this.bss.qualifyingForm.get('ifInternetUsage.failover')?.value || false,
      infrastructure_awareness:
        this.bss.qualifyingForm.get('ifInternetUsage.infrastructureAwareness')
          ?.value || false,
      cost_vs_speed_vs_reliability:
        this.bss.qualifyingForm.get('ifInternetUsage.costVsSpeedVsReliability')
          ?.value || '',
      voip_or_telephony:
        this.bss.qualifyingForm.get('voipOrTelephony')?.value || '',
      fibre_quotation:
        this.bss.qualifyingForm.get('fibreQuotation')?.value || false,
      reason:
        this.bss.qualifyingForm.get('ifFibreQuotation.reason')?.value || '',
      contact_in_future:
        this.bss.qualifyingForm.get('ifFibreQuotation.contactInFuture')
          ?.value || false,
      email_address_contact_in_future:
        this.bss.qualifyingForm.get(
          'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture',
        )?.value || '',
      contact_number_contact_in_future:
        this.bss.qualifyingForm.get(
          'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture',
        )?.value || '',
      notes_contact_in_future:
        this.bss.qualifyingForm.get(
          'ifFibreQuotation.ifContactInFuture.notesContactInFuture',
        )?.value || '',

      business_name:
        this.bss.businessDetailsForm.get('businessName')?.value || '',
      business_telephone:
        this.bss.businessDetailsForm.get('businessTelephone')?.value || '',
      business_email:
        this.bss.businessDetailsForm.get('businessEmail')?.value || '',

      business_details_address_line1:
        this.bss.businessDetailsForm.get('businessDetails.addressLine1')
          ?.value || '',
      business_details_address_line2: this.bss.businessDetailsForm.get(
        'businessDetails.addressLine2',
      )?.value,
      business_details_suburb:
        this.bss.businessDetailsForm.get('businessDetails.suburb')?.value || '',
      business_details_city:
        this.bss.businessDetailsForm.get('businessDetails.city')?.value || '',
      business_details_province:
        this.bss.businessDetailsForm.get('businessDetails.province')?.value ||
        '',
      business_details_postal_code:
        this.bss.businessDetailsForm.get('businessDetails.postalCode')?.value ||
        '',

      primary_contact_title:
        this.bss.businessDetailsForm.get('primaryContact.title')?.value || '',
      primary_contact_job_title:
        this.bss.businessDetailsForm.get('primaryContact.jobTitle')?.value ||
        '',
      primary_contact_first_name:
        this.bss.businessDetailsForm.get('primaryContact.firstName')?.value ||
        '',
      primary_contact_last_name:
        this.bss.businessDetailsForm.get('primaryContact.lastName')?.value ||
        '',
      primary_contact_telephone:
        this.bss.businessDetailsForm.get('primaryContact.telephone')?.value ||
        '',
      primary_contact_email:
        this.bss.businessDetailsForm.get('primaryContact.email')?.value || '',
      product:
        this.bss.businessDetailsForm.get('product')?.value?.product_code || '',
    };

    console.log('newData', newData);
    this.bss.loading = true;
    this.bss.loading$.next(true);
    this.bss.createNewBusinessSale(newData).subscribe(
      (res) => {
        this.bss.loading = false;
        this.bss.loading$.next(false);
        this.bss.submitted = true;
        this.bss.submitted$.next(true);
        console.log('res', res);

        if (formIndex !== 1) {
          this.resetAndNavigate();
        }

        this.notificationService.successfulQuotation();
      },
      (err) => {
        this.bss.submitted = false;
        this.bss.submitted$.next(false);
        this.bss.loading = false;
        this.bss.loading$.next(false);
        console.log('err', err);

        this.notificationService.failedQuotation();
      },
    );
  }

  resetAndNavigate() {
    this.bss.qualifyingForm.reset();
    this.bss.businessDetailsForm.reset();
    this.router.navigate(['business', 'questions']);

    this.bss.activeStepIndex = 0;
    this.bss.submitted = false;
    this.bss.submitted$.next(false);

    this.bss.loading = false;
    this.bss.loading$.next(false);
  }

  private scrollToFirstInvalidControl() {
    // Find the first invalid control
    const firstInvalidControl = this.findFirstInvalidControl(
      this.forms[this.steps[this.bss.activeStepIndex].routerLink],
    );

    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      firstInvalidControl.focus();
    }
  }

  private findFirstInvalidControl(
    formGroup: FormGroup | FormArray,
  ): HTMLElement | null {
    for (const key of Object.keys(formGroup.controls)) {
      const control = formGroup.get(key);

      if (control instanceof FormGroup || control instanceof FormArray) {
        // Recursively check nested form groups/arrays
        const nestedInvalidControl = this.findFirstInvalidControl(control);
        if (nestedInvalidControl) {
          return nestedInvalidControl;
        }
      } else if (control && control.invalid && control.touched) {
        // Get the DOM element corresponding to the form control
        const invalidControlElement = this.el.nativeElement.querySelector(
          `[formControlName="${key}"], [formArrayName="${key}"]`,
        );
        if (invalidControlElement) {
          return invalidControlElement;
        }
      }
    }

    return null; // No invalid control found
  }

  showFaqPanel() {
    this.showFaq = !this.showFaq;
  }
}
