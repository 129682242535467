import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
} from '@angular/common/http';
import { JwtInterceptor } from './_core/_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_core/_interceptors/error.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { PortalModule } from './features/portal/portal.module';
import { PrimeModule } from './prime.module';
import { FormsModule } from '@angular/forms';

import { MainModule } from './features/main-application/main.module';
import { CrmModule } from './features/crm/crm.module';
import { BusinessSalesModule } from './features/business-sales/business-sales.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientJsonpModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,

    // custom modules
    MainModule,
    PortalModule,
    CrmModule,
    BusinessSalesModule,

    PrimeModule,
  ],
  exports: [],
  providers: [
    MessageService,
    ConfirmationService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
