<!-- 


 -->

<p-table
  #transactionsTable
  [value]="(transactions$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-transaction>
    <tr [pSelectableRow]="transaction" [pContextMenuRow]="transaction">
      <td *ngFor="let col of columns">
        <div class="flex align-items-center">
          {{ getColumnValueFromFieldPath(col.field, transaction) }}
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p>
          There are no records to show. You might be disconnected or offline.
        </p>
      </td>
    </tr>
  </ng-template>
</p-table>
