<div class="w-full m-0 p-0 surface-border">
  <form
    [formGroup]="this.dynamicForm"
    #localForm="ngForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="grid" style="display: flex; flex-direction: column">
      <div class="col-12 lg:col-12">
        <div class="flex justify-content-center align-items-center">
          <h1>Create Product</h1>
        </div>
        <!-- Name -->
        <div class="grid formgrid p-fluid">
          <p-tabView class="field mb-4 mt-4 col-12 md:col-12">
            <p-tabPanel header="Product">
              <div class="grid formgrid p-fluid pt-4">
                <div class="field mb-4 col-12 md:col-12">
                  <input
                    class="w-6"
                    type="text"
                    id="name"
                    pInputText
                    placeholder="Product Name"
                    formControlName="name"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      (localForm.submitted &&
                        dynamicForm.get('name')?.invalid) ||
                      dynamicForm.get('name')?.dirty
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Product Name</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-12">
                  <textarea
                    id="description"
                    pInputTextarea
                    [rows]="5"
                    placeholder="Description"
                    formControlName="description"
                  ></textarea>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('description')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('description')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>description</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6 row-4">
                  <input
                    class="w-full"
                    type="number"
                    id="speed_down_mbps"
                    placeholder="Speed Down mbps"
                    pInputText
                    formControlName="speed_down_mbps"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('speed_down_mbps')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('speed_down_mbps')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Network Speed</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="number"
                    id="speed_up_mbps"
                    pInputText
                    placeholder="Speed Up mbps"
                    formControlName="speed_up_mbps"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('speed_up_mbps')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('speed_up_mbps')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Network Speed</strong> is required</small
                    >
                  </div>
                </div>

                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="text"
                    id="currency"
                    pInputText
                    placeholder="Currency"
                    formControlName="currency"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('currency')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('currency')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Currency</strong> is required</small
                    >
                  </div>
                </div>

                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="text"
                    id="duration_days"
                    pInputText
                    placeholder="Duration Days"
                    formControlName="duration_days"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('duration_days')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('duration_days')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Duration</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="text"
                    id="onceoff_description"
                    pInputText
                    placeholder="Onceoff Description"
                    formControlName="onceoff_description"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('onceoff_description')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('onceoff_description')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Onceoff description</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <p-dropdown
                    [options]="product_types"
                    [(ngModel)]="selectedProduct"
                    [showClear]="true"
                    placeholder="Select a Product Type"
                    formControlName="product_type"
                  ></p-dropdown>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('product_type')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('product_type')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Product type</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="text"
                    id="recurring_description"
                    pInputText
                    placeholder="Recurring Description"
                    formControlName="recurring_description"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('recurring_description')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('recurring_description')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Recurring description</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <input
                    class="w-full"
                    type="text"
                    id="renewal_type"
                    pInputText
                    placeholder="Renewal Type"
                    formControlName="renewal_type"
                  />
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('renewal_type')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('renewal_type')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Renewal</strong> is required</small
                    >
                  </div>
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel header="Pricing">
              <div class="grid formgrid p-fluid pt-4">
                <div class="field mb-4 col-12 md:col-6">
                  <label class="p-2" for="recurring_price_excl"
                    >Recurring Price Excluded</label
                  >
                  <p-inputNumber
                    id="recurring_price_excl"
                    formControlName="recurring_price_excl"
                  ></p-inputNumber>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('recurring_price_excl')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('recurring_price_excl')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Price</strong> is required</small
                    >
                  </div>
                </div>

                <div class="field mb-4 col-12 md:col-6">
                  <label class="p-2" for="recurring_price_incl"
                    >Recurring Price Included</label
                  >
                  <p-inputNumber
                    id="recurring_price_incl"
                    formControlName="recurring_price_incl"
                  ></p-inputNumber>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('recurring_price_incl')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('recurring_price_incl')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Price</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label class="p-2" for="onceoff_price_excl"
                    >Onceoff Price Excluded</label
                  >
                  <p-inputNumber
                    id="onceoff_price_excl"
                    formControlName="onceoff_price_excl"
                  ></p-inputNumber>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('onceoff_price_excl')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('onceoff_price_excl')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Price</strong> is required</small
                    >
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label class="p-2" for="onceoff_price_incl"
                    >Onceoff Price Included</label
                  >
                  <p-inputNumber
                    id="onceoff_price_incl"
                    formControlName="onceoff_price_incl"
                  ></p-inputNumber>
                  <div
                    class="px-1 py-1"
                    *ngIf="
                      dynamicForm.get('onceoff_price_incl')?.invalid &&
                      (localForm.submitted ||
                        dynamicForm.get('onceoff_price_incl')?.dirty)
                    "
                  >
                    <small id="first_name-help" class="p-error block"
                      ><strong>Price</strong> is required</small
                    >
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
      <div
        class="flex align-items-end justify-content-end px-1 py-1 mr-4"
        *ngIf="
          dynamicForm.get('onceoff_price_incl')?.invalid &&
          (localForm.submitted || dynamicForm.get('onceoff_price_incl')?.dirty)
        "
      >
        <small id="first_name-help" class="p-error block"
          >Please check that all fields have been filled</small
        >
      </div>
      <div
        class="flex align-items-end justify-content-end bottom-0 left-0 m-0 w-full p-4 bg-gray-200"
      >
        <button
          pButton
          pRipple
          class="mr-3 py-3 px-6 p-button-danger"
          (click)="onCancel()"
        >
          Cancel
        </button>
        <button pButton type="submit" class="py-3 px-6">Create</button>
      </div>
    </div>
  </form>
</div>
