import { Injectable } from '@angular/core';
import { ApiService } from '../../../../_core/_services/api.service';
import { Region } from '../../../../_share/_models/entities/schema';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  regionsQuery$: any;

  regions: Region[];
  regions$: Subject<Region[]> = new Subject<Region[]>();

  constructor(public api: ApiService) {
    this.regions = [];
  }

  getRegions(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Region>('region', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addRegion(value: any) {
    const new_value = { ...value };
    for (let i = 0; i < new_value.vnos.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.vnos[i] = { id: new_value.vnos[i] };
    }
    console.log();

    this.regionsQuery$ = this.api.postAPI<Region>('region', new_value);
    return this.regionsQuery$;
  }

  updateRegion(id: any, value: any) {
    // fixme: this is a temporary fix to convert the vnos array into an array of objects
    const new_value = { ...value };
    for (let i = 0; i < new_value.vnos.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.vnos[i] = { id: new_value.vnos[i] };
    }
    this.regionsQuery$ = this.api.putAPI<Region>('region', id, new_value);

    return this.regionsQuery$;
  }

  deleteRegion(region_id: number) {
    this.regionsQuery$ = this.api
      .deleteAPI<Region>('region', region_id)
      .subscribe((res) => {
        //console.log('res', res);
      });
  }
}
