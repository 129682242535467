import { NgModule } from '@angular/core';

import { BusinessSalesViewComponent } from './pages/business-sales-view/business-sales-view.component';
import { RouterModule, Routes } from '@angular/router';
import { BusinessSalesComponent } from './pages/business-sales/business-sales.component';
import { BusinessSalesQuestionsComponent } from './components/business-sales-questions/business-sales-questions.component';
import { BusinessSalesInfoComponent } from './components/business-sales-info/business-sales-info.component';
import { BusinessSalesSummaryComponent } from './components/business-sales-summary/business-sales-summary.component';
import { AuthGuard } from 'src/app/_core';

const businessSalesRoutes: Routes = [
  {
    path: '',
    component: BusinessSalesViewComponent,
    children: [
      {
        path: '',
        component: BusinessSalesComponent,
        children: [
          { path: '', redirectTo: 'questions', pathMatch: 'full' },
          {
            path: 'questions',
            component: BusinessSalesQuestionsComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard],

            data: {
              roles: ['salesmanager', 'businesssalesagent', 'superadmin'],
            },
          },
          {
            path: 'info',

            pathMatch: 'full',
            canActivate: [AuthGuard],
            data: {
              roles: ['salesmanager', 'businesssalesagent', 'superadmin'],
            },
            component: BusinessSalesInfoComponent,
          },
          {
            path: 'summary',
            // pathMatch: 'full',
            canActivate: [AuthGuard],
            pathMatch: 'full',

            data: {
              roles: ['salesmanager', 'businesssalesagent', 'superadmin'],
            },
            component: BusinessSalesSummaryComponent,
          },

          { path: '**', redirectTo: 'questions' },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(businessSalesRoutes)],
  exports: [RouterModule],
})
export class BusinessSalesRoutingModule {}
