import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TenantsService } from 'src/app/_share/_services/tenants.service';

@Component({
  selector: 'app-portal-tenants',
  templateUrl: './portal-tenants.component.html',
  styleUrls: ['./portal-tenants.component.scss'],
})
export class PortalTenantsComponent implements OnInit {
  portalTenants: any[] = [];
  portalTenants$: Subject<any[]> = new Subject<any[]>();
  portalTenantsQuery$: any;

  columns: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
  ];
  constructor(private tenantsServce: TenantsService) {}

  ngOnInit(): void {
    this.portalTenantsQuery$ = this.tenantsServce
      .getPortalTenants()
      .subscribe((portalTenants) => {
        this.portalTenants = portalTenants;
        this.portalTenants$.next(this.portalTenants);
      });
  }

  ngOnDestroy() {
    this.portalTenantsQuery$.unsubscribe();
  }
}
