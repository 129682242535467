import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControls } from 'src/app/_share/_models/forms/form-controls.interface';
import { BusinessSalesService } from '../../_services/business-sales.service';

@Component({
  selector: 'app-business-sales-info',
  templateUrl: './business-sales-info.component.html',
  styleUrls: ['./business-sales-info.component.scss'],
})
export class BusinessSalesInfoComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    public bss: BusinessSalesService,
  ) {}
  rsaPhoneNumberRegex: RegExp = /^(?:\+27|0)\d{9,11}$/;

  questionControls: FormControls[] = [
    {
      name: 'businessName',
      label: 'Business Name',
      type: 'text',
      required: true,
      value: '',
    },
    {
      name: 'businessTelephone',
      label: 'Business Telephone',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'businessEmail',
      label: 'Business Email',
      type: 'email',
      required: true,
      value: '',
    },

    {
      name: 'addressLine1',
      label: 'Address Line 1',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'addressLine2',
      label: 'Address Line 2',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'suburb',
      label: 'Suburb',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'province',
      label: 'Province',
      type: 'select',
      valueOptions: [
        { label: 'Eastern Cape', value: 'Eastern Cape' },
        { label: 'Free State', value: 'Free State' },
        { label: 'Gauteng', value: 'Gauteng' },
        { label: 'KwaZulu-Natal', value: 'KwaZulu-Natal' },
        { label: 'Limpopo', value: 'Limpopo' },
        { label: 'Mpumalanga', value: 'Mpumalanga' },
        { label: 'North West', value: 'North West' },
        { label: 'Northern Cape', value: 'Northern Cape' },
        { label: 'Western Cape', value: 'Western Cape' },
      ],
      required: false,
      value: '',
    },
    {
      name: 'postalCode',
      label: 'Postal Code',
      type: 'text',
      required: false,
      value: '',
    },

    {
      name: 'title',
      label: 'Title',
      type: 'select',
      valueOptions: [
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Dr', value: 'Dr' },
        { label: 'Prof', value: 'Prof' },
      ],
      required: false,
      value: '',
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'telephone',
      label: 'Telephone',
      type: 'text',
      required: false,
      value: '',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      required: false,
      value: '',
    },

    {
      name: 'product',
      label: 'Product',
      type: 'select',
      required: true,
      valueOptions: [
        {
          description: 'Business Fibre (FTTB) - 20Mbps - 24 Months',
          name: 'Business Fibre - 20Mbps',
          product_code: '60-1-30-72',
          product_id_reference: '8260',
          recurring_amount_excl: 1499,
          recurring_amount_incl: 1723.85,
          speed_download_mbps: 20,
          speed_upload_mbps: 20,
        },
      ],
      value: '',
    },
  ];

  ngOnInit(): void {
    console.log('BusinessSalesInfoComponent init');
    this.bss.activeStepIndex = 1;

    if (this.questionControls.find((control) => control.name === 'product')) {
      this.questionControls.find(
        (control) => control.name === 'product',
      )!.valueOptions = this.bss.businessSalesProducts;
    }
  }

  findFormControlName(controlName: string) {
    return this.questionControls.find(
      (control) => control.name === controlName,
    );
  }
}
