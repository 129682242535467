import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';

@Component({
  selector: 'app-subscription-statistics',
  templateUrl: './subscription-statistics.component.html',
  styleUrls: ['./subscription-statistics.component.scss'],
})
export class SubscriptionStatisticsComponent implements OnInit {
  constructor(private pss: PortalSubscriptionService) {}

  subscriptionStatistics: any;
  subscriptionStatistics$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  ngOnInit(): void {
    this.pss.getPortalSubscriptionStatistics().subscribe((res) => {
      console.log('subs stats', res);
      this.subscriptionStatistics = res;
      this.subscriptionStatistics$.next(this.subscriptionStatistics);
    });
  }
}
