<!-- 


 -->

<p-table
  #mainTable
  [value]="(data | async) || []"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [showCurrentPageReport]="true"
  [rows]="pageAmount"
  [rowsPerPageOptions]="[50, 100, 200]"
  [tableStyle]="{ 'min-width': '75vw' }"
  responsiveLayout="scroll"
  [globalFilterFields]="globalFilterFields"
  [scrollable]="true"
  scrollDirection="both"
  scrollHeight="55vh"
  selectionMode="single"
  [(selection)]="selectedRow"
  (onRowSelect)="onRowSelect($event)"
  (onContextMenu)="onContextMenu($event)"
  [contextMenu]="cm"
  [(contextMenuSelection)]="selectedRowCM"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear Search/Filters"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clearFilter(mainTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          name="globalFilter"
          class="p-inputtext-sm"
          type="text"
          placeholder="Search"
          (input)="mainTable.filterGlobal(getInputValue($event), 'contains')"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
          <p-columnFilter
            type="text"
            [field]="col.field"
            display="menu"
          ></p-columnFilter>
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entity>
    <tr [pSelectableRow]="entity" [pContextMenuRow]="entity" (click)="sndMessage(entity)">
      <td *ngFor="let col of columns">
        <ng-container *ngIf="col.relationshipType === 'single'">
          <button
            (click)="navigateToRelationship(col.relationshipFields, entity)"
            pButton
            class="p-button p-button-link"
            [label]="getValueFromRelationship(col.relationshipFields, entity)"
          ></button>
        </ng-container>

        <ng-container *ngIf="col.relationshipType === 'plural'">
          <p-chip
            class=""
            *ngFor="
              let relatedEntity of getValueFromRelationshipEntity(
                col.relationshipFields,
                entity
              )
            "
            label="{{
              getDescriptorFromPath(relatedEntity, col.relationshipFields)
            }}"
          ></p-chip>
        </ng-container>

        <ng-container
          *ngIf="
            !col.relatedField &&
            col.field != 'toc_file_reference' &&
            col.field != 'toc_status' &&
            col.field != 'date_created' &&
            col.field != 'date_updated' &&
            col.field != 'configured' &&
            col.field != 'available' &&
            col.field != 'billed' &&
            col.field != 'notified'
          "
        >
          {{ entity[col.field] }}
        </ng-container>

        <ng-container
          *ngIf="col.field === 'date_created' || col.field === 'date_updated'"
        >
          {{ entity[col.field] | date : "iso" }}
        </ng-container>

        <ng-container *ngIf="col.field === 'toc_file_reference'">
          <button
            (click)="downloadFile(entity['toc_file_reference'])"
            pButton
            icon="pi pi-download"
            class="p-button p-button-link"
            [label]="entity['toc_file_reference']"
          ></button>
        </ng-container>

        <ng-container
          *ngIf="
            col.field === 'configured' ||
            col.field === 'available' ||
            col.field === 'billed' ||
            col.field === 'notified'
          "
        >
          <div *ngIf="entity[col.field] != null">
            <p-tag
              severity="{{ getSeverityFromBool(entity[col.field]) }}"
              value="{{ entity[col.field] | boolAsString }}"
            ></p-tag>
          </div>
        </ng-container>

        <!-- status chip/badge/tag -->
        <ng-container *ngIf="col.field === 'toc_status'">
          <div *ngIf="entity[col.field] != null">
            <p-tag
              severity="{{ getSeverityFromStatus(entity[col.field]) }}"
              value="{{ entity[col.field] | uppercase }}"
            ></p-tag>
          </div>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="!loading"
          mode="indeterminate"
        >
        </p-progressBar>
        <div *ngIf="loading">
          <p>
            There are no records to show. You might be disconnected or offline.
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-contextMenu #cm [model]="this.menuItems"></p-contextMenu>
