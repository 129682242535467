import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_share/_models/entities/user';
import { UserService } from 'src/app/_share/_services/user.service';

@Component({
  selector: 'app-user-account-profile',
  templateUrl: './user-account-profile.component.html',
  styleUrls: ['./user-account-profile.component.scss'],
})
export class UserAccountProfileComponent implements OnInit {
  profileFields: any[] = [
    { field: 'first_name', label: 'First Name' },
    { field: 'last_name', label: 'Last Name' },
    { field: 'email', label: 'Email' },
    { field: 'phone', label: 'Phone' },
    { field: 'role', label: 'Role' },
  ];

  constructor(public us: UserService) {}

  ngOnInit(): void {}

  resolveField(user: User, fieldString: { field: keyof User }) {
    const field = fieldString.field;
    if (field === 'role') {
      const role = user[field];
      return role?.description;
    }

    const value = user[field];
    return value;
  }
}
