import { Injectable } from '@angular/core';
import { ApiService } from '../../_core/_services/api.service';
import { BehaviorSubject, map } from 'rxjs';
import { Tenant } from '../_models/entities/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  tenants: Tenant[] = [];
  tenants$: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);

  constructor(private api: ApiService) {}

  getAll() {
    return this.api.getAPI('tenants').pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }

  getPortalTenants() {
    return this.api.getAPI('portal-tenants').pipe(
      map((data: any) => {
        return data.body;
      })
    );
  }
}
