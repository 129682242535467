import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessSalesService } from '../../_services/business-sales.service';

@Component({
  selector: 'app-business-sales-summary',
  templateUrl: './business-sales-summary.component.html',
  styleUrls: ['./business-sales-summary.component.scss'],
})
export class BusinessSalesSummaryComponent implements OnInit {
  constructor(
    private router: Router,
    public bss: BusinessSalesService,
  ) {}

  businessDetails = [
    {
      header: 'Business Name',
      value: 'Test Business Name',
      name: 'businessName',
    },
    {
      header: 'Business Telephone',
      value: '0123456789',
      name: 'businessTelephone',
    },
    {
      header: 'Business Email',
      value: 'testbusines@test.com',
      name: 'businessEmail',
    },
  ];

  businessAddress = [
    {
      header: 'Business Name',
      value: 'Test Business Name',
      name: 'businessName',
    },
    {
      header: 'Business Telephone',
      value: '0123456789',
      name: 'businessTelephone',
    },
    {
      header: 'Business Email',
      value: 'testbusines@test.com',
      name: 'businessEmail',
    },
  ];

  businessPrimaryContact = [];

  ngOnInit(): void {
    console.log('BusinessSalesSummaryComponent init');
    this.bss.activeStepIndex = 2;
  }

  resetAndNavigate() {
    this.bss.qualifyingForm.reset();
    this.bss.businessDetailsForm.reset();
    this.router.navigate(['business', 'questions']);

    this.bss.activeStepIndex = 0;
    this.bss.submitted = false;
    this.bss.submitted$.next(false);

    this.bss.loading = false;
    this.bss.loading$.next(false);
  }
}
