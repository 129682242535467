import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeadContactsService {
  leadContactsQuery$: any;

  leadContacts: any[] = [];
  leadContacts$: Subject<any[]> = new Subject<any[]>();

  constructor(public api: ApiService) {}

  getLeadContacts() {
    this.api.getAPI<any>('crm/contact').subscribe((res) => {
      //console.log('res', res);
      this.leadContacts = res.body;
      this.leadContacts$.next(this.leadContacts);
    });
  }
}
