<div *ngIf="(leads$ | async) || [] as leads">
  <p-table
    #leadsTable
    [loading]="isLeadsLoading"
    [value]="leads"
    dataKey="id"
    [globalFilterFields]="[
      'company.name',
      'lead_owner.first_name',
      'lead_status'
    ]"
    [tableStyle]="{
      'min-width': '50vw',
      'min-height': '50vh',
      'max-width': '100%'
    }"
    [columns]="columns"
    responsiveLayout="scroll"
    [scrollable]="true"
    scrollDirection="both"
    scrollHeight="75vh"
    selectionMode="single"
    [virtualScroll]="true"
    [lazy]="true"
    [virtualScrollItemSize]="100"
    [showCurrentPageReport]="true"
    [rows]="leads.length > 0 ? 100 : 100"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onLazyLoad)="loadLeadsLazy($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            name="globalFilter"
            class="p-inputtext-sm"
            type="text"
            placeholder="Search"
            (input)="leadsTable.filterGlobal(getInputValue($event), 'contains')"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">
          <div class="flex align-items-center">
            {{ col.header }}
            <p-columnFilter
              *ngIf="col.filtering === true"
              type="text"
              [field]="col.field"
              display="menu"
            ></p-columnFilter>
            <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-lead>
      <tr>
        <td *ngFor="let col of columns">
          <div *ngIf="col.fields[0] != 'actions'; else showActions">
            {{ resolveCombinedFields(lead, col.fields) }}
          </div>
          <ng-template #showActions>
            <p-table
              [value]="lead.actions"
              [tableStyle]="{ 'min-width': '25vw', 'max-width': '25vw' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Action Type</th>
                  <th>Action Owner</th>
                  <th>Action Details</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-action>
                <tr>
                  <td style="width: 15vw">
                    {{ action.action_details }}
                  </td>
                  <td>
                    <span class="text-600 text-sm">
                      {{ action.action_owner?.first_name }}
                      {{ action.action_owner?.last_name }}
                    </span>
                  </td>
                  <td>
                    <p-tag
                      [severity]="getSeverityFromStatus(action.action_type)"
                      >{{ action.action_type | uppercase }}</p-tag
                    >
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody" let-columns="columns">
      <tr style="height: 92px">
        <td *ngFor="let col of columns; let even = even">
          <p-skeleton
            [ngStyle]="{
              width: even ? (col.field === 'year' ? '30%' : '40%') : '60%'
            }"
          ></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center justify-content-center"
        >
          <h3 class="text-center">There are no records to show.</h3>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center justify-content-center"
        >
          <p>
            <span class="text-600 text-sm">Total Leads: </span>
            {{ leads.length }}
          </p>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
