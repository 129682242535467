import { Component, Input, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { KeyIndividualFormComponent } from '../key-individual-form/key-individual-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LeadsService } from 'src/app/features/crm/_services/leads.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/_share/_services/notification.service';

@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.scss'],
})
export class LeadDetailComponent implements OnInit {
  filterValue: any;
  constructor(
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private leadsService: LeadsService,
    private notificationService: NotificationService,
    private clipboard: Clipboard
  ) {}

  @Input() lead: any;
  @Input() loading: any;

  leadDetailsForm: FormGroup<any> = new FormGroup({
    name: new FormControl('', Validators.required),
    address: new FormControl(''),
    phone: new FormControl(''),
    website: new FormControl(''),
    email: new FormControl(''),
    multiple_branches: new FormControl(false),
    staff_count: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
  });

  ref: DynamicDialogRef | undefined;

  successfullCoordsCopy: boolean = false;

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.leadDetailsForm.patchValue({
      name: this.lead.company.name,
      address: this.lead.company.address,
      phone: this.convertToLocalNumber(this.lead.company.phone),
      website: this.lead.company.website,
      email: this.lead.company.email,
      multiple_branches: this.lead.company.multiple_branches,
      staff_count: this.lead.company.staff_count,
      latitude: this.lead.company.latitude,
      longitude: this.lead.company.longitude,
    });
    this.successfullCoordsCopy = false;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  showAddKeyIndividual($event: any) {
    console.log($event);
    this.ref = this.dialogService.open(KeyIndividualFormComponent, {
      data: {
        formdata: null,
        company_id: this.lead.company.id,
      },
      header: 'Add a key Individual',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showEditKeyIndividual($event: any, key_individual_id: number) {
    console.log($event);
    console.log(key_individual_id);
    console.log(
      this.lead.company.contacts.find(
        (key_individual: any) => key_individual.id === key_individual_id
      )
    );

    this.ref = this.dialogService.open(KeyIndividualFormComponent, {
      data: {
        formdata: this.lead.company.contacts.find(
          (key_individual: any) => key_individual.id === key_individual_id
        ),
      },
      header: 'Add a key Individual',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  onLeadUpdateDetailsSubmit() {
    console.log('lead details - form valid:', this.leadDetailsForm.valid);
    console.log('lead details - form values:', this.leadDetailsForm.value);
    if (this.leadDetailsForm.valid) {
      const company = { ...this.leadDetailsForm.value };
      company.contacts = this.lead.company.contacts;
      company.id = this.lead.company.id;

      // window.alert(this.leadDetailsForm.valid);
      this.lead.company = company;
      this.leadsService.updateCompany(
        this.lead.company.id,
        this.leadDetailsForm.value
      );

      this.notificationService.successfullyModifiedEntity(
        'update',
        'company details'
      );
    }
  }

  copyToClipboard() {
    this.successfullCoordsCopy = false;
    this.clipboard.copy(
      this.leadDetailsForm.value.latitude +
        ',' +
        this.leadDetailsForm.value.longitude
    );
    this.successfullCoordsCopy = true;
  }

  convertToLocalNumber(value: string, country_code = 'ZA') {
    if (country_code === 'ZA') {
      return value.replace('+27', '0');
    } else {
      return value;
    }
  }
}
