<div class="text-900 font-medium text-xl mb-3">Register New User</div>
<div
	class=""
	*ngIf="this.formState == 'register'"
>
	<form
		[formGroup]="form"
		(ngSubmit)="onSubmit()"
	>
		<div class="grid formgrid p-fluid">
			<!-- Generate form fields dynamically based on a configuration object or using *ngFor if applicable -->
			<div
				class="field mb-4 col-12 md:col-6"
				*ngFor="let field of formFields"
			>
				<label
					[for]="field.controlName"
					class="font-medium text-900"
					>{{ field.label }}</label
				>
				<input
					*ngIf="field.type === 'text'"
					id="{{ field.controlName }}"
					formControlName="{{ field.controlName }}"
					pInputText
				/>
				<div *ngIf="field.type === 'radio'">
					<div
						*ngFor="let idtype of identificationTypes"
						class="mb-4"
					>
						<p-radioButton
							[inputId]="idtype.value"
							[value]="idtype.value"
							formControlName="identification_type"
						></p-radioButton>
						<label
							[for]="idtype.value"
							class="ml-2"
							>{{ idtype.label }}</label
						>
					</div>
				</div>

				<textarea
					*ngIf="field.type === 'textarea'"
					[id]="field.controlName"
					formControlName="{{ field.controlName }}"
					pInputTextarea
					rows="3"
				></textarea>
				<div class="invalid-feedback">
					<p-message
						*ngIf="
							submitted &&
							form.controls[field.controlName]?.errors?.['required'] &&
							!form.controls[field.controlName].touched &&
							form.controls[field.controlName].invalid
						"
						severity="error"
						text="{{ field.errorMessage }}"
						styleClass="mt-2 w-full"
					></p-message>
				</div>
			</div>

			<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

			<div class="field mb-4 col-12 md:col-6">
				<label class="font-medium text-900"
					>Identity Document or Passport</label
				>
				<p-fileUpload
					#idDoc
					mode="basic"
					chooseLabel="Choose"
					accept=".pdf"
					[maxFileSize]="10000000"
					(onUpload)="onIdUpload($event)"
					[auto]="true"
					[customUpload]="true"
					(uploadHandler)="uploadIdFile($event)"
				>
				</p-fileUpload>
				<!-- -->
			</div>

			<div class="field mb-4 col-12 md:col-6">
				<label class="font-medium text-900">Identification Documents</label>

				<p-fileUpload
					#toc_file
					mode="basic"
					chooseLabel="Choose"
					[auto]="false"
					accept=".pdf"
					[maxFileSize]="10000000"
					[customUpload]="true"
				>
				</p-fileUpload>
			</div>

			<div class="field mb-4 col-12 md:col-3">
				<label
					for="postal_code"
					class="font-medium text-900"
					>Postal Code</label
				>
				<p-inputMask
					formControlName="postal_code"
					mask="9999"
					placeholder="0000"
				></p-inputMask>
			</div>

			<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

			<div class="field mb-4 col-12 md:col-6">
				<label
					for="password"
					class="font-medium text-900"
					>Password</label
				>
				<input
					pInputText
					formControlName="password"
					id="password"
					type="password"
				/>
				<div
					*ngIf="submitted && f['password'].errors"
					class="invalid-feedback"
				>
					<p-message
						*ngIf="f['password'].errors['required'] && !f['touched']"
						severity="error"
						text="* Please enter your password"
						styleClass="mt-2 w-full"
					></p-message>
				</div>
			</div>

			<div class="field mb-4 col-12 md:col-6">
				<label
					for="confirm_password"
					class="font-medium text-900"
					>Confirm Password</label
				>
				<input
					pInputText
					formControlName="confirm_password"
					id="confirm_password"
					type="password"
				/>
				<div
					*ngIf="submitted && f['confirm_password'].errors"
					class="invalid-feedback"
				>
					<p-message
						*ngIf="f['confirm_password'].errors['required'] && !f['touched']"
						severity="error"
						text="* Please confirm your password"
						styleClass="mt-2 w-full"
					></p-message>
				</div>
			</div>
			<div class="flex justify-content-center pb-3">
				<p-message
					*ngIf="form.errors?.['PasswordNoMatch']"
					severity="error"
					text="The entered passwords do not match"
				></p-message>

				<p-message
					*ngIf="
            (form.controls['confirm_password'].value ===
            form.controls['password'].value) && !form.errors?.['PasswordNoMatch'] && form.controls['confirm_password'].value != '' && form.controls['password'].value != ''
          "
					severity="success"
					text="The passwords match!"
				></p-message>
			</div>
			<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
			<div class="col-12">
				<div class="text-center text-red-500">
					<p-message
						*ngIf="error"
						severity="error"
						[text]="error"
						styleClass="mb-2"
					>
					</p-message>
				</div>
				<div>
					<p-message
						*ngIf="form.invalid && submitted"
						severity="error"
						text="Please fill in all required fields"
						styleClass="mb-2 "
					></p-message>
				</div>
				<button
					pButton
					pRipple
					label="Submit User Registration"
					class="w-auto mt-3"
				></button>
			</div>
		</div>
	</form>
</div>
