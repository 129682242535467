import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PortalPackagesService } from 'src/app/features/portal/_services/portal-packages.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-update-package-dialog',
  templateUrl: './update-package-dialog.component.html',
  styleUrls: ['./update-package-dialog.component.scss'],
})
export class UpdatePackageDialogComponent implements OnInit {
  product_type: any[];
  selectedProduct: any;
  product_id: number = 0;
  checked: boolean = false;

  dynamicForm: any = this.fb.group({
    product_type: [''],
    name: [''],
    description: [''],
    recurring_price_excl: [''],
    recurring_price_incl: [''],
    recurring_description: [''],
    speed_down_mbps: [''],
    speed_up_mbps: [''],
    currency: [''],
    onceoff_price_excl: [''],
    onceoff_price_incl: [''],
    onceoff_description: [''],
    renewal_type: [''],
    duration_days: [''],
    available: [''],
  });

  constructor(
    public portalPackagesService: PortalPackagesService,
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.product_type = ['Fixed', 'Hotspot'];
  }

  ngOnInit(): void {
    const product = this.config.data.data;
    this.dynamicForm.patchValue(product);
    console.log(product);

    this.portalPackagesService.getPortalPackages().subscribe((res: any) => {
      this.product_id = res.find(
        (product: any) => product.name === product.name
      ).id;
      console.log(this.product_id);
    });
  }

  onSubmit() {
    if (this.dynamicForm.invalid) {
      console.log('invalid form');
      return;
    }

    console.log(this.dynamicForm.value);
    this.dynamicForm.value.available = this.checked;
    this.portalPackagesService
      .updatePortalPackage(this.product_id, this.dynamicForm.value)
      .subscribe((res: any) => {
        console.log(res);
        this.ref.close();
      });
  }

  onCancel() {
    this.ref.close();
  }
}
