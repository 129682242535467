import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortalProducts } from 'src/app/_share/_models/portal/interfaces';
import { PortalSubscriptions } from 'src/app/_share/_models/portal/portal-subscriptions.model';
import { BillingService } from '../../_services/billing.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  tabs = [
    { key: 'overview', name: 'Overview' },
    { key: 'products-and-promotions', name: 'Products and Promotions' },
    { key: 'billing', name: 'Billing' },
    { key: 'isps', name: 'ISPs' },
    { key: 'services', name: 'Services' },
  ];

  selectedCustomer: any;

  customers: any[] = [];
  customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.customers,
  );

  products: PortalProducts[] = [
    {
      product_type: 'fixed',
      product_category: 'ftth',

      name: 'FTTH 30/15 - Assymmetrical',
      description: '30Mbps : 15Mbps FTTH Assymmetrical',
      recurring_price_excl: 600,
      recurring_price_incl: 600,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 30,
      speed_up_mbps: 15,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 60/30 - Assymmetrical',
      description: '50Mbps : 25Mbps FTTH Assymmetrical',
      recurring_price_excl: 550,
      recurring_price_incl: 550,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 60,
      speed_up_mbps: 30,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring', //month-to-month
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 100/50 - Assymmetrical',
      description: '100Mbps : 50Mbps FTTH Assymmetrical',
      recurring_price_excl: 880,
      recurring_price_incl: 880,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'ftth',
      name: 'FTTH 200/100 - Assymmetrical',
      description: '100Mbps : 50Mbps FTTH Assymmetrical',
      recurring_price_excl: 880,
      recurring_price_incl: 880,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 30/30 - Symmetrical',
      description: '30Mbps : 30Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 50,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 50/50 - Symmetrical',
      description: '50Mbps : 50Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 60,
      speed_up_mbps: 60,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 100/100 - Symmetrical',
      description: '100Mbps : 100Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 100,
      speed_up_mbps: 100,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
    {
      product_type: 'fixed',
      product_category: 'fttb',
      name: 'FTTB 200/200 - Symmetrical',
      description: '200Mbps : 200Mbps FTTH Symmetrical',
      recurring_price_excl: 780,
      recurring_price_incl: 780,
      recurring_description: 'Monthly Fee',
      speed_down_mbps: 200,
      speed_up_mbps: 200,
      currency: 'NAD',
      onceoff_price_excl: 1500,
      onceoff_price_incl: 1500,
      onceoff_description: 'Installation Fee',
      renewal_type: 'recurring',
      duration_days: 30,
    },
  ];

  categories = [
    {
      name: 'FTTH',
      key: 'ftth',
    },
    {
      name: 'FTTB',
      key: 'fttb',
    },
  ];

  promotions = [
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WHK',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
    {
      name: 'Free Promotional Installation - WVB',
      dateStart: '2024-01-01',
      dateEnd: '2024-12-31',
      description: 'Free installation on all FTTH products - WVB',
      area: 'Windhoek',
      promotion_type: 'installation',
    },
  ];
  constructor(private bs: BillingService) {}

  ngOnInit(): void {
    this.bs.getCustomers().subscribe((customers) => {
      this.customers = customers;
      this.customers$.next(customers);
    });
  }
}
