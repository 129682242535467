<div class="flex flex-column align-items-center justify-content-center">
  <div class="flex m-5 p-2"></div>
  <div class="flex m-5 p-2 text-center">
    <h2>We are redirecting you to the payment portal... Just hang tight...</h2>
  </div>

  <div class="flex m-5 p-2">
    <p-progressSpinner
      class="m-5"
      [style]="{ width: '50px', height: '50px' }"
    ></p-progressSpinner>
  </div>

  <!-- payfast -->
  <ng-container *ngIf="PAYFAST_SANDBOX; else liveForm">
    <form
      action="https://sandbox.payfast.co.za/eng/process"
      method="post"
      id="payfastForm"
      [formGroup]="payfastPaymentForm"
    >
      <input
        type="hidden"
        *ngFor="let pfControl of getFormControls(payfastPaymentForm)"
        [formControlName]="pfControl"
        name="{{ pfControl }}"
        [value]="payfastPaymentForm.get(pfControl)?.value"
      />

      <input type="submit" hidden />
    </form>
  </ng-container>
  <ng-template #liveForm>
    <form
      action="https://www.payfast.co.za/eng/process"
      method="post"
      id="payfastForm"
      [formGroup]="payfastPaymentForm"
    >
      <input
        type="hidden"
        *ngFor="let pfControl of getFormControls(payfastPaymentForm)"
        [formControlName]="pfControl"
        name="{{ pfControl }}"
        [value]="payfastPaymentForm.get(pfControl)?.value"
      />

      <input type="submit" hidden />
    </form>
  </ng-template>

  <!-- paygate -->

  <!-- stripe -->

  <!-- add others -->
</div>
