import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';

import { confirmPasswordValidator } from '../../_validators/confirm-password.validator';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loading: boolean = false;
  btnLoad: boolean = false;
  submitted: boolean = false;
  error: string = '';
  forgotPasswordForm: any;
  newPasswordForm: any;

  resetObject = {
    email: '',
    password: '',
    token: '',
  };

  state = 'forgotPassword';

  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // create the form with controls to reset password

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.newPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      validator: confirmPasswordValidator,
    });

    // get token from url
    this.route.queryParams.subscribe((params) => {
      // console.log('params', params);
      console.log('it worked', params);
      if (params['token'] && params['token'].length > 0 && params['email']) {
        this.state = 'resetPassword';
        this.resetObject.token = params['token'];
        this.resetObject.email = params['email'];
      }
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  get fn() {
    return this.newPasswordForm.controls;
  }

  onForgotSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.forgotPassword(this.f.email.value).subscribe(
      (res: any) => {
        // get return url from query parameters or default to home page
        console.log('res', res);
        this.loading = false;
        this.state = 'requestSent';
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false; // Set loading to false in the error callback as well
        this.error = 'No such user please enter a valid email';
      }
    );
  }

  onResetSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.fn.password.value !== this.fn.confirmPassword.value) {
      this.error = 'Passwords do not match';
      this.loading = false;
      return;
    }

    this.resetObject.password = this.fn.password.value;

    this.userService
      .resetPassword(
        this.resetObject.email,
        this.resetObject.token,
        this.resetObject.password
      )
      .subscribe(
        (res: any) => {
          // get return url from query parameters or default to home page
          console.log('res', res);
          this.loading = false;
          this.state = 'resetSent';
        },
        (error) => {
          console.error('Error:', error);
          this.loading = false; // Set loading to false in the error callback as well
          this.error = 'Token expired';
        }
      );
  }

  clearError() {
    this.error = ''; // Clear the error message
  }
}
