import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../../_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGatewayService {
  constructor(
    public fb: FormBuilder,
    private api: ApiService,
    private router: Router
  ) {}

  payment_request_id: any = '';
  checksum: any = '';
  waitingForRedirect: boolean = false;

  paymentGatewayForm = this.fb.group({
    amount: [0, Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  paygateInitPayment(amount: number, email: string, reference: string) {
    return this.api.postAPI('portal/payments/create', {
      amount: amount,
      email: email,
      reference: reference,
      unique_reference: '123456789',
    });
  }

  createSubscription(subscription: any) {
    return this.api.postAPI('portal/subscriptions/create', subscription);
  }

  createPayment(payment: any) {
    return this.api.postAPI('portal/payment/new', payment);
  }

  // paygateRedirect() {
  //   this.waitingForRedirect = false;
  //   window.location.href = `https://secure.paygate.co.za/payweb3/process.trans?PAY_REQUEST_ID=${this.payment_request_id}&CHECKSUM=${this.checksum}`;
  // }
}
