import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lead-screen',
  templateUrl: './lead-screen.component.html',
  styleUrls: ['./lead-screen.component.scss'],
})
export class LeadScreenComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}
}
