import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PortalAuthenticationService } from 'src/app/features/portal/_services/portal-authentication.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-portal-login',
  templateUrl: './portal-login.component.html',
  styleUrls: ['./portal-login.component.scss'],
})
export class PortalLoginComponent implements OnInit {
  loading = false;
  submitted = false;
  error = '';
  errorMessages: any;

  constructor(
    public fb: FormBuilder,
    private portalUsersService: PortalUsersService,
    private portalAuthenticationService: PortalAuthenticationService,
    private ref: DynamicDialogRef,
    private messageService: MessageService
  ) {}

  portalUserForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit(): void {}

  get f() {
    return this.portalUserForm.controls;
  }

  onFormSubmit() {
    console.log('Form valid: ', this.portalUserForm.valid);
    console.log('Form values: ', this.portalUserForm.value);
    console.log('Original form values: ', this.portalUserForm);
    this.submitted = true;
    // stop here if form is invalid
    if (this.portalUserForm.invalid) {
      return;
    }

    if (this.portalUserForm.valid) {
      this.portalAuthenticationService
        .login(
          this.portalUserForm.value.username,
          this.portalUserForm.value.password
        )
        .subscribe(
          (response) => {
            console.log('Response: ', response);
            this.ref.close();
            this.messageService.add({
              key: 'portal',
              severity: 'success',
              summary: 'Success',
              detail: 'Login successful',
            });
          },
          (error) => {
            this.error = error;
            this.errorMessages = error.error.detail;
          }
        );
    }
  }
}
