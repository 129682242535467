<!-- portal body -->
<div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
  <div class="p-fluid flex flex-column lg:flex-row">
    <ul
      class="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-evenly md:justify-content-between lg:justify-content-start mb-5 lg:pr-8 lg:mb-0"
    >
      <li>
        <a
          style="text-decoration: none"
          pRipple
          class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          [routerLink]="['/portal']"
        >
          <i class="pi pi-home md:mr-2"></i>
          <span class="font-medium hidden md:block">Home</span>
        </a>
      </li>
      <li>
        <a
          pRipple
          class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          (click)="activeProfileTab = 'profile'"
        >
          <i class="pi pi-user md:mr-2"></i>
          <span class="font-medium hidden md:block">Profile</span>
        </a>
      </li>
      <li>
        <a
          pRipple
          class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          (click)="activeProfileTab = 'account'"
        >
          <i class="pi pi-cog md:mr-2"></i>
          <span class="font-medium hidden md:block">Account</span>
        </a>
      </li>
      <!-- <li>
          <a
            pRipple
            class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          >
            <i class="pi pi-palette md:mr-2"></i>
            <span class="font-medium hidden md:block">Appearance</span>
          </a>
        </li> -->
      <!-- <li>
          <a
            pRipple
            class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          >
            <i class="pi pi-sun md:mr-2"></i>
            <span class="font-medium hidden md:block">Accessibility</span>
          </a>
        </li> -->
      <!-- <li>
          <a
            pRipple
            class="flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-hover transition-duration-150 transition-colors"
          >
            <i class="pi pi-bell md:mr-2"></i>
            <span class="font-medium hidden md:block">Notifications</span>
          </a>
        </li> -->
    </ul>

    <!-- profile -->
    <ng-container *ngIf="portalCustomer$ | async as portalCustomerDetails">
      <div
        class="surface-card p-1 shadow-2 border-round flex-auto"
        *ngIf="activeProfileTab == 'profile'"
      >
        <div class="surface-section px-6 pt-5">
          <div class="text-3xl font-medium text-900 mb-4">Profile</div>
          <p-divider></p-divider>
          <!-- <p-tabMenu [model]="items" [activeItem]="items[0]"></p-tabMenu> -->
        </div>

        <div
          class="surface-section px-6 py-5 overflow-y-scroll"
          style="max-height: 70vh"
        >
          <app-share-user-customer-details
            [customer]="portalCustomerDetails"
          ></app-share-user-customer-details>
        </div>
        <div
          class="surface-section px-6 py-5 overflow-y-scroll"
          style="max-height: 70vh"
        >
          <share-user-files
            [files]="(portalCustomerFiles$ | async) || []"
          ></share-user-files>
        </div>
      </div>
    </ng-container>

    <!-- account -->
    <ng-container
      *ngIf="portalSubscription$ | async as portalSubscriptionDetails"
    >
      <div
        class="surface-card shadow-2 p-1 border-round flex-auto"
        *ngIf="activeProfileTab == 'account'"
      >
        <!-- from prime blocks -->
        <div class="surface-ground">
          <div class="surface-section px-6 pt-5">
            <div class="text-3xl font-medium text-900 mb-4">Account</div>
            <p-divider></p-divider>
            <!-- <p-tabMenu [model]="items" [activeItem]="items[0]"></p-tabMenu> -->
          </div>
          <div class="overflow-y-scroll" style="max-height: 70vh">
            <div class="surface-section px-6 py-1">
              <div
                class="flex align-items-start flex-column lg:flex-row lg:justify-content-between"
              >
                <div class="flex align-items-start flex-column md:flex-row">
                  <!-- <img
                  src="assets/images/blocks/avatars/circle/avatar-f-1@2x.png"
                  class="mr-5 mb-3 lg:mb-0"
                  style="width: 90px; height: 90px"
                /> -->
                </div>
              </div>
            </div>
            <div class="px-6 py-5">
              <div class="surface-card p-4 shadow-2 border-round">
                <share-subscription-details
                  [subscription]="portalSubscription"
                ></share-subscription-details>
                <div
                  class="font-medium text-3xl text-900 mb-3 align-content-center justify-content-center"
                >
                  <button
                    pButton
                    pRipple
                    disabled
                    label="Cancel Subscription"
                    class="w-2 my-4 mx-1 p-button-rounded p-button-danger"
                    (click)="cancelSubscription()"
                  ></button>
                  <button
                    pButton
                    pRipple
                    disabled
                    label="Upgrade Subscription"
                    class="w-2 my-4 mx-1 p-button-rounded p-button-success"
                  ></button>
                  <button
                    pButton
                    pRipple
                    label="Activate Subscription"
                    class="w-2 my-4 mx-1 p-button-rounded p-button-success"
                    (click)="activateSubscription()"
                  ></button>
                </div>
              </div>
            </div>

            <div class="py-5 px-6">
              <div class="surface-card p-4 shadow-2 border-round">
                <div class="font-medium text-3xl text-900 mb-3">
                  Account Transactions
                </div>
                <p-table
                  #transactionsTable
                  [value]="portalTransactions"
                  scrollHeight="40vh"
                  [scrollable]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        *ngFor="let col of transactionColumns"
                        [pSortableColumn]="col.field"
                      >
                        <div class="flex align-items-center">
                          {{ col.header }}
                        </div>
                      </th>
                      <th>
                        <div class="flex align-items-center">Actions</div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-transaction>
                    <tr
                      [pSelectableRow]="transaction"
                      [pContextMenuRow]="transaction"
                    >
                      <td *ngFor="let col of transactionColumns">
                        <div
                          class="flex align-items-center"
                          *ngIf="col.field != 'amount_incl'"
                        >
                          {{ transaction[col.field] }}
                        </div>

                        <div
                          class="flex align-items-center"
                          *ngIf="
                            col.field == 'amount_incl' &&
                            transaction.transaction_type == 'debit' &&
                            col.header == 'Bills'
                          "
                        >
                          {{ transaction[col.field] | currency: "R" }}
                        </div>
                        <div
                          class="flex align-items-center text-green-500"
                          *ngIf="
                            col.field == 'amount_incl' &&
                            transaction.transaction_type == 'credit' &&
                            col.header == 'Payments'
                          "
                        >
                          {{ transaction[col.field] | currency: "R" }}
                        </div>
                      </td>
                      <td>
                        <button
                          *ngIf="
                            transaction.transaction_type == 'credit' &&
                            transaction.status == 'pending'
                          "
                          pButton
                          pRipple
                          label="Pay"
                          class="p-button-rounded p-button-success p-button-sm"
                          (click)="payTransaction(transaction, 'cash')"
                        ></button>
                      </td>
                    </tr>
                  </ng-template>
                  <!-- <ng-template pTemplate="emptymessage">
               <tr>
                 <td
                   [attr.colspan]="this.getNumberofColumns()"
                   class="align-content-center"
                 >
                   <p>
                     There are no records to show. You might be disconnected or offline.
                   </p>
                 </td>
               </tr>
             </ng-template> -->
                </p-table>
              </div>
            </div>
            <div class="py-5 px-6">
              <div class="font-medium text-3xl text-900 mb-3">
                Application Flow
              </div>
              <ul class="list-none p-0 m-0 flex flex-column md:flex-row">
                <li class="relative mr-0 md:mr-8 flex-auto">
                  <div
                    class="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1"
                  >
                    <i
                      class="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"
                    ></i>
                    <div>
                      <div class="text-900 font-medium mb-1">Applied</div>
                      <span class="text-600 text-sm hidden md:block"
                        >Tempus iaculis urna id volutpat</span
                      >
                    </div>
                  </div>
                  <div
                    class="w-full absolute top-50 left-100 surface-300 hidden md:block"
                    style="transform: translateY(-50%); height: 2px"
                  ></div>
                </li>
                <li class="relative mr-0 md:mr-8 flex-auto">
                  <div
                    class="border-2 border-blue-500 surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1"
                  >
                    <i
                      class="pi pi-users text-blue-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"
                    ></i>
                    <div>
                      <div class="text-blue-600 font-medium mb-1">
                        Installation
                      </div>
                      <span class="text-600 text-sm hidden md:block"
                        >Nisi vitae suscipit tellus</span
                      >
                    </div>
                  </div>
                  <div
                    class="w-full absolute top-50 left-100 surface-300 hidden md:block"
                    style="transform: translateY(-50%); height: 2px"
                  ></div>
                </li>
                <li class="relative flex-auto">
                  <div
                    class="border-1 surface-border surface-card border-round p-3 flex flex-column md:flex-row align-items-center z-1"
                  >
                    <i
                      class="pi pi-check-circle text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"
                    ></i>
                    <div>
                      <div class="text-900 font-medium mb-1">
                        Service Activation
                      </div>
                      <span class="text-600 text-sm hidden md:block"
                        >Sit amet est placerat in egestas erat</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- <div class="w-2">
      <div
        class="flex relative align-items-center justify-content-center mx-3 py-3 min-h-full"
      >
        <div
          class="border-left-1 surface-border top-0 left-50 absolute h-full"
        ></div>
        <div class="py-2 z-1 surface-0">
          <span class="text-900 font-medium">OR</span>
        </div>
      </div>
    </div>
    <div
      class="w-5 py-8 text-center flex align-items-center justify-content-center"
    >
      <ul class="list-none p-0 m-0">
        <li class="pb-4 border-bottom-1 surface-border">
          <button
            pButton
            pRipple
            label="Sign Up"
            icon="pi pi-user-plus"
            class="p-button-rounded p-button-success p-button-lg"
            (click)="showSignUpDialog($event)"
          ></button>
        </li>
        <li class="pb-4 pt-4 border-bottom-1 surface-border">
          <button
            pButton
            pRipple
            label="Sign In"
            icon="pi pi-user"
            class="p-button-rounded p-button-success p-button-lg"
            (click)="showSignInDialog($event)"
          ></button>
        </li>
        <li class="pt-4">
          <button
            pButton
            pRipple
            label="Forgot Password"
            icon="pi pi-question"
            class="p-button-rounded p-button-warning p-button-outlined p-button-lg"
          ></button>
        </li>
      </ul>
    </div> -->

<div
  class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
>
  <span class="text-600"
    >© 2023, Captive Portal. Powered by STEM Connect.</span
  >
  <div class="col-fixed flex align-items-center mt-4">
    <i class="pi pi-twitter p-1 text-xl text-900 cursor-pointer mr-3"></i>
    <i class="pi pi-facebook p-1 text-xl text-900 cursor-pointer mr-3"></i>
    <i class="pi pi-youtube p-1 text-xl text-900 cursor-pointer mr-3"></i>
    <i class="pi pi-google p-1 text-xl text-900 cursor-pointer mr-3"></i>
  </div>
</div>
