import { Component, OnInit } from '@angular/core';
import { LeadsService } from 'src/app/features/crm/_services/leads.service';

@Component({
  selector: 'app-crm-management',
  templateUrl: './crm-management.component.html',
  styleUrls: ['./crm-management.component.scss'],
})
export class CrmManagementComponent implements OnInit {
  constructor(public leadsService: LeadsService) {}

  ngOnInit(): void {}
}
