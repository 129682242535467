<div class="flex flex-column align-items-center my-4">
  <span
    class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-1"
    style="width: 96px; height: 96px"
  >
    <i class="pi pi-check text-5xl"></i>
  </span>
  <!-- <div class="font-medium text-2xl text-900">{{ this.config.data | json }}</div> -->
</div>
<div class="flex flex-column align-items-center">
  <h2>Support Request Successful!</h2>
  <p
    class="line-height-3 p-0 m-0 text-2xl text-gray-600 text-center md:w-8 w-6"
  >
    Thank you! You have successfully submitted a support request and our team
    will be in contact with you soon.
  </p>
</div>
