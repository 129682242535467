import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PortalPackagesService } from 'src/app/features/portal/_services/portal-packages.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CreatePackageDialogComponent } from './create-package-dialog/create-package-dialog.component';
import { UpdatePackageDialogComponent } from './update-package-dialog/update-package-dialog.component';
import { PortalProductsCreate } from 'src/app/_share/_models/portal/interfaces';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
})
export class PackagesComponent implements OnInit {
  packages: any[] = [];
  packages$: Subject<any[]> = new Subject<any[]>();
  isLoading: boolean = true;
  items: MenuItem[] = [];
  selectedProduct: any;
  columns: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'recurring_price_excl', header: 'Recurring Price Excl.' },
    { field: 'recurring_price_incl', header: 'Recurring Price Incl.' },
    { field: 'onceoff_price_excl', header: 'Once Off Price Excl.' },
    { field: 'onceoff_price_incl', header: 'Once Off Price Incl.' },
    { field: 'duration_days', header: 'Duration Days' },
    { field: 'speed_down_mbps', header: 'Speed (Mbps)' },
    { field: 'speed_up_mbps', header: 'Speed (Mbps)' },
    { field: 'currency', header: 'Currency' },
  ];
  constructor(
    public portalPackagesService: PortalPackagesService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    // this.portalPackagesService.getPortalPackages();
    this.retrievePortalPackages();
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: (event) => {
          this.updateProduct(this.selectedProduct);
        },
        disabled: true,
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: (event) => {
          this.confirmUser();
        },
        disabled: true,
      },
    ];
  }

  addPackage() {
    const ref = this.dialogService.open(CreatePackageDialogComponent, {
      data: {
        data: '',
      },
      width: '75%',
      contentStyle: {
        margin: '0',
        padding: '0',
        height: '100%',
        overflow: 'auto',
      },
    });
  }

  updateProduct(data: PortalProductsCreate) {
    const ref = this.dialogService.open(UpdatePackageDialogComponent, {
      data: {
        data: data,
      },
      width: '75%',
      contentStyle: {
        margin: '0',
        padding: '0',
        height: '100%',
        overflow: 'auto',
      },
    });
  }

  confirmUser() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        // implement delete user endpoint
        console.log('delete user');
      },
    });
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  retrievePortalPackages() {
    this.isLoading = true;
    this.portalPackagesService
      .getPortalPackagesManagement()
      .subscribe((res: any) => {
        this.packages = res;
        this.packages$.next(this.packages);
        this.isLoading = false;
      });
  }

  exportPdf() {
    import('jspdf').then((jsPDFModule) => {
      const { jsPDF } = jsPDFModule;

      import('jspdf-autotable').then((autoTableModule) => {
        const autoTable = autoTableModule.default;

        const doc = new jsPDF('l', 'px', 'a4');
        autoTable(doc, {
          // head: [this.columns.map((c) => c.field)],
          body: this.packages,
        });
        doc.save('packages.pdf');
      });
    });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.packages);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'products');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
    );
  }
}
