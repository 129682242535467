import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_share/_services/user.service';

@Component({
  selector: 'app-portal-management',
  templateUrl: './portal-management.component.html',
  styleUrls: ['./portal-management.component.scss'],
})
export class PortalManagementComponent implements OnInit {
  constructor(public userService: UserService) {}

  ngOnInit(): void {}

  getCurrentPortalTenantName(
    portal_tenant_id: number,
    portal_tenants: any[]
  ): string {
    const tenant = portal_tenants.find((t) => t.id === portal_tenant_id);
    return tenant ? tenant.name : '';
  }
}
