import { Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { ApiService } from '../../../../_core/_services/api.service';
import { Site } from 'src/app/_share/_models/entities/schema';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  sitesQuery$: any;

  sites$: Subject<any[]> = new Subject<any[]>();
  sites: any[] = [];

  constructor(public api: ApiService) {}

  getSites(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Site>('site', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addSite(value: any) {
    console.log('value', value);

    this.sitesQuery$ = this.api.postAPI<any>('site', value);
    return this.sitesQuery$;
  }

  updateSite(id: any, value: any) {
    this.sitesQuery$ = this.api.putAPI<any>('site', id, value);
    return this.sitesQuery$;
  }

  deleteSite(id: any) {
    this.api.deleteAPI<any>('site', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
