import { Component, OnInit, Input } from '@angular/core';
import {
  PortalEndCustomer,
  PortalFiles,
} from '../../_models/portal/interfaces';
import { PortalFilesService } from 'src/app/features/portal/_services/portal-files.service';

@Component({
  selector: 'share-user-files',
  templateUrl: './share-user-files.component.html',
  styleUrls: ['./share-user-files.component.scss'],
})
export class ShareUserFilesComponent implements OnInit {
  @Input() files: PortalFiles[] | undefined;

  constructor(private portalFilesService: PortalFilesService) {}

  ngOnInit(): void {}

  downloadFile(file_id: string, fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    this.portalFilesService.downloadFile(file_id, extension);
  }
}
