import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-script',
  templateUrl: './lead-script.component.html',
  styleUrls: ['./lead-script.component.scss']
})
export class LeadScriptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
