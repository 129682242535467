import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyAsStatusString',
})
export class AnyAsStatusStringPipe implements PipeTransform {
  transform(value: any): string {
    if (value === true) {
      return 'TRUE';
    } else if (value === false) {
      return 'FALSE';
    }
    return String(value.toString()).replace('_', ' ');
  }
}
