import { Component, OnInit } from '@angular/core';
import { UserPreferencesService } from 'src/app/_share/_services/user-preferences.service';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent implements OnInit {
  pageAmount: number = 0;
  pageAmountOptions: any[] = [50, 100, 200];

  constructor(private userPreferencesService: UserPreferencesService) {}

  ngOnInit(): void {
    this.userPreferencesService.getUserPreferences();
    this.pageAmount = this.userPreferencesService.userPreferences.pageAmount;
  }

  changePageAmount() {
    console.log('changePageAmount');
    this.userPreferencesService.setUserPreferenceByKey(
      'pageAmount',
      this.pageAmount
    );
  }
}
