<div class="surface-card shadow-2 p-4">
  <div class="flex flex-column align-items-center justify-content-between mb-4">
    <span class="text-xl font-medium text-900">User Account</span>
    <div class="ml-auto"></div>

    <div class="text-500 mb-5">Welcome to OM!</div>
    <ul
      class="list-none p-0 m-0 border-top-1 surface-border w-full"
      *ngIf="us.currentUser$ | async as currentUser; else loading"
    >
      <li
        class="flex align-items-center py-3 px-2 flex-wrap"
        *ngFor="let field of profileFields; let i = index"
        [ngClass]="{ 'surface-ground': i % 2 === 0 }"
      >
        <div class="text-500 w-full md:w-2 font-medium">{{ field.label }}</div>
        <div class="text-900 w-full md:w-10">
          {{ resolveField(currentUser, field) }}
        </div>
      </li>
    </ul>
    <ng-template #loading>
      <div class="flex align-items-center justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
  </div>
  <div class="h-full" *ngIf="false">
    <div class="flex align-items-center justify-content-between mb-4">
      <div class="text-900 font-medium text-xl">Notifications</div>
      <div></div>
    </div>

    <span class="block text-600 font-medium mb-3">TODAY</span>
    <ul class="p-0 mx-0 mt-0 mb-4 list-none">
      <li class="flex align-items-center py-2 border-bottom-1 surface-border">
        <div
          class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-dollar text-xl text-blue-500"></i>
        </div>
        <span class="text-900 line-height-3 font-medium"
          >Richard Jones
          <span class="text-700 font-normal"
            >has purchased a blue t-shirt for
            <span class="text-primary font-medium">$79</span></span
          >
        </span>
      </li>
      <li class="flex align-items-center py-2">
        <div
          class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-download text-xl text-orange-500"></i>
        </div>
        <span class="text-700 line-height-3"
          >Your request for withdrawal of
          <span class="text-primary font-medium">$2500</span> has been
          initiated.</span
        >
      </li>
    </ul>

    <span class="block text-600 font-medium mb-3">YESTERDAY</span>
    <ul class="p-0 m-0 list-none">
      <li class="flex align-items-center py-2 border-bottom-1 surface-border">
        <div
          class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-dollar text-xl text-blue-500"></i>
        </div>
        <span class="text-900 line-height-3 font-medium"
          >Keyser Wick
          <span class="text-700 font-normal"
            >has purchased a black jacket for
            <span class="text-primary font-medium">$59</span></span
          >
        </span>
      </li>
      <li class="flex align-items-center py-2 border-bottom-1 surface-border">
        <div
          class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-question text-xl text-pink-500"></i>
        </div>
        <span class="text-900 line-height-3 font-medium"
          >Jane Davis
          <span class="text-700 font-normal"
            >has posted a new questions about your product.</span
          >
        </span>
      </li>
      <li class="flex align-items-center py-2">
        <div
          class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-arrow-up text-xl text-green-500"></i>
        </div>
        <span class="text-900 line-height-3 font-medium"
          >Claire Smith
          <span class="text-700 font-normal"
            >has upvoted your store along with a comment.</span
          >
        </span>
      </li>
    </ul>
  </div>
</div>
