import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { BehaviorSubject, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  leadsQuery$: any;

  leadsActive: any[] = [];
  leadsActive$: Subject<any[]> = new Subject<any[]>();

  isLeadsActiveLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  leadsPersonal: any[] = [];
  leadsPersonal$: Subject<any[]> = new Subject<any[]>();

  isLeadsPersonalLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(public api: ApiService) {}

  getAllLeads(offset: number = 0, limit: number = 10, companyName?: string) {
    const params: any = { offset, limit };

    if (companyName) {
      params.company_name = companyName;
    }

    return this.api.getAPI<any>('crm/leads', params).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getActiveLeads() {
    this.isLeadsActiveLoading$.next(true);
    this.api.getAPI<any>('crm/leads/active').subscribe((res) => {
      console.log('res', res);
      this.leadsActive = res.body;
      this.leadsActive$.next(this.leadsActive);
      this.isLeadsActiveLoading$.next(false);
    });
  }

  getPersonalLeads() {
    this.isLeadsPersonalLoading$.next(true);
    this.api.getAPI<any>('crm/leads/personal').subscribe((res) => {
      console.log('res', res);
      this.leadsPersonal = res.body;
      this.leadsPersonal$.next(this.leadsPersonal);
      this.isLeadsPersonalLoading$.next(false);
    });
  }

  // sortNewLeads() {
  //   this.leads.sort((a, b) => {
  //     return a.company.name.localeCompare(b.company.name);
  //   });
  //   this.leads$.next(this.leads);
  // }

  addLeadAction(values: any) {
    this.api.postAPI<any>('crm/leadactions', values).subscribe((res) => {
      console.log('res', res);
    });
  }

  addLeadContact(values: any) {
    this.api.postAPI<any>('crm/leadcontact', values).subscribe((res) => {
      console.log('res', res);
    });
  }

  updateLeadContact(contact_id: number, values: any) {
    this.api
      .putAPI<any>('crm/leadcontact', contact_id, values)
      .subscribe((res) => {
        console.log('res', res);
      });
  }

  deleteLeadContact(contact_id: any) {
    this.api.deleteAPI<any>('crm/leadcontact', contact_id).subscribe((res) => {
      console.log('res', res);
    });
  }

  updateCompany(company_id: number, values: any) {
    this.api.putAPI<any>('crm/company', company_id, values).subscribe((res) => {
      console.log('res', res);
    });
  }

  updateLead(lead_id: number, values: any) {
    console.log('lead to update', lead_id, values);
    this.api.putAPI<any>('crm/leads', lead_id, values).subscribe((res) => {
      console.log('updated lead', res);
    });
  }
}
