import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';

import { RelationshipService } from './relationship.service';
import { NotificationService } from '../../../_share/_services/notification.service';
import { ConfirmationService } from 'primeng/api';
import { first, map, Subject } from 'rxjs';
import { TableMetaData } from 'src/app/_share/_models/components/table-meta-data.model';

interface EntityServiceMap {
  [key: string]: {
    service: any;
    createFn: string;
    readFn: string;
    updateFn: string;
    deleteFn: string;
    entityObservable?: string;
    entityData?: string;
  };
}

interface Filter {
  value: string | null;
  matchMode: string;
  operator: string;
}

interface FilterObject {
  [key: string]: Filter[];
}

// todo: generalise or remove this service
// the purpose of this service is to provide a generic way to interact with the APIs of the different entities
@Injectable({
  providedIn: 'root',
})
export class EntityService {
  entityMap: { [key: string]: string } = {
    vlan: 'Vlans',
    vno: 'Vnos',
    region: 'Regions',
    isp: 'Isps',
    ispcircuit: 'IspCircuits',
    product: 'Products',
    contact: 'Contacts',
    site: 'Sites',
    endcustomer: 'EndCustomers',
    device: 'Devices',
    equipmenttype: 'EquipmentTypes',
    endcustomercircuit: 'EndCustomerCircuits',
    toc: 'Tocs',
  };

  private entityQuery: any;
  private entities: any[] = [];
  entities$: Subject<any[]> = new Subject<any[]>();
  entitiesLoading = false;
  dataToUpdate: any;

  metaData: TableMetaData = { totalRecords: 0, rows: 100, first: 0 };

  metaDataEntityMap: { [key: string]: TableMetaData } = {
    device: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    region: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    vno: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    vlan: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    isp: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    ispcircuit: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    product: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    contact: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    site: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    endcustomer: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    endcustomercircuit: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    equipmenttype: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
    toc: {
      totalRecords: 0,
      rows: 100,
      first: 0,
      loading: true,
      globalFilter: '',
      sort: '',
      filter: [],
    },
  };

  entityServices: EntityServiceMap = {
    Vlans: {
      service: null,
      createFn: 'addVlan',
      readFn: 'getVlans',
      updateFn: 'updateVlan',
      deleteFn: 'deleteVlan',
      entityObservable: 'vlans$',
      entityData: 'vlans',
    },
    Vnos: {
      service: null,
      createFn: 'addVno',
      readFn: 'getVnos',
      updateFn: 'updateVno',
      deleteFn: 'deleteVno',
      entityObservable: 'vnos$',
      entityData: 'vnos',
    },
    Regions: {
      service: null,
      createFn: 'addRegion',
      readFn: 'getRegions',
      updateFn: 'updateRegion',
      deleteFn: 'deleteRegion',
      entityObservable: 'regions$',
      entityData: 'regions',
    },
    Isps: {
      service: null,
      createFn: 'addIsp',
      readFn: 'getIsps',
      updateFn: 'updateIsp',
      deleteFn: 'deleteIsp',
      entityObservable: 'isps$',
      entityData: 'isps',
    },
    IspCircuits: {
      service: null,
      createFn: 'addIspCircuit',
      readFn: 'getIspCircuits',
      updateFn: 'updateIspCircuit',
      deleteFn: 'deleteIspCircuit',
      entityObservable: 'ispCircuits$',
      entityData: 'ispCircuits',
    },
    Products: {
      service: null,
      createFn: 'addProduct',
      readFn: 'getProducts',
      updateFn: 'updateProduct',
      deleteFn: 'deleteProduct',
      entityObservable: 'products$',
      entityData: 'products',
    },
    Contacts: {
      service: null,
      createFn: 'addContact',
      readFn: 'getContacts',
      updateFn: 'updateContact',
      deleteFn: 'deleteContact',
      entityObservable: 'contacts$',
      entityData: 'contacts',
    },
    EquipmentTypes: {
      service: null,
      createFn: 'addEquipmentType',
      readFn: 'getEquipmentTypes',
      updateFn: 'updateEquipmentType',
      deleteFn: 'deleteEquipmentType',
      entityObservable: 'equipmentTypes$',
      entityData: 'equipmentTypes',
    },
    Sites: {
      service: null,
      createFn: 'addSite',
      readFn: 'getSites',
      updateFn: 'updateSite',
      deleteFn: 'deleteSite',
      entityObservable: 'sites$',
      entityData: 'sites',
    },
    EndCustomers: {
      service: null,
      createFn: 'addEndCustomer',
      readFn: 'getEndCustomers',
      updateFn: 'updateEndCustomer',
      deleteFn: 'deleteEndCustomer',
      entityObservable: 'endCustomers$',
      entityData: 'endCustomers',
    },
    EndCustomerCircuits: {
      service: null,
      createFn: 'addEndCustomerCircuit',
      readFn: 'getEndCustomerCircuits',
      updateFn: 'updateEndCustomerCircuit',
      deleteFn: 'deleteEndCustomerCircuit',
      entityObservable: 'endCustomerCircuits$',
      entityData: 'endCustomerCircuits',
    },
    Devices: {
      service: null,
      createFn: 'addDevice',
      readFn: 'getDevices',
      updateFn: 'updateDevice',
      deleteFn: 'deleteDevice',
      entityObservable: 'devices$',
      entityData: 'devices',
    },
    Tocs: {
      service: null,
      createFn: 'addToc',
      readFn: 'getTocs',
      updateFn: 'updateToc',
      deleteFn: 'deleteToc',
      entityObservable: 'tocs$',
    },
  };

  constructor(
    public api: ApiService,
    public relationshipService: RelationshipService,
    public notificationService: NotificationService, // private confirmationService: ConfirmationService
  ) {
    this.entities = [];
  }

  deleteEntity(entityKey: string, id: any) {
    const entityServiceName = this.entityMap[entityKey];
    if (entityServiceName) {
      const { service, deleteFn } = this.entityServices[entityServiceName];
      if (service && deleteFn && service[deleteFn]) {
        // todo: improve this logic, only shows success message when actually been deleted
        setTimeout(() => {
          this.notificationService.successfullyModifiedEntity(
            'delete',
            entityServiceName,
          );
          this.refreshEntities(entityKey);
        }, 3000);

        const thisQuery$ = service[deleteFn](id);

        thisQuery$.subscribe(
          (res: any) => {},
          (error: any) => {
            this.notificationService.errorModifyingEntity(
              'delete',
              entityServiceName,
            );
          },
        );
      }
    }
  }

  deleteAllEntities(entityKey: any) {
    // todo: use the originalFormData to determine if the related entities have changed and to act accordingly
    this.entityQuery = this.api
      .deleteAllAPI<any>(entityKey)
      .subscribe((res) => {});
  }

  getEntities(activeTabKey: string) {
    const entityServiceName = this.entityMap[activeTabKey];
    const { service, readFn, entityObservable, entityData } =
      this.entityServices[entityServiceName];

    const queryParams = {
      limit: this.metaDataEntityMap[activeTabKey].rows,
      offset: this.metaDataEntityMap[activeTabKey].first,
      q: this.metaDataEntityMap[activeTabKey].globalFilter,
      sort: this.metaDataEntityMap[activeTabKey].sort,
      filter: this.metaDataEntityMap[activeTabKey].filter,
    };

    service[readFn](queryParams)
      .pipe(
        map((res: any) => {
          // console.log(`${activeTabKey} mapping result`, res);

          this.metaDataEntityMap[activeTabKey].totalRecords = res.count;

          return res.data;
        }),
      )
      .subscribe((data: any) => {
        // service.devices = data;
        if (
          service[entityObservable || 'device'] &&
          typeof service[entityObservable || 'device']?.next === 'function'
        ) {
          service[entityData || 'device'] = data;
          service[entityObservable || 'device'].next(data);
        } else {
          console.error(
            `Observable not found or not a function for entity: ${entityServiceName}`,
          );
        }
        this.metaDataEntityMap[activeTabKey].loading = false;
      });
  }

  addEntity(activeTabKey: string, value: any) {
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, createFn } = this.entityServices[entityServiceName];
      if (service && createFn && service[createFn]) {
        // call the function

        setTimeout(() => {
          this.notificationService.successfullyModifiedEntity(
            'create',
            entityServiceName,
          );
          this.refreshEntities(activeTabKey);
        }, 3000);

        let thisQuery$ = service[createFn](value);
        thisQuery$.subscribe(
          (res: any) => {},
          (error: any) => {
            //console.log('error', error);
            this.notificationService.errorModifyingEntity(
              'update',
              entityServiceName,
            );
          },
        );
      } else {
        console.log(
          `Create function not found for entity: ${entityServiceName}`,
        );
      }
    } else {
      //console.log(`Entity not found for key: ${activeTabKey}`);
    }
  }

  updateEntity(
    activeTabKey: string,
    id: number,
    value: any,
    originalFormData: any,
  ) {
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, updateFn } = this.entityServices[entityServiceName];
      if (service && updateFn && service[updateFn]) {
        // call the function

        // console.log('value - form values', value);
        // console.log('originalFormData - form values', originalFormData);

        const thisQuery$ = service[updateFn](id, value);
        thisQuery$.subscribe(
          (res: any) => {
            // todo: use the originalFormData to determine if the related entities have changed and to act accordingly
            // todo: add errors
            // todo: add success message

            this.notificationService.successfullyModifiedEntity(
              'update',
              entityServiceName,
            );
            this.refreshEntities(activeTabKey);
          },
          (error: any) => {
            //console.log('error', error);
            this.notificationService.errorModifyingEntity(
              'update',
              entityServiceName,
            );
          },
        );
      } else {
        console.log(
          `Update function not found for entity: ${entityServiceName}`,
        );
      }
    } else {
      //console.log(`Entity not found for key: ${activeTabKey}`);
    }
  }

  getEntityObservable(activeTabKey: string) {
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, entityObservable } =
        this.entityServices[entityServiceName];
      if (
        service &&
        entityObservable &&
        service.hasOwnProperty(entityObservable)
      ) {
        return service[entityObservable];
      } else {
        console.log(
          `Observable data subject not found for entity: ${entityServiceName}`,
        );
      }
    }
  }

  getEntityData(activeTabKey: string) {
    // fixme: update to call entire list or most of list
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, entityData } = this.entityServices[entityServiceName];
      if (service && entityData && service.hasOwnProperty(entityData)) {
        return service[entityData];
      } else {
        //console.log(`Entity data not found for entity: ${entityServiceName}`);
      }
    }
  }

  deleteSelectedRowCM(activeTabKey: string, selectedRowCMId: any) {
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, deleteFn } = this.entityServices[entityServiceName];
      if (service && deleteFn && service[deleteFn]) {
        setTimeout(() => {
          this.notificationService.successfullyModifiedEntity(
            'delete',
            entityServiceName,
          );
          this.refreshEntities(activeTabKey);
        }, 3000);
        // run delete function for entity
        service[deleteFn](selectedRowCMId);
      } else {
        console.log(
          `Delete function not found for entity: ${entityServiceName}`,
        );
      }
    } else {
      //console.log(`Entity not found for key: ${activeTabKey}`);
    }
  }

  editSelectedRowCM(key: any, id: any) {
    throw new Error('Method not implemented.');
  }

  refreshEntities(activeTabKey: string) {
    const entityServiceName = this.entityMap[activeTabKey];
    if (entityServiceName) {
      const { service, readFn, entityObservable, entityData } =
        this.entityServices[entityServiceName];
      if (service && readFn && service[readFn]) {
        const queryParams = {
          limit: this.metaDataEntityMap[activeTabKey].rows,
          offset: this.metaDataEntityMap[activeTabKey].first,
          q: this.metaDataEntityMap[activeTabKey].globalFilter,
          sort: this.metaDataEntityMap[activeTabKey].sort,
          filter: this.metaDataEntityMap[activeTabKey].filter,
        };

        // console.log(`${activeTabKey} queryParams`, queryParams);

        service[readFn](queryParams)
          .pipe(
            map((res: any) => {
              // console.log(`${activeTabKey} mapping result`, res);

              this.metaDataEntityMap[activeTabKey].totalRecords = res.count;

              return res.data;
            }),
          )
          .subscribe((data: any) => {
            if (
              service[entityObservable || 'device'] &&
              typeof service[entityObservable || 'device']?.next === 'function'
            ) {
              service[entityData || 'device'] = data;
              service[entityObservable || 'device'].next(data);
            } else {
              console.error(
                `Observable not found or not a function for entity: ${entityServiceName}`,
              );
            }
            this.metaDataEntityMap[activeTabKey].loading = false;
          });
      } else {
        console.log(`Read function not found for entity: ${entityServiceName}`);
      }
    } else {
      console.log(`Entity not found for key: ${activeTabKey}`);
    }
  }
}
