<div class="flex">
  <div class="flex-1">
    <h1 class="text-xl font-bold">Activate your Wi-Fi access</h1>
  </div>
</div>
<form [formGroup]="portalAuthForm" (ngSubmit)="onAuthSubmit()">
  <div class="formgrid grid p-fluid">
    <div class="field col-12 md:col-6">
      <label for="first_name">Username</label>
      <input
        pInputText
        id="username"
        formControlName="username"
        type="text"
        class="w-full p-inputtext-lg"
        (blur)="portalAuthForm.controls['username'].markAsDirty()"
      />
      <small
        *ngIf="
          portalAuthForm.controls['username'].hasError('required') &&
          portalAuthForm.controls['username'].touched &&
          portalAuthForm.controls['username'].invalid
        "
        id="username-help"
        class="p-error"
        >This field is required</small
      >
    </div>
    <div class="field col-12 md:col-6">
      <label for="password">Password</label>
      <p-password
        formControlName="password"
        id="password"
        [feedback]="false"
        [toggleMask]="true"
        class="w-full p-inputtext-lg"
        (onBlur)="portalAuthForm.controls['password'].markAsDirty()"
      ></p-password>
      <small
        *ngIf="
          portalAuthForm.controls['password'].hasError('required') &&
          portalAuthForm.controls['password'].touched &&
          portalAuthForm.controls['password'].invalid
        "
        id="password-help"
        class="p-error"
        >This field is required</small
      >
    </div>
    <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
    <div class="field col-12 md:col-6">
      <button
        pButton
        type="submit"
        class="p-button-success p-button p-button-lg"
        label="Submit"
      ></button>
    </div>
  </div>
</form>
