import { Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { ApiService } from '../../../../_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class IspCircuitsService {
  ispCircuitsQuery$: any;

  ispCircuits: any[] = [];
  ispCircuits$: Subject<any[]> = new Subject<any[]>();

  constructor(public api: ApiService) {}

  getIspCircuits(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<any[]>('ispcircuit', queryParams).pipe(
      map((res) => {
        return res.body;
      }),
    );
  }

  addIspCircuit(value: any) {
    const new_value = {
      ...value,
    };
    this.ispCircuitsQuery$ = this.api.postAPI<any>('ispcircuit', new_value);
    return this.ispCircuitsQuery$;
  }

  updateIspCircuit(id: any, value: any) {
    const new_value = {
      ...value,
    };
    this.ispCircuitsQuery$ = this.api.putAPI<any>('ispcircuit', id, new_value);
    return this.ispCircuitsQuery$;
  }

  deleteIspCircuit(id: any) {
    this.api.deleteAPI<any>('ispcircuit', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
