<div class="w-full m-0 p-0 surface-border">
  <form
    [formGroup]="this.dynamicForm"
    #localForm="ngForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="grid" style="display: flex; flex-direction: column">
      <div class="col-12 lg:col-12">
        <div class="flex justify-content-center align-items-center">
          <h1>Update Customer</h1>
        </div>
        <!-- Name -->

        <div class="grid formgrid p-fluid pt-4">
          <div class="w-full m-1">
            <div class="grid formgrid p-fluid pt-4">
              <div class="field col-12 md:col-12">
                <p-divider><h4>Customer Details</h4></p-divider>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="first_name"
                  pInputText
                  placeholder="First Name"
                  formControlName="first_name"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    (localForm.submitted &&
                      dynamicForm.get('first_name')?.invalid) ||
                    dynamicForm.get('first_name')?.dirty
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>First Name</strong> is required</small
                  >
                </div>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="identification_reference"
                  placeholder="Identification Reference"
                  pInputText
                  formControlName="identification_reference"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('identification_reference')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('identification_reference')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Identification</strong> is required</small
                  >
                </div>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="last_name"
                  pInputText
                  placeholder="Last Name"
                  formControlName="last_name"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    (localForm.submitted &&
                      dynamicForm.get('last_name')?.invalid) ||
                    dynamicForm.get('last_name')?.dirty
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Last Name</strong> is required</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full m-1">
            <div class="grid formgrid p-fluid pt-4">
              <div class="field col-12 md:col-12">
                <p-divider><h4>Customer Indentity</h4></p-divider>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="identification_type"
                  pInputText
                  placeholder="Identification Type"
                  formControlName="identification_type"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('identification_type')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('identification_type')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Identification Type</strong> is required</small
                  >
                </div>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="mobile_number"
                  pInputText
                  placeholder="Mobile Number"
                  formControlName="mobile_number"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('mobile_number')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('mobile_number')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Mobile Number</strong> is required</small
                  >
                </div>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="email"
                  pInputText
                  placeholder="Email"
                  formControlName="email"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('email')?.invalid &&
                    (localForm.submitted || dynamicForm.get('email')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Email</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="alternate_contact_number"
                  pInputText
                  placeholder="Alternate Contact Number"
                  formControlName="alternate_contact_number"
                />
              </div>
            </div>
          </div>

          <div class="w-full m-1">
            <div class="grid formgrid p-fluid pt-4">
              <div class="field col-12 md:col-12">
                <p-divider><h4>Customer Address</h4></p-divider>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="complex_building"
                  pInputText
                  placeholder="Complex Building"
                  formControlName="complex_building"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('complex_building')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('complex_building')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Complex Building</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="unit_number"
                  pInputText
                  placeholder="Unit Number"
                  formControlName="unit_number"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('unit_number')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('unit_number')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Unit Number</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="street_address"
                  pInputText
                  placeholder="Street Address"
                  formControlName="street_address"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('street_address')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('street_address')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Street Address</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="postal_address"
                  pInputText
                  placeholder="Postal Address"
                  formControlName="postal_address"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('postal_address')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('postal_address')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Postal Address</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="suburb"
                  pInputText
                  placeholder="Suburb"
                  formControlName="suburb"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('suburb')?.invalid &&
                    (localForm.submitted || dynamicForm.get('suburb')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Suburb</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="city"
                  pInputText
                  placeholder="City"
                  formControlName="city"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('city')?.invalid &&
                    (localForm.submitted || dynamicForm.get('city')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>City</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="province"
                  pInputText
                  placeholder="Province"
                  formControlName="province"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('province')?.invalid &&
                    (localForm.submitted || dynamicForm.get('province')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Province</strong> is required</small
                  >
                </div>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <input
                  class="w-full"
                  type="text"
                  id="postal_code"
                  pInputText
                  placeholder="Postal Code"
                  formControlName="postal_code"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    dynamicForm.get('postal_code')?.invalid &&
                    (localForm.submitted ||
                      dynamicForm.get('postal_code')?.dirty)
                  "
                >
                  <small id="first_name-help" class="p-error block"
                    ><strong>Postal Code</strong> is required</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex align-items-end justify-content-end px-1 py-1 mr-4"
      *ngIf="
        dynamicForm?.invalid && (localForm.submitted || dynamicForm?.dirty)
      "
    >
      <small id="first_name-help" class="p-error block"
        >Please check that all fields have been filled</small
      >
    </div>
    <div
      class="flex align-items-end justify-content-end bottom-0 left-0 m-0 w-full p-4 bg-gray-200"
    >
      <button
        pButton
        pRipple
        class="mr-3 py-3 px-6 p-button-danger"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button pButton type="submit" class="py-3 px-6">Update</button>
    </div>
  </form>
</div>
