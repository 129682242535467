import { Injectable } from '@angular/core';
import { EquipmentType } from '../../../../_share/_models/entities/schema';
import { ApiService } from '../../../../_core/_services/api.service';
import { map, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentTypesService {
  equipmentTypes$: Subject<EquipmentType[]> = new Subject<EquipmentType[]>();
  equipmentTypes: any[];
  equipmentTypesQuery$: any;

  constructor(public api: ApiService) {
    this.equipmentTypes = [];
  }

  getEquipmentTypeById(id: any) {
    return this.api.getAPI<EquipmentType>(`devices/get/equipment/${id}`);
  }

  getEquipmentTypes(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<EquipmentType>('equipmenttype', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addEquipmentType(value: EquipmentType) {
    const new_value = { ...value };
    this.equipmentTypesQuery$ = this.api.postAPI<EquipmentType>(
      'equipmenttype',
      new_value
    );
    return this.equipmentTypesQuery$;
  }

  updateEquipmentType(id: any, value: any) {
    const new_value = { ...value };
    this.equipmentTypesQuery$ = this.api.putAPI<EquipmentType>(
      'equipmenttype',
      id,
      new_value
    );
    return this.equipmentTypesQuery$;
  }

  deleteEquipmentType(id: any) {
    this.equipmentTypesQuery$ = this.api
      .deleteAPI<EquipmentType>('equipmenttype', id)
      .subscribe((res) => {
        //console.log('res', res);
      });
  }
}
