import { Component, OnInit } from '@angular/core';
import { PortalPackagesService } from 'src/app/features/portal/_services/portal-packages.service';
import { PortalProductsCreate } from 'src/app/_share/_models/portal/interfaces';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-create-package-dialog',
  templateUrl: './create-package-dialog.component.html',
  styleUrls: ['./create-package-dialog.component.scss'],
})
export class CreatePackageDialogComponent implements OnInit {
  product_types: any[] = ['Fixed', 'Hotspot'];
  selectedProduct: any;

  dynamicForm: any = this.fb.group(
    {
      product_type: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      recurring_price_excl: ['', Validators.required],
      recurring_price_incl: ['', Validators.required],
      recurring_description: ['', Validators.required],
      speed_down_mbps: ['', Validators.required],
      speed_up_mbps: ['', Validators.required],
      currency: ['', Validators.required],
      onceoff_price_excl: ['', Validators.required],
      onceoff_price_incl: ['', Validators.required],
      onceoff_description: ['', Validators.required],
      renewal_type: ['', Validators.required],
      duration_days: ['', Validators.required],
    },
    { updateOn: 'submit' }
  );

  constructor(
    public portalPackagesService: PortalPackagesService,
    private fb: FormBuilder,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.dynamicForm.invalid) {
      console.log('invalid form');
      return;
    }

    console.log(this.dynamicForm.value);
    this.portalPackagesService
      .createPortalPackage(this.dynamicForm.value)
      .subscribe((res) => {
        console.log(res);
      });
    this.ref.close();
  }

  onCancel() {
    this.ref.close();
  }
}
