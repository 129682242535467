import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  testToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully tested a toast message',
    });
  }

  mainSuccess(message: string) {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }

  mainError(message: string) {
    this.messageService.add({
      key: 'main',
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }

  successfullyModifiedEntity(action: string, entity: string) {
    let summary = '';
    let severity = '';
    if (action == 'create') {
      summary = 'Successfully added a record to ' + entity;
      severity = 'success';
    } else if (action == 'update') {
      summary = 'Successfully updated a record of ' + entity;
      severity = 'success';
    } else if (action == 'delete') {
      summary = 'Successfully deleted a record of ' + entity;
      severity = 'success';
    }

    this.messageService.add({
      key: 'main',
      severity: 'success',
      summary: 'Success',
      detail: summary,
    });
  }

  errorModifyingEntity(action: string, entity: string) {
    let summary = '';
    let severity = '';

    if (action == 'create') {
      summary = 'Failed to add a record to ' + entity;
      severity = 'error';
    }
    if (action == 'update') {
      summary = 'Failed to update a record in ' + entity;
      severity = 'error';
    }
    if (action == 'delete') {
      summary = 'Failed to delete a record of ' + entity;
      severity = 'error';
    }

    this.messageService.add({
      key: 'main',
      severity: severity,
      summary: 'Error',
      detail: summary,
    });
  }

  successfulQuotation() {
    this.messageService.add({
      key: 'business',
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully submitted a new quotation request',
    });
  }

  failedQuotation() {
    this.messageService.add({
      key: 'business',
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to submit a new quotation request',
    });
  }

  invalidFields() {
    this.messageService.add({
      key: 'business',
      severity: 'error',
      summary: 'Error',
      detail: 'Please fill in all required fields',
    });
  }

  successfullPortal() {
    this.messageService.add({
      key: 'portal',
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully created a new portal',
    });
  }

  failedPortal() {
    this.messageService.add({
      key: 'portal',
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to create a new portal',
    });
  }

  successfulCredentialCopy() {
    this.messageService.add({
      key: 'portal',
      severity: 'success',
      summary: 'Copied Credentials',
      detail: 'Successfully copied credentials to the clipboard',
    });
  }
}
