import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LeadsService } from 'src/app/features/crm/_services/leads.service';

@Component({
  selector: 'app-crm-leads-dashboard',
  templateUrl: './crm-leads-dashboard.component.html',
  styleUrls: ['./crm-leads-dashboard.component.scss'],
})
export class CrmLeadsDashboardComponent implements OnInit {
  leads: any[] = [];
  leads$: Subject<any[]> = new Subject<any[]>();
  totalAmount: number = 200;
  pageLimit: number = 100;
  pageOffset: number = 0;

  isLeadsLoading: boolean = false;

  columns: any[] = [
    {
      header: 'Company Name',
      fields: ['company.name'],
      filtering: false,
    },
    {
      header: 'Company Phone',
      fields: ['company.phone'],
      filtering: false,
    },
    {
      header: 'Company Address',
      fields: ['company.address'],
      filtering: false,
    },
    {
      header: 'Lead Owner',
      fields: ['lead_owner.first_name', 'lead_owner.last_name'],
      filtering: false,
    },
    {
      header: 'Lead Status',
      fields: ['lead_status'],
      filtering: false,
    },
    {
      header: 'Last Action',
      fields: ['actions'],
      filtering: false,
    },
  ];
  virtualLeads: any;

  constructor(private leadsService: LeadsService) {}

  ngOnInit(): void {
    this.isLeadsLoading = true;
    this.leadsService
      .getAllLeads(this.pageOffset, this.pageLimit)
      .subscribe((res) => {
        console.log('res - leads', res);
        this.leads = res.results;
        this.totalAmount = res.count;
        this.leads$.next(this.leads);
        this.isLeadsLoading = false;
        this.virtualLeads = Array.from({ length: this.totalAmount });
      });
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  resolveNestedField(object: any, field: string): any {
    return field
      .split('.')
      .reduce((acc, curr) => (acc ? acc[curr] : null), object);
  }

  resolveCombinedFields(object: any, fields: string[]): string {
    if (!object) return '';

    const resolvedValues = fields.map((field) => {
      const nestedFieldNames = field.split('.');
      let value = object;

      for (const nestedFieldName of nestedFieldNames) {
        value = value ? value[nestedFieldName] : '';
      }

      return value;
    });

    return resolvedValues.join(' '); // You can customize the separator if needed
  }

  getInputValue(e: Event): any {
    const inputelement = e.target as HTMLInputElement;
    return inputelement.value;
  }

  loadLeadsLazy($event: any) {
    console.log('load leads lazy', $event);
    const first = $event.first;
    // todo: store state of table using primeng component feature
    const rows = $event.rows;
    const last = $event.last;

    const filters = $event.filters;

    this.pageLimit = 100;
    this.pageOffset = 0;

    if (filters.global) {
      this.leadsService
        .getAllLeads(this.pageOffset, this.pageLimit, filters.global.value)
        .subscribe((res) => {
          console.log('res - leads', res);
          this.totalAmount = res.count;
          this.pageOffset = 0;
          // this.virtualLeads = Array.from({ length: this.totalAmount });
          // this.virtualLeads.splice(first, rows, ...res.results);
          this.leads$.next(res.results);
          this.isLeadsLoading = false;
        });
    } else {
      this.leadsService.getAllLeads(first, rows).subscribe((res) => {
        console.log('res - leads', res);
        this.totalAmount = res.count;
        this.virtualLeads = Array.from({ length: this.totalAmount });
        this.virtualLeads.splice(first, rows, ...res.results);
        this.leads$.next(this.virtualLeads);
        this.isLeadsLoading = false;
      });
    }
  }

  getSeverityFromStatus(status: string) {
    // todo: update to show actions which correspond with resultant statuses
    switch (status) {
      case 'not-interested':
        return 'error';
      case 'appointment-made-key-individual':
        return 'help';
      case 'note':
        return '';
      case 'call-back-convenience':
        return 'danger';
      default:
        return 'danger';
    }
  }
}
