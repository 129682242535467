<div class="flex-1 overflow-y-auto p-2 overflow-x-hidden">
  <div class="surface-section px-2 py-4 md:px-3 lg:px-4">
    <div *ngIf="(this.bss.loading$ | async) || false" class="text-center">
      <p-progressBar
        class="text-center"
        mode="indeterminate"
        [style]="{ height: '6px' }"
      ></p-progressBar>
    </div>
    <div
      *ngIf="
        ((this.bss.loading$ | async) == false &&
          (this.bss.submitted$ | async)) ||
        false
      "
      class="border-1 border-blue-600 border-round p-4"
    >
      <span class="text-700 text-xl">Thanks!</span>
      <div class="text-900 font-bold text-4xl my-2">Successful Quote 🚀</div>
      <p class="text-700 text-xl mt-0 mb-4 p-0">
        <!-- Your order is on the way. It'll be shipped today. We'll inform you. -->
      </p>
      <div
        style="
          height: 3px;
          background: linear-gradient(
            90deg,
            #2196f3 0%,
            rgba(33, 150, 243, 0) 50%
          );
        "
      ></div>
      <div
        class="flex flex-column sm:align-items-start sm:justify-content-between py-5"
      >
        <div *ngIf="false" class="mb-3 sm:mb-0 block">
          <span class="font-medium text-xl text-900 mr-2">Account number:</span>
          <span class="font-medium text-xl text-blue-500">A1234</span>
        </div>
        <div *ngIf="false" class="mb-3 sm:mb-0 block">
          <span class="font-medium text-xl text-900 mr-2">Quote number:</span>
          <span class="font-medium text-xl text-blue-500">Q123456</span>
        </div>
        <div class="mt-4">
          <button
            pButton
            pRipple
            label="Start New Quote"
            icon="pi pi-plus"
            class="p-button-outlined p-button-success p-button-lg"
            (click)="resetAndNavigate()"
          ></button>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        ((this.bss.loading$ | async) == false &&
          (this.bss.submitted$ | async) === false) ||
        false
      "
      class="flex flex-wrap mt-5 pb-3 w-full"
    >
      <div class="w-full">
        <p class="text-700 text-xl p-3 border-1 border-round border-blue-600">
          Please review the details below and confirm that they are correct. If
          you need to make any changes, please click the 'Previous' button to go
          back and make the necessary changes.
        </p>
        <!-- Business Name -->
        <span class="font-medium text-900 text-xl">Business Name</span>
        <ul class="list-none p-0 m-0 border-top-1 border-300">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-900 w-full">
              {{ bss.businessDetailsForm.get("businessName")?.value }}
            </div>
          </li>
        </ul>

        <!-- Business Telephone -->
        <span class="font-medium text-900 text-xl">Business Telephone</span>
        <ul class="list-none p-0 m-0 border-top-1 border-300">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-900 w-full">
              {{ bss.businessDetailsForm.get("businessTelephone")?.value }}
            </div>
          </li>
        </ul>

        <!-- Business Email -->
        <span class="font-medium text-900 text-xl">Business Email</span>
        <ul class="list-none p-0 m-0 border-top-1 border-300">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-900 w-full">
              {{ bss.businessDetailsForm.get("businessEmail")?.value }}
            </div>
          </li>
        </ul>

        <!-- Business Details -->
        <span class="font-medium text-900 text-xl">Business Address</span>
        <ul class="list-none p-0 m-0 border-top-1 border-300">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Address Line 1</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("businessDetails.addressLine1")
                  ?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Address Line 2</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("businessDetails.addressLine2")
                  ?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Suburb</div>
            <div class="text-900 w-full md:w-8">
              {{ bss.businessDetailsForm.get("businessDetails.suburb")?.value }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">City</div>
            <div class="text-900 w-full md:w-8">
              {{ bss.businessDetailsForm.get("businessDetails.city")?.value }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Province</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("businessDetails.province")?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Postal Code</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("businessDetails.postalCode")?.value
              }}
            </div>
          </li>
        </ul>

        <!-- Primary Contact -->
        <span class="font-medium text-900 text-xl">Primary Contact</span>
        <ul class="list-none p-0 m-0 border-top-1 border-300">
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Title</div>
            <div class="text-900 w-full md:w-8">
              {{ bss.businessDetailsForm.get("primaryContact.title")?.value }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">First Name</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("primaryContact.firstName")?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Last Name</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("primaryContact.lastName")?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Job Title</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("primaryContact.jobTitle")?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Telephone</div>
            <div class="text-900 w-full md:w-8">
              {{
                bss.businessDetailsForm.get("primaryContact.telephone")?.value
              }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full md:w-4 font-medium">Email</div>
            <div class="text-900 w-full md:w-8">
              {{ bss.businessDetailsForm.get("primaryContact.email")?.value }}
            </div>
          </li>
        </ul>

        <!-- Product -->
        <span class="font-medium text-900 text-xl mb-4">Selected Product</span>
        <ng-container
          *ngIf="
            bss.businessDetailsForm.get('product')?.value as selectedProduct
          "
        >
          <div class="w-full border-1 border-round border-blue-300">
            <div class="align-items-center surface-border grid p-3">
              <span
                class="text-xl text-900 font-medium mb-2 text-center col-12"
                >{{ selectedProduct.description }}</span
              >

              <div class="col-6 text-center">
                <span class="text-blue-500 font-medium text-center text-md"
                  ><i class="pi pi-arrow-down"> </i>
                  {{ selectedProduct.speed_download_mbps }} Mbps</span
                >
                <br />
                <span class="text-600 font-medium text-center">Download </span>
              </div>

              <div class="col-6 text-center">
                <span class="text-green-500 font-medium text-center text-lg"
                  ><i class="pi pi-arrow-up"> </i
                  >{{ selectedProduct.speed_upload_mbps }} Mbps
                </span>
                <br />
                <span class="text-600 font-medium text-center">Upload </span>
                <span></span>
              </div>
              <span
                class="text-2xl text-800 block font-semibold text-center col-12 mb-2"
                >{{
                  selectedProduct.recurring_amount_excl
                    | currency: "ZAR" : "symbol-narrow"
                }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
