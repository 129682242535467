import { Injectable } from '@angular/core';
import { ApiService } from '../../../../_core/_services/api.service';
import { Vlan } from '../../../../_share/_models/entities/schema';
import { BehaviorSubject, map, Observable, Subject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VlansService {
  vlansQuery$: any;
  vlans: Vlan[] = [];
  vlans$: BehaviorSubject<Vlan[]> = new BehaviorSubject<Vlan[]>([]);

  constructor(public api: ApiService) {
    this.vlans = [];
  }
  vlansLoading = false;

  // getAllVlans(): Observable<Vlan[]> {
  //   return this.api.getAPI<Vlan>('vlan');
  // }

  getVlans(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Vlan>('vlan', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addVlan(value: Vlan) {
    const new_value = { ...value };
    console.log('new_value', new_value);

    this.vlansQuery$ = this.api.postAPI<Vlan>('vlan', new_value);
    return this.vlansQuery$;
  }

  updateVlan(id: any, value: any) {
    const new_value = { ...value };
    console.log('VLAN to update', new_value);
    this.vlansQuery$ = this.api.putAPI<Vlan>('vlan', id, new_value);
    return this.vlansQuery$;
  }

  deleteVlan(id: any) {
    this.vlansQuery$ = this.api.deleteAPI<Vlan>('vlan', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
