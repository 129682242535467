<p-toast key="business-sales"></p-toast>
<p-sidebar [(visible)]="showFaq" styleClass="p-sidebar-lg">
  <app-business-sales-script></app-business-sales-script
></p-sidebar>
<div class="flex flex-column min-h-full max-h-full h-full">
  <div class="flex flex-row flex-grow-0">
    <div
      class="border-blue-600 font-medium text-2xl p-3 mb-1 text-900 flex-1"
      style="border-left: 8px solid"
    >
      Business Sales
    </div>

    <button
      pButton
      label="Show FAQ"
      (click)="showFaqPanel()"
      class="mb-1 justify-content-end p-button-info"
      icon="pi pi-question"
      iconPos="right"
    ></button>
  </div>

  <div class="flex-grow-1 overflow-y-hidden max-h-full h-full flex flex-column">
    <p-steps
      [model]="steps"
      [readonly]="true"
      [(activeIndex)]="bss.activeStepIndex"
    ></p-steps>
    <div class="flex-1 overflow-y-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="flex flex-row justify-content-end">
    <button
      *ngIf="hasPrev()"
      pButton
      [disabled]="!hasPrev()"
      label="Previous"
      iconPos="left"
      icon="pi pi-angle-left"
      class="p-button-lg mx-1"
      (click)="prevPage()"
    ></button>
    <button
      *ngIf="hasNext()"
      pButton
      [disabled]="!hasNext() || canSubmit()"
      label="Next"
      iconPos="right"
      icon="pi pi-angle-right"
      class="p-button-lg mx-1"
      (click)="nextPage()"
    ></button>
    <button
      *ngIf="canSubmit()"
      pButton
      label="Submit Sales Request"
      iconPos="right"
      icon="pi pi-angle-up"
      class="p-button-lg p-button-success mx-1"
      (click)="submitBusinessSale()"
      [loading]="(this.bss.loading$ | async) || false"
    ></button>
  </div>
</div>
<!-- end of outline -->
