<div *ngIf="userService.currentUser$ | async; let currentUserProfile">
  <ul class="list-none p-0 m-0">
    <li class="pb-2 border-bottom-1 surface-border">
      <div>
        <span class="text-600 font-bold">First Name:</span>
        {{ currentUserProfile.first_name }}
      </div>
    </li>
    <li class="py-2 border-bottom-1 surface-border">
      <div>
        <span class="text-600 font-bold">Last Name:</span>
        {{ currentUserProfile.last_name }}
      </div>
    </li>
    <li class="py-2 border-bottom-1 surface-border">
      <div>
        <span class="text-600 font-bold">Username:</span>
        {{ currentUserProfile.username }}
      </div>
    </li>
    <li class="pt-2">
      <div>
        <span class="text-600 font-bold">Email:</span>
        {{ currentUserProfile.email }}
      </div>
    </li>
  </ul>
  <div class="py-2">
    <button
      pButton
      (click)="openEditUserProfileDialog()"
      label="Edit"
      disabled
    ></button>
    &nbsp;
    <button
      pButton
      (click)="openEditUserProfileDialog()"
      label="Change Password"
      disabled
    ></button>
  </div>
</div>
