import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, Validators } from '@angular/forms';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.scss'],
})
export class UpdateCustomerComponent implements OnInit {
  dynamicForm: any = this.fb.group(
    {
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      identification_reference: ['', Validators.required],
      identification_type: ['', Validators.required],
      mobile_number: ['', Validators.required],
      email: ['', Validators.required],
      alternate_contact_number: [''],
      complex_building: ['', Validators.required],
      unit_number: ['', Validators.required],
      street_address: ['', Validators.required],
      postal_address: ['', Validators.required],
      suburb: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      postal_code: ['', Validators.required],
    },
    { updateOn: 'submit' }
  );

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private portalUsersService: PortalUsersService
  ) {}

  ngOnInit(): void {
    const user = this.config.data.user;
    this.dynamicForm.patchValue(user);
  }

  onSubmit() {
    if (this.dynamicForm.invalid) {
      console.log('invalid form');
      return;
    }
    this.portalUsersService.updatePortalUser(
      this.config.data.user.id,
      this.dynamicForm.value
    );
    this.ref.close();
  }

  onCancel() {
    this.ref.close();
  }
}
