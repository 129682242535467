<!-- <div
  class="justify-content-center align-items-center text-center h-full min-h-full flex flex-column"
>
  <h2>Check back soon!</h2>
</div> -->

<div class="flex flex-column h-full min-h-full">
  <div class="grid nested-grid overflow-y-scroll">
    <div class="col-12 md:col-4">
      <div class="grid h-full min-h-full">
        <div class="col-12">
          <app-user-account-profile></app-user-account-profile>
        </div>
      </div>
    </div>

    <div class="col-8" *ngIf="false">
      <div class="grid">
        <div class="col-6">
          <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Orders</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl"
                >152 New</span
              >
              <div class="flex align-items-center">
                <i class="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
                <span class="text-pink-500 font-medium">-25</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Revenue</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">$1500</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+15</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-cyan-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Customers</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">25100</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+%12</span>
              </div>
            </div>
            <div class="flex-auto p-3">
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Comments</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">72</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+20</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-blue-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Orders</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl"
                >152 New</span
              >
              <div class="flex align-items-center">
                <i class="pi pi-arrow-down text-pink-500 text-xl mr-2"></i>
                <span class="text-pink-500 font-medium">-25</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-shopping-cart text-orange-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Revenue</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">$1500</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+15</span>
              </div>
            </div>
            <div
              class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3"
            >
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-cyan-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Customers</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">25100</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+%12</span>
              </div>
            </div>
            <div class="flex-auto p-3">
              <div class="flex align-items-center mb-3">
                <i class="pi pi-users text-purple-500 text-xl mr-2"></i>
                <span class="text-500 font-medium">Comments</span>
              </div>
              <span class="block text-900 font-medium mb-4 text-xl">72</span>
              <div class="flex align-items-center">
                <i class="pi pi-arrow-up text-green-500 text-xl mr-2"></i>
                <span class="text-green-500 font-medium">+20</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="surface-card shadow-2 p-4 h-full">
            <div class="flex align-items-center justify-content-between mb-4">
              <div class="text-900 font-medium text-xl">Notifications</div>
              <div>
                <!-- <button
                      pButton
                      pRipple
                      icon="pi pi-ellipsis-v"
                      class="p-button-text p-button-plain p-button-rounded"
                      (click)="menu2.toggle($event)"
                    ></button>
                    <p-menu #menu2 [popup]="true" [model]="items"></p-menu> -->
              </div>
            </div>

            <span class="block text-600 font-medium mb-3">TODAY</span>
            <ul class="p-0 mx-0 mt-0 mb-4 list-none">
              <li
                class="flex align-items-center py-2 border-bottom-1 surface-border"
              >
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-dollar text-xl text-blue-500"></i>
                </div>
                <span class="text-900 line-height-3 font-medium"
                  >Richard Jones
                  <span class="text-700 font-normal"
                    >has purchased a blue t-shirt for
                    <span class="text-primary font-medium">$79</span></span
                  >
                </span>
              </li>
              <li class="flex align-items-center py-2">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-download text-xl text-orange-500"></i>
                </div>
                <span class="text-700 line-height-3"
                  >Your request for withdrawal of
                  <span class="text-primary font-medium">$2500</span> has been
                  initiated.</span
                >
              </li>
            </ul>

            <span class="block text-600 font-medium mb-3">YESTERDAY</span>
            <ul class="p-0 m-0 list-none">
              <li
                class="flex align-items-center py-2 border-bottom-1 surface-border"
              >
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-dollar text-xl text-blue-500"></i>
                </div>
                <span class="text-900 line-height-3 font-medium"
                  >Keyser Wick
                  <span class="text-700 font-normal"
                    >has purchased a black jacket for
                    <span class="text-primary font-medium">$59</span></span
                  >
                </span>
              </li>
              <li
                class="flex align-items-center py-2 border-bottom-1 surface-border"
              >
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-question text-xl text-pink-500"></i>
                </div>
                <span class="text-900 line-height-3 font-medium"
                  >Jane Davis
                  <span class="text-700 font-normal"
                    >has posted a new questions about your product.</span
                  >
                </span>
              </li>
              <li class="flex align-items-center py-2">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-arrow-up text-xl text-green-500"></i>
                </div>
                <span class="text-900 line-height-3 font-medium"
                  >Claire Smith
                  <span class="text-700 font-normal"
                    >has upvoted your store along with a comment.</span
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6">
          <div class="surface-card shadow-2 p-4 h-full">
            <div class="flex align-items-center justify-content-between mb-3">
              <div class="text-900 font-medium text-xl">Team Activity</div>
              <div>
                <!-- <button
                    pButton
                    pRipple
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-plain p-button-rounded"
                    (click)="menu1.toggle($event)"
                  ></button> -->
                <!-- <p-menu #menu1 [popup]="true" [model]="items"></p-menu> -->
              </div>
            </div>
            <ul class="list-none p-0 m-0">
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-1.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Jane Cooper</span
                    >
                    <div class="text-700 mb-2">responded to an issue.</div>
                    <a class="text-primary cursor-pointer">
                      <i class="pi pi-github text-sm mr-2"></i>
                      <span>Issue #1185</span>
                    </a>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >14 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-m-1.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Robert Fox</span
                    >
                    <div class="text-700">
                      changed team size from
                      <span class="text-900 font-medium">5</span> to
                      <span class="text-900 font-medium">6</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >20 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-2.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Kristin Watson Cooper</span
                    >
                    <div class="text-700 mb-2">
                      created a Q4 presentation to an issue.
                    </div>
                    <a class="text-primary cursor-pointer">
                      <i class="pi pi-file-pdf text-sm mr-2"></i>
                      <span>q4_presentation.pdf</span>
                    </a>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >38 mins ago</span
                >
              </li>
              <li
                class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-f-3.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Annette Black</span
                    >
                    <div class="text-700">
                      added
                      <span class="text-900 font-medium">Nico Greenberg</span>
                      to <span class="text-primary">Watchlist Tier-1</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >1 day ago</span
                >
              </li>
              <li
                class="py-3 flex md:align-items-start md:justify-content-between flex-column md:flex-row"
              >
                <div class="flex align-items-start mr-0 lg:mr-5">
                  <img
                    src="assets/images/blocks/avatars/circle-big/avatar-m-2.png"
                    class="mr-3 w-3rem h-3rem"
                  />
                  <div>
                    <span class="text-900 font-medium block mb-2"
                      >Floyd Miles</span
                    >
                    <div class="text-700">
                      has refunded a blue t-shirt for
                      <span class="text-primary font-medium">$79</span>.
                    </div>
                  </div>
                </div>
                <span
                  class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0"
                  >4 days ago</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-splitter
  layout="vertical"
  [panelSizes]="[50, 50]"
  [minSizes]="[50, 50]"
  [gutterSize]="2"
  *ngIf="false"
>
  <ng-template pTemplate>
    <p-splitter [panelSizes]="[20, 80]" [gutterSize]="2">
      <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
          <div class="surface-card shadow-2 w-full h-full p-3">
            <div class="text-3xl font-medium text-900 mb-2">Profile</div>
            <div class="font-medium text-500 mb-3">
              Hey there! Here's your profile information.
            </div>
            <ul class="list-none p-0 m-0 w-full h-full">
              <li class="pb-2 border-bottom-1 surface-border">
                <div>
                  <span class="text-600 font-bold">First Name:</span>
                  Max
                </div>
              </li>
              <li class="py-2 border-bottom-1 surface-border">
                <div>
                  <span class="text-600 font-bold">Last Name:</span>
                  Dittmar
                </div>
              </li>
              <li class="py-2 border-bottom-1 surface-border">
                <div>
                  <span class="text-600 font-bold">Username:</span>
                  max
                </div>
              </li>
              <li class="pt-2">
                <div>
                  <span class="text-600 font-bold">Email:</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
          <div class="surface-card shadow-2 w-full h-full p-3">
            <div class="flex align-items-center justify-content-between mb-4">
              <div class="text-3xl font-medium text-900 mb-2">
                Notifications and Alerts
              </div>
            </div>

            <p-scrollPanel [style]="{ width: '100%' }">
              <span class="block text-600 font-medium mb-3">TODAY</span>
              <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-dollar text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Richard Jones
                    <span class="text-700 font-normal"
                      >has purchased a blue t-shirt for
                      <span class="text-primary font-medium">$79</span></span
                    >
                  </span>
                </li>
                <li class="flex align-items-center py-2">
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-download text-xl text-orange-500"></i>
                  </div>
                  <span class="text-700 line-height-3"
                    >Your request for withdrawal of
                    <span class="text-primary font-medium">$2500</span> has been
                    initiated.</span
                  >
                </li>
              </ul>

              <span class="block text-600 font-medium mb-3">YESTERDAY</span>
              <ul class="p-0 m-0 list-none">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-dollar text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Keyser Wick
                    <span class="text-700 font-normal"
                      >has purchased a black jacket for
                      <span class="text-primary font-medium">$59</span></span
                    >
                  </span>
                </li>
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-question text-xl text-pink-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Jane Davis
                    <span class="text-700 font-normal"
                      >has posted a new questions about your product.</span
                    >
                  </span>
                </li>
                <li class="flex align-items-center py-2">
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-arrow-up text-xl text-green-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Claire Smith
                    <span class="text-700 font-normal"
                      >has upvoted your store along with a comment.</span
                    >
                  </span>
                </li>
              </ul>
            </p-scrollPanel>
          </div>
        </div>
      </ng-template>
    </p-splitter>
  </ng-template>
  <ng-template pTemplate>
    <p-splitter [panelSizes]="[40, 60]" [gutterSize]="2">
      <ng-template pTemplate>
        <div
          class="col flex align-items-center justify-content-center overflow-y-scroll"
        >
          <div class="surface-card w-full h-full p-3 shadow-2">
            <p-timeline [value]="events">
              <ng-template pTemplate="content" let-event>
                <small class="p-text-secondary white-space-nowrap">{{
                  event.date
                }}</small>
              </ng-template>
              <ng-template pTemplate="opposite" let-event>
                {{ event.status }}
              </ng-template>
            </p-timeline>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate>
        <div class="col flex align-items-center justify-content-center">
          <div class="surface-card w-full h-full p-3 shadow-2">
            <div class="flex align-items-center justify-content-between mb-4">
              <div class="text-3xl font-medium text-900 mb-2">
                Notifications and Alerts
              </div>
            </div>

            <p-scrollPanel [style]="{ width: '100%' }">
              <span class="block text-600 font-medium mb-3">TODAY</span>
              <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-dollar text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Richard Jones
                    <span class="text-700 font-normal"
                      >has purchased a blue t-shirt for
                      <span class="text-primary font-medium">$79</span></span
                    >
                  </span>
                </li>
                <li class="flex align-items-center py-2">
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-download text-xl text-orange-500"></i>
                  </div>
                  <span class="text-700 line-height-3"
                    >Your request for withdrawal of
                    <span class="text-primary font-medium">$2500</span> has been
                    initiated.</span
                  >
                </li>
              </ul>

              <span class="block text-600 font-medium mb-3">YESTERDAY</span>
              <ul class="p-0 m-0 list-none">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-dollar text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Keyser Wick
                    <span class="text-700 font-normal"
                      >has purchased a black jacket for
                      <span class="text-primary font-medium">$59</span></span
                    >
                  </span>
                </li>
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-question text-xl text-pink-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Jane Davis
                    <span class="text-700 font-normal"
                      >has posted a new questions about your product.</span
                    >
                  </span>
                </li>
                <li class="flex align-items-center py-2">
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-arrow-up text-xl text-green-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium"
                    >Claire Smith
                    <span class="text-700 font-normal"
                      >has upvoted your store along with a comment.</span
                    >
                  </span>
                </li>
              </ul>
            </p-scrollPanel>
          </div>
        </div>
      </ng-template>
    </p-splitter>
  </ng-template>
</p-splitter>
