<div class="grid" style="max-width: 100vw">
  <div class="col-6">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">System Tenants</div>
      <app-tenants></app-tenants>
    </div>
  </div>

  <div class="col-6">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">Portal Tenants</div>
      <app-portal-tenants></app-portal-tenants>
    </div>
  </div>
  <div class="col-12">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">System Users</div>

      <app-tenant-users></app-tenant-users>
    </div>
  </div>
</div>
