import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../../_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PortalFilesService {
  constructor(private api: ApiService) {}

  getFiles(portal_customer_id: number): Observable<any> {
    return this.api
      .getAPI(`portal/management/user/files/${portal_customer_id}`)
      .pipe(
        map((res: any) => {
          return res.body.data;
        })
      );
  }

  getPortalFiles(portal_customer_id: number): Observable<any> {
    return this.api.getAPI(`portal/user/files/${portal_customer_id}`).pipe(
      map((res: any) => {
        return res.body.data;
      })
    );
  }

  downloadFile(file_id: string, extension: string) {
    this.api
      .getFileAPI('portal/management/user/download-file', file_id)
      .subscribe((response: any) => {
        const contentDispositionHeader = response.headers.get(
          'Content-Disposition'
        );
        let fileName = '';
        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.length > 1) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        if (!fileName) {
          fileName = `${file_id}.${extension}`;
        }

        const blob: Blob = response.body as Blob;
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }
}
