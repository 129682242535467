import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LeadsService } from 'src/app/features/crm/_services/leads.service';
import { LeadContactsService } from 'src/app/features/crm/_services/lead-contacts.service';
import { ActionsFormComponent } from '../actions-form/actions-form.component';

import { CacheService } from 'src/app/_core/_services/cache.service';
import { UserService } from 'src/app/_share/_services/user.service';

@Component({
  selector: 'app-lead-main',
  templateUrl: './lead-main.component.html',
  styleUrls: ['./lead-main.component.scss'],
})
export class LeadMainComponent implements OnInit {
  filterValue: any;
  leadsPersonal: any[] = [];

  showOnlyWithInteraction: boolean = false;
  filterPersonalValue: any;

  constructor(
    public leadsService: LeadsService,
    public leadContactsService: LeadContactsService,
    private dialogService: DialogService,
    private userService: UserService,
    private cacheService: CacheService,
  ) {}

  addingAction: boolean = false;
  loadingStuff: boolean = false;
  ref: DynamicDialogRef | undefined;
  activeLead: any;
  activeLeadPersonal: any;
  activePersonalIndex: number = 0;
  activeIndex: number = 0;
  leads: any[] = [];

  ngOnInit(): void {
    this.leadsService.getActiveLeads();
    this.leadsService.getPersonalLeads();
    this.userService.getCurrentLoggedInUser();

    this.leadsService.leadsActive$.subscribe((res) => {
      console.log('res', res);
      this.leads = res;

      this.filterLeadsOnlyNew();
      this.sortNewLeads();

      const idx = this.getActiveIndexFromID(
        this.cacheService.getItem('activeLeadID'),
        this.leads,
      );

      if (idx == -1) {
        // in the other tab, go to the next best lead
        this.nextLead();
      } else {
        this.activeIndex = idx;
      }
      // if (this.leadsPersonal.length > 0) {
      //   this.activePersonalIndex = this.getActiveIndexFromID(
      //     this.cacheService.getItem('activePersonalLeadID'),
      //     this.leadsPersonal
      //   );
      // }

      this.setActiveLead(this.activeIndex);
    });

    this.leadsService.leadsPersonal$.subscribe((res) => {
      console.log('res', res);
      this.leadsPersonal = res;
      this.filterLeadsOnlyQualified();
      this.sortPersonalLeads();
      this.activeLeadPersonal = this.leadsPersonal[this.activePersonalIndex];
    });

    this.leadsService.leadsPersonal$.subscribe((res) => {
      console.log('res', res);
      this.leadsPersonal = res;
      this.filterLeadsOnlyQualified();
      this.sortPersonalLeads();
      this.activeLeadPersonal = this.leadsPersonal[this.activePersonalIndex];
    });
  }

  changeSalesTab($event: any) {
    if ($event.index == 0) {
      // active leads

      this.leadsService.getActiveLeads();
    } else {
      // personal leads

      this.leadsService.getPersonalLeads();
    }
  }

  ngOnChanges() {
    this.setActiveLead(this.activeIndex);
  }
  // todo: implement server side pagination here
  lastLead() {
    this.filterValue = '';
    if (this.activeIndex < this.leads.length - 1) {
      this.activeIndex = this.leads.length - 1;
      this.setActiveLead(this.activeIndex);
    }
  }

  nextLead() {
    this.filterValue = '';
    if (this.activeIndex < this.leads.length - 1) {
      this.activeIndex++;
      this.setActiveLead(this.activeIndex);
    }
  }

  previousLead() {
    this.filterValue = '';
    if (this.activeIndex >= 1) {
      this.activeIndex--;
      this.setActiveLead(this.activeIndex);
    }
  }

  firstLead() {
    this.filterValue = '';
    if (this.activeIndex >= 1) {
      // todo: implement server side pagination here
      this.activeIndex = 0;
      this.setActiveLead(this.activeIndex);
    }
  }

  previousLeadPersonal() {
    if (this.activePersonalIndex >= 1) {
      this.activePersonalIndex--;
      this.activeLeadPersonal = this.leadsPersonal[this.activePersonalIndex];
    }
  }
  nextLeadPersonal() {
    if (this.activePersonalIndex < this.leadsPersonal.length - 1) {
      this.activePersonalIndex++;
      this.activeLeadPersonal = this.leadsPersonal[this.activePersonalIndex];
    }
  }

  filterLeadsOnlyNew() {
    this.leads = this.leads.filter((lead) => {
      this.activeLead = this.leads[this.activeIndex];
      return lead.lead_status == 'new' || lead.lead_status == '';
    });
  }

  filterLeadsOnlyNextInteraction() {
    // only show leads that have a next interaction date

    if (this.showOnlyWithInteraction) {
      this.leads = this.leads.filter((lead) => {
        return lead.next_interaction_date != null;
      });
      this.activeIndex = 0;
    } else {
      this.leadsService.getActiveLeads();
    }
  }

  sortNewLeads() {
    this.leads.sort((a, b) => {
      return a.company.name.localeCompare(b.company.name);
    });
  }

  sortPersonalLeads() {
    // this.leadsPersonal.sort((a, b) => {
    //   return a.company.next_interaction_date - b.company.next_interaction_date;
    // });

    this.leadsPersonal.sort(this.customSort);
  }

  // Custom sorting function
  customSort(a: any, b: any) {
    // First, compare by next_interaction_date
    const nextInteractionComparison =
      a.next_interaction_date - b.next_interaction_date;

    // If next_interaction_date is the same, compare by last_interaction_date
    if (nextInteractionComparison === 0) {
      return a.last_interaction_date - b.last_interaction_date;
    }

    return nextInteractionComparison;
  }

  filterLeadsOnlyQualified() {
    this.leadsPersonal = this.leadsPersonal.filter((lead) => {
      return (
        (lead.lead_status == 'qualified' ||
          lead.lead_status == 'opportunity') &&
        lead.lead_owner?.username == this.userService.currentUser.username
      );
    });
    this.activeLeadPersonal = this.leadsPersonal[this.activePersonalIndex];
  }

  filterLeads() {
    console.log('filterLeads - ', this.filterValue);

    if (this.filterValue) {
      // make filtervalue lowercase
      this.filterValue = this.filterValue.toLowerCase();

      // ensure that company name is in lowercase but not changed on the original object

      this.activeLead = this.leads.filter((lead) => {
        return lead.company.name.toLowerCase().includes(this.filterValue);
      })[0];

      this.activeIndex = this.leads.findIndex((lead) => {
        return lead.company.name.toLowerCase().includes(this.filterValue);
      });
    }
  }

  filterPersonalLeads() {
    // console.log('filterLeads - ', this.filterValue);

    if (this.filterPersonalValue) {
      // make filtervalue lowercase
      this.filterPersonalValue = this.filterPersonalValue.toLowerCase();

      // ensure that company name is in lowercase but not changed on the original object

      this.activeLeadPersonal = this.leadsPersonal.filter((lead) => {
        return lead.company.name
          .toLowerCase()
          .includes(this.filterPersonalValue);
      })[0];

      this.activePersonalIndex = this.leadsPersonal.findIndex((lead) => {
        return lead.company.name
          .toLowerCase()
          .includes(this.filterPersonalValue);
      });
    }
  }

  showAddAction($event: any) {
    // this.addingAction = true;
    console.log($event);
    this.ref = this.dialogService.open(ActionsFormComponent, {
      data: {
        formdata: null,
        lead_id: this.activeLead.id,
        action_owner_id: this.userService.currentUser.id,
      },
      header: 'Log a new Action',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showAddActionPersonal($event: any) {
    // this.addingAction = true;
    console.log($event);
    this.ref = this.dialogService.open(ActionsFormComponent, {
      data: {
        formdata: null,
        lead_id: this.activeLeadPersonal.id,
        action_owner_id: this.userService.currentUser.id,
      },
      header: 'Log a new Action',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  setActiveLead(lead_idx: any) {
    this.activeLead = this.leads[lead_idx];
    this.cacheService.setItem('activeLeadID', this.activeLead.id);
  }

  setActiveLeadPersonal(lead: any) {
    this.activeLeadPersonal = lead;
  }

  getActiveIndexFromID(id: number, leads: any[]): number {
    for (let index = 0; index < leads.length; index++) {
      if (leads[index].id === id) {
        return index; // Return the index when a match is found
      }
    }
    return -1; // Return -1 if no match is found
  }

  ngOnDestroy() {
    // this.leadsService.leads$.unsubscribe();
  }
}
