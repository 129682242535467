import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ParksService } from 'src/app/features/portal/_services/parks.service';
import { PortalRegisterService } from 'src/app/features/portal/_services/portal-register.service';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogService: DialogService,
    private parksService: ParksService,
    private prs: PortalRegisterService
  ) {}

  errorMessage: string = '';
  results: any[] = [];
  notARobot: boolean = false;

  parks: any[] = [];
  parks$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  ukPhoneNumberRegex: RegExp = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;

  submitLoading: boolean = false;

  formErrors: boolean = false;

  supportForm: any = this.fb.group({
    title: ['Mr', [Validators.required]],
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    mobile_phone: ['', [Validators.required]],
    park_name: ['', [Validators.required]],
    pitch_chalet_lodge_no: ['', [Validators.required]],

    support_category: ['', [Validators.required]],
    support_request: ['', [Validators.required]],
    permission_marketing: [false],
  });

  supportCategoryOptions = [
    { value: 'product_issue', label: 'Product Issue' },
    { value: 'billing_issue', label: 'Billing Issue' },
    { value: 'feature_request', label: 'Feature Request' },
    { value: 'general_enquiry', label: 'General Enquiry' },
  ];

  parkOptions: any[] = [];

  controlList: any[] = [
    {
      name: 'title',
      label: 'Title',
      description: 'Please select your title',
      value: 'Please select your title',
      type: 'dropdown',
      width: 2,
      filter: false,
      options: [
        { value: 'Mr', label: 'Mr' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Mrs', label: 'Mrs' },
      ],
      validators: { required: true },
    },
    {
      name: 'first_name',
      label: 'First Name',
      description: 'Enter your first name',
      value: '',
      type: 'text',
      width: 5,
      validators: { required: true },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      description: 'Enter your last name',
      value: '',
      type: 'text',
      width: 5,
      validators: { required: true },
    },
    {
      name: 'email',
      label: 'Email',
      description: 'Enter your email address',
      value: '',
      type: 'email',
      width: 6,
      validators: { required: true, email: true },
    },
    {
      name: 'mobile_phone',
      label: 'Mobile Phone',
      description: 'Enter your mobile number',
      value: '',
      type: 'tel',
      width: 6,
      validators: { required: true },
    },
    {
      name: 'park_name',
      label: 'Park Name',
      description: 'Select the park name',
      value: 'Choose a Park',
      type: 'autocomplete',
      width: 6,
      filter: true,
      validators: { required: true },
      options: this.parkOptions,
    },
    {
      name: 'pitch_chalet_lodge_no',
      label: 'Pitch/Chalet/Lodge No',
      description: 'Enter your pitch, chalet, or lodge number',
      value: '',
      type: 'text',
      width: 6,
      validators: { required: true },
    },
    {
      name: 'support_category',
      label: 'Category',
      description: 'Please choose the category of your support request',
      value: '',
      type: 'dropdown',
      width: 12,
      validators: { required: true },
      options: this.supportCategoryOptions,
    },
    {
      name: 'support_request',
      label: 'Support Request Details',
      description: 'Please enter your request',
      value: '',
      type: 'textarea',
      validators: { required: true },
    },
    {
      name: 'permission_marketing',
      label: 'I agree to receive other communications from Benington Networks.',
      description:
        'By clicking submit below, you consent to allow Benington Networks to store and process the personal information submitted above to provide you the content requested.',
      value: false,
      type: 'checkbox',
      validators: {},
    },
  ];

  ngOnInit(): void {
    // this.showSupportSuccessDialog();
    this.parksService.getParks().subscribe((parks: any) => {
      this.parks = parks;
      this.parks$.next(parks);
      this.parkOptions = this.convertToOptions(parks);
    });
  }

  showSupportSuccessDialog() {
    this.dialogService.open(SuccessDialogComponent, {
      header: 'Support Request Submitted',
      width: '70%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
    });
  }

  onSupportSubmit() {
    this.errorMessage = '';
    console.log('form valid: ', this.supportForm.valid);
    console.log('form value: ', this.supportForm.value);
    this.submitLoading = true;

    if (this.supportForm.valid) {
      this.prs.createSupportRequest(this.supportForm.value).subscribe(
        (response) => {
          console.log('response: ', response);
          this.errorMessage = 'Your support request has been submitted';
          this.showSupportSuccessDialog();
          this.submitLoading = false;
          this.supportForm.reset();
        },

        (error) => {
          console.log('error: ', error);
          this.errorMessage = 'There was an error submitting your request';
          this.submitLoading = false;
        }
      );
    } else {
      this.submitLoading = false;
      this.errorMessage = 'Please fill out the required fields';
      this.supportForm.markAllAsTouched();

      for (const control of Object.keys(this.supportForm.controls)) {
        this.supportForm.get(control)?.markAsDirty();
      }
    }
  }

  convertToOptions(parks: any[]): any[] {
    return parks.map((park) => {
      return {
        value: park.id,
        label: park.name,
        type: park.description.toLowerCase(),
      };
    });
  }

  search(event: any) {
    console.log(event.query);
    if (event.query.length < 4) {
      this.results = [];
    } else {
      const resultsTemp = this.parkOptions
        .filter((option) => {
          return option.label.toLowerCase().includes(event.query.toLowerCase());
        })
        .map((result) => {
          return result.label;
        });

      this.results = resultsTemp;
    }
  }

  setValiditity($event: any) {
    this.notARobot = true;
  }
}
