import { Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../../../_core/_services/api.service';
import { Vno } from '../../../../_share/_models/entities/schema';
import { BehaviorSubject, map, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VnosService {
  vnosQuery$: any;

  @Output() vnos: any[] = [];
  @Output() vnos$: Subject<any> = new Subject<any>();

  constructor(public api: ApiService) {
    this.vnos = [];
  }

  getVnos(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Vno>('vno', queryParams).pipe(
      map((res) => {
        //console.log('res', res);
        return res.body;
      })
    );
  }

  addVno(value: Vno) {
    const new_value = { ...value };
    console.log('new_value', new_value);

    this.vnosQuery$ = this.api.postAPI<Vno>('vno', new_value);
    return this.vnosQuery$;
  }

  updateVno(id: any, value: Vno) {
    const new_value = { ...value };
    console.log('VNO to update', new_value);
    this.vnosQuery$ = this.api.putAPI<Vno>('vno', id, new_value);
    return this.vnosQuery$;
  }

  deleteVno(vno_id: number) {
    this.vnosQuery$ = this.api
      .deleteAPI<Vno>('vno', vno_id)
      .subscribe((res) => {
        //console.log('res', res);
      });
  }
}
