<div>
  <span class="text-900 font-medium text-3xl px-6"> Customer Details </span>
</div>

<div class="lg:px-6 px-1 py-5">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">
      <span class="text-blue-600">
        {{ customer?.first_name }} {{ customer?.last_name }}
      </span>
    </div>

    <ul class="list-none p-0 m-0 border-top-1 border-300">
      <li
        *ngFor="let field of customerFieldsToDisplay; let i = index"
        [ngClass]="{ 'surface-ground': i % 2 !== 0 }"
        class="flex align-items-center py-3 px-2 flex-wrap"
      >
        <div class="text-500 w-full md:w-4 font-medium">{{ field.header }}</div>
        <div class="text-900 w-full md:w-8">
          <ng-container [ngSwitch]="field.type">
            <span *ngSwitchCase="'text'">{{ customer?.[field.field] }}</span>
            <span *ngSwitchCase="'date'">{{ customer?.[field.field] }}</span>
            <span *ngSwitchCase="'number'">{{ customer?.[field.field] }}</span>
            <ng-container *ngSwitchCase="'status'"> </ng-container>
            <span *ngSwitchDefault>{{ customer?.[field.field] }}</span>
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</div>
