import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(country: 'ZA' | 'UK'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validPhoneNumber: RegExp = /^(?:\+27|0)\d{9,11}$/;
    if (country === 'ZA') {
      validPhoneNumber = /^(?:\+27|0)\d{9,11}$/;
    } else if (country === 'UK') {
      validPhoneNumber = /^(?:\+44|0)(?:\d\s?){9,10}\d$/;
    }

    const isValid = validPhoneNumber.test(control.value);
    return isValid ? null : { phoneNumber: { value: control.value, country } };
  };
}
