<!-- 


 -->
<p-table
  styleClass="p-datatable-striped"
  #customersTable
  [value]="(userActions$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
  [globalFilterFields]="[
    'action_date',
    'action_description',
    'action_type',
    'date_created',
    'date_updated',
    'id',
    'portal_subscription_id',
  ]"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(customersTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          #inputField
          pInputText
          type="text"
          (input)="customersTable.filterGlobal(inputField.value, 'contains')"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer>
    <tr
      #row
      style="cursor: pointer"
      [pSelectableRow]="customer"
      [pContextMenuRow]="customer"
    >
      <td *ngFor="let col of columns">
        <div class="flex align-items-center">
          {{ customer[col.field] }}
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p>
          There are no records to show. You might be disconnected or offline.
        </p>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="isLoading"
          mode="indeterminate"
        >
        </p-progressBar>
        <div *ngIf="!isLoading">
          <p>
            There are no records to show. You might be disconnected or offline.
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="#errorMessage">
    <div *ngIf="isError">
      <p>There was an error fetching the data</p>
    </div>
  </ng-template>
</p-table>
<p-confirmDialog header="Delete"></p-confirmDialog>
