<div class="overflow-y-scroll" style="max-height: 60vh">
  <div class="surface-section px-4 py-4 md:px-2 lg:px-4">
    <div
      class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900"
      style="border-left: 8px solid"
    >
      Lead Details
    </div>

    <ng-container *ngIf="loading; else showDetails">
      <p-skeleton height="20px"></p-skeleton>
      <p-divider></p-divider>
      <p-skeleton height="20px"></p-skeleton>
    </ng-container>

    <ng-template #showDetails>
      <div class="text-600 text-sm mb-3">
        <span>Updated </span>
        <span class="font-bold"
          >{{ lead?.date_updated | date : "yyyy-MM-dd hh:mm" }} ({{
            lead?.date_updated | dateAsAgo
          }})</span
        >
      </div>
      <div
        class="text-600 text-sm mb-3"
        *ngIf="lead && lead.last_interaction_date"
      >
        <span>Last Interacted: </span>
        <span class="font-bold">{{
          lead?.last_interaction_date | date : "yyyy-MM-dd"
        }}</span>
      </div>
      <div
        class="text-600 text-sm mb-3"
        *ngIf="lead && lead.next_interaction_date"
      >
        <span>Next Interaction Date: </span>
        <span class="font-bold">{{
          lead?.next_interaction_date | date : "yyyy-MM-dd"
        }}</span>
      </div>
      <p-divider></p-divider>
      <p-tag
        [value]="(lead && lead.lead_status | uppercase) || 'NEW'"
        class="align-self-center ml-2"
      >
      </p-tag>
      <p-tag
        [value]="(lead && lead.next_interaction_date) || 'No Date'"
        class="align-self-center ml-2"
      >
      </p-tag>
      <!-- todo: need to load and show it when still retrieving leads -->
    </ng-template>
    <div class="flex gap-3 flex-column-reverse md:flex-row">
      <!-- if loading else show form-->
      <ng-container *ngIf="loading; else showForm">
        <div class="flex-auto grid formgrid p-fluid">
          <p-skeleton
            *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            height="40px"
            class="w-12 md:w-6 field my-2 p-2"
          ></p-skeleton>
        </div>
      </ng-container>

      <ng-template #showForm>
        <form
          [formGroup]="leadDetailsForm"
          (ngSubmit)="onLeadUpdateDetailsSubmit()"
        >
          <!-- name -->
          <div class="flex-auto grid formgrid p-fluid">
            <div class="mb-2 w-12 md:w-6 p-2 field">
              <label for="email" class="block font-normal text-900 mb-2"
                >Company Name</label
              >
              <input
                formControlName="name"
                styleClass="p-input-text"
                id="text"
                type="text"
                pInputText
                autocomplete="false"
              />
            </div>
            <!-- address -->
            <div class="mb-2 w-6 p-2 field">
              <label for="address" class="block font-normal text-900 mb-2"
                >Company Address</label
              >
              <textarea
                id="address"
                formControlName="address"
                type="text"
                pInputTextarea
                rows="5"
                [autoResize]="true"
                autocomplete="false"
              ></textarea>
            </div>

            <!-- website -->
            <div class="mb-2 w-6 p-2 field">
              <label for="website" class="font-normal text-900 mb-2"
                >Company Website URL</label
              >
              <div class="p-inputgroup">
                <!-- <span class="p-inputgroup-addon">https://</span> -->
                <input
                  id="website"
                  type="text"
                  formControlName="website"
                  pInputText
                  autocomplete="false"
                />
              </div>
              <!-- <a
              pButton
              label="Visit"
              icon="pi pi-external-link"
              target="_blank"
              [href]="'https://' + sanitize(lead && lead.company.website)"
              style="text-decoration: none"
              class="my-1"
            ></a> -->
            </div>

            <!-- email address -->
            <div class="mb-2 w-6 p-2 field">
              <label for="email" class="font-normal text-900 mb-2"
                >Company Email</label
              >
              <input
                id="email"
                type="email"
                formControlName="email"
                pInputText
                autocomplete="false"
              />
              <a
                pButton
                label="Email {{ lead && lead.company.name }}"
                icon="pi pi-external-link"
                target="_blank"
                [href]="'mailto:' + (lead && lead.company.email)"
                style="text-decoration: none"
                class="my-1"
                *ngIf="lead && lead.company.email.length > 1"
              ></a>
            </div>

            <!-- contact number -->
            <div class="mb-2 w-6 p-2 field">
              <label for="phone" class="font-normal text-900 mb-2"
                >Company Telephone</label
              >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">+27</span>
                <input
                  id="phone"
                  type="tel"
                  formControlName="phone"
                  pInputText
                  autocomplete="false"
                />
              </div>
              <a
                pButton
                label="Call {{ lead && lead.company.name }}"
                icon="pi pi-phone"
                target="_blank"
                class="my-1 p-button-success"
                [href]="
                  'https://teams.microsoft.com/l/call/0/0?users=4:' +
                  convertToLocalNumber(lead && lead.company.phone)
                "
                *ngIf="lead && lead.company.phone.length > 1"
                style="text-decoration: none"
              ></a>
            </div>

            <!-- staff count -->
            <div class="mb-2 w-6 p-2 field">
              <label for="staff_count" class="font-normal text-900 mb-2"
                >Company Size</label
              >
              <div class="p-inputgroup">
                <p-inputNumber
                  id="staff_count"
                  formControlName="staff_count"
                ></p-inputNumber>
              </div>
            </div>

            <!-- add border -->
            <p-divider class="w-12"></p-divider>

            <!-- latitude -->
            <div class="mb-2 w-6 p-2 field">
              <label for="latitude" class="font-normal text-900 mb-2"
                >Latitude</label
              >
              <p-inputNumber
                id="latitude"
                formControlName="latitude"
                [minFractionDigits]="2"
                mode="decimal"
                [maxFractionDigits]="5"
                autocomplete="false"
                [min]="-90"
                [max]="90"
                [useGrouping]="false"
              >
              </p-inputNumber>
              <a
                pButton
                [label]="
                  successfullCoordsCopy
                    ? 'Copied'
                    : 'Copy coordinates to clipboard'
                "
                icon="pi pi-copy"
                style="text-decoration: none"
                class="my-1"
                *ngIf="lead && lead.company.latitude && lead.company.longitude"
                (click)="copyToClipboard()"
              ></a>
            </div>

            <!-- longitude -->
            <div class="mb-2 w-6 p-2 field">
              <label for="longitude" class="font-normal text-900 mb-2"
                >Longitude</label
              >
              <p-inputNumber
                id="longitude"
                formControlName="longitude"
                [minFractionDigits]="2"
                mode="decimal"
                [maxFractionDigits]="5"
                autocomplete="false"
                [useGrouping]="false"
                [min]="-180"
                [max]="180"
              >
              </p-inputNumber>
            </div>

            <!-- multiple branches -->
            <!-- <div class="mb-2 w-6 p-2 field">
            <label for="multiple_branches" class="font-normal text-900 mb-2"
              >Multiple Branches</label
            >
            <p-toggleButton
              formControlName="multiple_branches"
              onLabel="Yes"
              offLabel="No"
              class="w-full"
            ></p-toggleButton>
          </div> -->

            <div class="field w-12">
              <button
                pButton
                pRipple
                type="submit"
                label="Update Lead Details"
                icon="pi pi-save"
                class="p-button-rounded p-button-secondary w-12 mt-3 block"
              ></button>
              <p-divider></p-divider>
            </div>

            <div
              class="m-1 p-1 w-12"
              *ngIf="lead && lead.company.contacts?.length"
            >
              <!--  -->
              <div class="flex justify-content-between align-items-center mb-2">
                <span class="text-lg text-900 font-medium"
                  >Key Individuals</span
                >
              </div>
              <p-divider></p-divider>
              <ul class="list-none p-0 m-0 flex flex-column md:flex-column">
                <li
                  class="flex md:align-items-center md:justify-content-between mb-4"
                  *ngFor="let contact of lead && lead.company.contacts"
                >
                  <div
                    class="flex surface-card border-1 border-400 border-round md:m-1 w-full align-items-center justify-content-center"
                  >
                    <div class="p-4">
                      <div class="flex flex-column align-items-center mb-3">
                        <span class="flex font-medium text-900 text-xl mb-2">{{
                          contact.name
                        }}</span>
                        <span class="text-800 text-lg mb-2">{{
                          contact.job_title
                        }}</span>

                        <div>
                          <span class="text-600 font-bold">Email:</span>
                          <span class="ml-1 text-800">{{ contact.email }}</span>
                        </div>
                        <div>
                          <span class="text-600 font-bold">Phone:</span>
                          <span class="ml-1 text-800">{{ contact.phone }}</span>
                        </div>
                      </div>
                      <div class="pt-3 border-top-1 surface-border flex">
                        <a
                          pButton
                          pRipple
                          type="button"
                          label="Call {{ contact.name }}"
                          icon="pi pi-phone"
                          target="_blank"
                          class="my-1 flex-auto ml-2 p-button-outlined p-button-success"
                          style="border-radius: 30px"
                          [href]="
                            'https://teams.microsoft.com/l/call/0/0?users=4:' +
                            convertToLocalNumber(contact && contact.phone)
                          "
                          style="text-decoration: none"
                        ></a>
                        <button
                          pButton
                          pRipple
                          type="button"
                          label="Edit {{ contact.name }}"
                          icon="pi pi-pencil"
                          class="my-1 flex-auto ml-2 p-button-outlined p-button-primary"
                          style="border-radius: 30px"
                          style="text-decoration: none"
                          (click)="showEditKeyIndividual($event, contact.id)"
                        ></button>
                      </div>
                      <div class="">
                        <span class="text-900 font-medium mb-1"></span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button
              pButton
              type="button"
              label="Add Key Individual"
              class="p-button-rounded p-button-secondary"
              icon="pi pi-plus"
              (click)="showAddKeyIndividual($event)"
            ></button>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
</div>
