<ng-container *ngIf="portalConfig$ | async as portalConfig">
  <ng-container [ngSwitch]="vno">
    <!-- portal body -->
    <ng-container *ngSwitchCase="'Benington'">
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center align-items-center min-h-full"
      >
        <div class="flex align-items-center justify-content-center">
          <h3 class="text-2xl text-gray-500">
            Welcome to {{ this.park?.name }}
          </h3>
        </div>
        <div class="flex align-items-center justify-content-center">
          <p class="p-4 text-lg text-gray-800 text-center">
            You can either register and purchase a new package or activate your
            Wi-Fi access using your voucher code.
          </p>
        </div>
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Register and Purchase Wi-Fi Access"
                  icon="pi pi-money-bill"
                  class="p-button-outlined p-button-lg p-button-info"
                  (click)="showRegisterDialogUK($event, 'register')"
                ></button>
              </li>
              <li class="pb-2 surface-border" *ngIf="false">
                <button
                  pButton
                  pRipple
                  label="Top Up Wi-Fi Access"
                  icon="pi pi-money-bill"
                  class="p-button-outlined p-button-lg p-button-info"
                  (click)="showRegisterDialogUK($event, 'topup')"
                ></button>
              </li>
              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Activate Wi-Fi Access"
                  class="p-button-success p-button-outlined p-button-lg"
                  (click)="showAuthDialogUK($event)"
                ></button>
              </li>

              <!-- <li class="pb-4 pt-4 border-top-1 surface-border"> -->
              <!-- <button
                *ngIf="!(portalUser | async)"
                pButton
                pRipple
                label="Sign In"
                icon="pi pi-user"
                class="mx-2  p-button-success p-button-lg"
                (click)="showSignInDialog($event)"
              ></button> -->
              <!-- <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Register"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-success p-button-lg"
                  (click)="showRegisterDialogUK($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button> -->
              <!-- </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div
        class="surface-section p-3 flex-grow-1 flex flex-row justify-content-center"
      ></div>
    </ng-container>

    <ng-container *ngSwitchCase="'MTD'">
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center align-content-center min-h-full"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-4 pt-4 surface-border">
                <img
                  src="/assets/portal/images/mtd/billy.svg"
                  alt="Billy"
                  height="100px"
                />
              </li>
              <li class="py-5 surface-border" *ngIf="false">
                <button
                  pButton
                  pRipple
                  label="Purchase Prepaid Access"
                  class="p-button p-button-rounded p-button-lg bg-purple-600 p-4"
                  routerLink="/portal/purchase/package"
                ></button>
                <!-- <p class="text-purple-400">
                    You need to sing in or sign up to purchase a package...
                  </p> -->
              </li>

              <li class="pb-4 pt-4 border-top-1 surface-border" *ngIf="false">
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg bg-blue-500 p-4"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg bg-blue-500 p-4"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="p-button p-button-rounded p-button-lg bg-purple-600 p-4"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined p-4"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>

              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Activate Wi-Fi Access"
                  class="p-button p-button-rounded p-button-outlined p-button-lg p-4"
                  (click)="showAuthDialogUK($event)"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Stem'">
      <div
        class="surface-section p-3 min-h-full flex-column justify-content-center align-content-center"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Purchase Internet Access"
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-outlined p-button-lg"
                  routerLink="/portal/purchase/package"
                ></button>
              </li>

              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Complimentary Access"
                  class="p-button-rounded p-button-success p-button-outlined p-button-lg"
                ></button>
              </li>

              <li class="pb-4 pt-4 border-top-1 surface-border">
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div
        class="surface-section p-3 flex-grow-1 flex flex-column justify-content-center"
      >
        <div class="flex">
          <div
            class="w-12 py-8 text-center flex align-items-center justify-content-center"
          >
            <ul class="list-none p-0 m-0">
              <li class="pb-2 surface-border">
                <button
                  pButton
                  pRipple
                  label="Purchase Internet Access"
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-outlined p-button-lg"
                  routerLink="/portal/purchase/package"
                ></button>
              </li>
              <!-- <li class="py-4 border-bottom-1 surface-border">
        <button
          pButton
          pRipple
          label="Activate Access with Voucher"
          icon="pi pi-credit-card"
          class="p-button-rounded p-button-outlined p-button-lg"
        ></button>
      </li> -->
              <li class="py-4">
                <button
                  pButton
                  pRipple
                  label="Complimentary Access"
                  class="p-button-rounded p-button-success p-button-outlined p-button-lg"
                ></button>
              </li>
              <!-- <li class="pt-4">
        <button
          pButton
          pRipple
          label="Education Channel"
          class="p-button-rounded p-button-success p-button-outlined p-button-lg"
        ></button>
      </li> -->
              <li class="pb-4 pt-4 border-top-1 surface-border">
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign In"
                  icon="pi pi-user"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignInDialog($event)"
                ></button>
                <button
                  *ngIf="!(portalUser | async)"
                  pButton
                  pRipple
                  label="Sign Up"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignUpDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Sign Out"
                  icon="pi pi-user-plus"
                  class="mx-2 p-button-rounded p-button-success p-button-lg"
                  (click)="showSignOutDialog($event)"
                ></button>
                <button
                  *ngIf="portalUser | async"
                  pButton
                  pRipple
                  label="Go to Profile"
                  icon="pi pi-th-large"
                  class="mx-2 p-button-rounded p-button-info p-button-lg p-button-outlined"
                  [routerLink]="['/portal/profile']"
                ></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- <div *ngIf="vno == 'Benington'; else otherVNOFooter">
    <div
      class="surface-100 px-4 py-4 md:px-6 lg:px-8 flex flex-column justify-content-between align-items-center"
    >
      <span class="text-600"
        >© {{ getCurrentYear() }}, Captive Portal. Powered by Benington
        Networks.</span
      >
    </div>
  </div>

  <ng-template #otherVNOFooter> </ng-template>
</ng-container> -->
</ng-container>
