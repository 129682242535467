<!-- <div style="outline: solid 1px red"> -->
<div class="flex-1 overflow-y-auto p-2 overflow-x-hidden" #formContainer>
  <form [formGroup]="this.bss.qualifyingForm">
    <div class="grid formgrid p-fluid">
      <div class="field mb-2 col-12 lg:col-12">
        <label
          >Are you currently making use of internet at your business?</label
        >
        <small class="text-gray-500 block mb-2"
          >If you require internet for day to day business operations</small
        >
        <div class="field-radiobutton py-1 px-2">
          <p-radioButton
            formControlName="internetUsage"
            value="yes"
            inputId="internetUsageYes"
          ></p-radioButton>
          <label for="internetUsageYes" class="">Yes</label>
        </div>
        <div class="field-radiobutton py-1 px-2">
          <p-radioButton
            formControlName="internetUsage"
            value="no"
            inputId="internetUsageNo"
          ></p-radioButton>
          <label for="internetUsageNo" class="">No</label>
        </div>
        <small
          *ngIf="
            bss.qualifyingForm.controls['internetUsage'].hasError('required') &&
            bss.qualifyingForm.controls['internetUsage'].touched
          "
          id="internetUsage-help"
          class="p-error"
          >This field is required</small
        >
      </div>
      <!-- field -->
      <div
        formGroupName="ifInternetUsage"
        class="border-1 border-blue-400 border-round m-4 p-4 fadein animation-duration-1000 formgrid grid"
        *ngIf="bss.qualifyingForm.get('internetUsage')?.value === 'yes'"
      >
        <div class="field mb-4 col-12 lg:col-12">
          <label>Please specify what type of internet service:</label>
          <small class="text-gray-500 block mb-2"
            >Select the primary service</small
          >
          <!-- options -->
          <div
            *ngFor="
              let option of findFormControlName('internetType')?.valueOptions
            "
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="internetType"
              [value]="option.value"
            ></p-radioButton>
            <label class="">{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label>Who is your current provider?</label>
          <input
            pInputText
            formControlName="provider"
            id="provider"
            type="text"
          />
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label>Are you currently in contract or month-to-month?</label>

          <!-- options -->
          <div
            *ngFor="
              let option of findFormControlName('contractType')?.valueOptions
            "
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="contractType"
              [value]="option.value"
            ></p-radioButton>
            <label>{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->

        <div
          formGroupName="ifContractType"
          class="border-1 border-blue-400 border-round p-4 m-2 fadein animation-duration-1000 formgrid grid col-12"
          *ngIf="
            bss.qualifyingForm.get('ifInternetUsage.contractType')?.value ===
            'contract'
          "
        >
          <div class="field mb-4 col-12 lg:col-12">
            <label>When does your contract end?</label>
            <small class="text-gray-500 block"
              >Please specify the year and month</small
            >
            <p-calendar
              class="col-12"
              formControlName="contractPeriod"
              [touchUI]="true"
              view="month"
              dateFormat="yy-mm"
            ></p-calendar>
          </div>
          <!-- field -->
        </div>
        <!-- end of form group - contractType -->

        <div class="field mb-4 col-12 lg:col-12">
          <label>What is your current average connection speed? (Mbps)</label>
          <p-inputNumber
            formControlName="connectionSpeeds"
            id="connectionSpeeds"
            [step]="10"
            [min]="1"
            [placeholder]="'Mbps'"
            [showButtons]="true"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            buttonLayout="horizontal"
          ></p-inputNumber>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label
            >How many users do you have on using your internet connection
            daily?</label
          >
          <p-inputNumber
            formControlName="usersDaily"
            id="usersDaily"
            [step]="1"
            [min]="1"
            [showButtons]="true"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            buttonLayout="horizontal"
          ></p-inputNumber>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label
            >What are you currently paying per month on your internet?</label
          >
          <p-inputNumber
            formControlName="monthlyCost"
            id="monthlyCost"
            [step]="100"
            [min]="1"
            currency="ZAR"
            currencyDisplay="symbol"
            [showButtons]="true"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            buttonLayout="horizontal"
            mode="currency"
            locale="en-ZA"
          ></p-inputNumber>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label>What are you using your internet for?</label>
          <!-- options -->
          <p-listbox
            [options]="findFormControlName('usage')?.valueOptions || []"
            [metaKeySelection]="false"
            [checkbox]="true"
            [multiple]="true"
            [showToggleAll]="false"
            formControlName="usage"
            optionLabel="label"
            optionValue="value"
          >
            <ng-template let-usageItem pTemplate="item">
              <div>{{ usageItem.label }}</div>
            </ng-template>
          </p-listbox>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label>Do you have a failover or backup connection?</label>

          <!-- options -->
          <div
            *ngFor="let option of findFormControlName('failover')?.valueOptions"
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="failover"
              [value]="option.value"
            ></p-radioButton>
            <label class="">{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label
            >Are you aware of the new fibre infrastructure in your area?</label
          >

          <!-- options -->
          <div
            *ngFor="
              let option of findFormControlName('infrastructureAwareness')
                ?.valueOptions
            "
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="infrastructureAwareness"
              [value]="option.value"
            ></p-radioButton>
            <label class="">{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->

        <div class="field mb-4 col-12 lg:col-12">
          <label
            >What is more important to you, cost, speed or reliability?</label
          >

          <!-- options -->
          <div
            *ngFor="
              let option of findFormControlName('costVsSpeedVsReliability')
                ?.valueOptions
            "
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="costVsSpeedVsReliability"
              [value]="option.value"
            ></p-radioButton>
            <label class="">{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->
      </div>
      <!-- end of form group - internetUsage -->

      <div class="field mb-4 col-12 lg:col-12">
        <label
          >Are you making use of an internet telephone service or VoIP?</label
        >

        <!-- options -->
        <div
          *ngFor="
            let option of findFormControlName('voipOrTelephony')?.valueOptions
          "
          class="field-radiobutton py-1 px-2"
        >
          <p-radioButton
            formControlName="voipOrTelephony"
            [value]="option.value"
          ></p-radioButton>
          <label class="">{{ option.label }}</label>
        </div>
      </div>
      <!-- end of field -->

      <div class="field mb-4 col-12 lg:col-12">
        <label>Would you be interested in a quote for fibre?</label>

        <!-- options -->
        <div
          *ngFor="
            let option of findFormControlName('fibreQuotation')?.valueOptions
          "
          class="field-radiobutton py-1 px-2"
        >
          <p-radioButton
            formControlName="fibreQuotation"
            [value]="option.value"
          ></p-radioButton>
          <label class="">{{ option.label }}</label>
        </div>
        <small
          *ngIf="
            bss.qualifyingForm.controls['fibreQuotation'].hasError(
              'required'
            ) && bss.qualifyingForm.controls['fibreQuotation'].touched
          "
          id="fibreQuotation-help"
          class="p-error"
          >This field is required</small
        >
      </div>
      <!-- end of field -->
      <div
        formGroupName="ifFibreQuotation"
        class="border-1 border-blue-400 border-round m-4 p-4 fadein animation-duration-1000 formgrid grid w-full"
        *ngIf="bss.qualifyingForm.get('fibreQuotation')?.value === false"
      >
        <div class="field mb-4 col-12 lg:col-12">
          <label>If not interested in a quote, what is the reason?</label>

          <input pInputText formControlName="reason" type="text" />
        </div>
        <!-- end of field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label>Could we contact you in the future?</label>

          <!-- options -->
          <div
            *ngFor="
              let option of findFormControlName('contactInFuture')?.valueOptions
            "
            class="field-radiobutton py-1 px-2"
          >
            <p-radioButton
              formControlName="contactInFuture"
              [value]="option.value"
            ></p-radioButton>
            <label class="">{{ option.label }}</label>
          </div>
        </div>
        <!-- end of field -->
        <div
          formGroupName="ifContactInFuture"
          *ngIf="
            bss.qualifyingForm.get('ifFibreQuotation.contactInFuture')
              ?.value === true
          "
          class="border-1 border-blue-400 border-round m-4 p-4 fadein animation-duration-1000 formgrid grid w-full"
        >
          <div class="field mb-4 col-12 lg:col-6">
            <label for="emailAddressContactInFuture">Contact Email</label>
            <input
              id="emailAddressContactInFuture"
              type="text"
              pInputText
              formControlName="emailAddressContactInFuture"
              (blur)="
                bss.qualifyingForm
                  .get(
                    'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture'
                  )
                  ?.markAsTouched()
              "
              pKeyFilter="email"
            />
            <small class="text-600 text-800 block mb-2"
              >Email address with correct parameters</small
            >

            <small
              *ngIf="
                bss.qualifyingForm.get(
                  'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture'
                )?.touched &&
                bss.qualifyingForm
                  .get(
                    'ifFibreQuotation.ifContactInFuture.emailAddressContactInFuture'
                  )
                  ?.hasError('email')
              "
              id="emailAddressContactInFuture-help"
              class="p-error"
              >Please enter a valid email address</small
            >
          </div>
          <!-- end of field -->

          <div class="field mb-4 col-12 lg:col-6">
            <label for="contactNumberContactInFuture">Contact Number</label>
            <input
              id="contactNumberContactInFuture"
              type="text"
              pInputText
              formControlName="contactNumberContactInFuture"
              pKeyFilter="num"
            />
            <small class="text-600 text-800 block mb-2"
              >Contact number with correct parameters</small
            >
            <!-- <small
              *ngIf="
                bss.qualifyingForm.get(
                  'ifFibreQuotation.ifContactInFuture.contactNumberContactInFuture'
                )?.touched
              "
              id="contactNumberContactInFuture-help"
              class="p-error"
              >Please enter a valid contact number</small
            > -->
          </div>
          <!-- end of field -->
          <div class="field mb-4 col-12 lg:col-6">
            <label for="notesContactInFuture">Notes</label>
            <textarea
              id="notesContactInFuture"
              type="text"
              rows="5"
              pInputTextarea
              formControlName="notesContactInFuture"
            ></textarea>
          </div>
        </div>
        <!-- end of form group - ifContactInFuture -->
      </div>
      <!-- end of form group - fibreQuotation -->
    </div>
    <!-- grid -->
  </form>
</div>
