<p-table
  #subscriptionsTable
  [value]="(tenants$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
  [globalFilterFields]="['name', 'description']"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          #inputField
          pInputText
          type="text"
          (input)="
            subscriptionsTable.filterGlobal(inputField.value, 'contains')
          "
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tenant>
    <tr>
      <td *ngFor="let col of columns">{{ tenant[col.field] }}</td>
    </tr>
  </ng-template>
</p-table>
