<!-- 


 -->
<p-table
  styleClass="p-datatable-striped"
  #subscriptionActionsTable
  [value]="(subscriptionActions$ | async) || []"
  scrollHeight="40vh"
  [scrollable]="true"
  [globalFilterFields]="[
    'action_date',
    'action_description',
    'action_type',
    'date_created',
    'date_updated',
    'id',
    'portal_subscription_id'
  ]"
  sortField="portal_subscription.subscription_account_reference"
  sortMode="single"
  rowGroupMode="subheader"
  groupRowsBy="portal_subscription.subscription_account_reference"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        label="Clear"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(subscriptionActionsTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          #inputField
          pInputText
          type="text"
          (input)="
            subscriptionActionsTable.filterGlobal(inputField.value, 'contains')
          "
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }}
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="groupheader"
    let-action
    let-rowIndex="rowIndex"
    let-expanded="expanded"
  >
    <tr pRowGroupHeader>
      <td colspan="5">
        <span class="font-bold ml-2">{{
          action.portal_subscription.subscription_account_reference
        }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-action>
    <tr
      #row
      style="cursor: pointer"
      [pSelectableRow]="action"
      [pContextMenuRow]="action"
    >
      <td *ngFor="let col of columns">
        <div class="flex align-items-center">
          {{ action[col.field] }}
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p>
          There are no records to show. You might be disconnected or offline.
        </p>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [attr.colspan]="this.getNumberofColumns()"
        class="align-content-center"
      >
        <p-progressBar
          styleClass="align-self-center"
          *ngIf="isLoading"
          mode="indeterminate"
        >
        </p-progressBar>
        <div *ngIf="!subscriptionActions$">
          <p>
            There are no records to show. You might be disconnected or offline.
          </p>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="#errorMessage">
    <div *ngIf="isError">
      <p>There was an error fetching the data</p>
    </div>
  </ng-template>
</p-table>
<p-confirmDialog header="Delete"></p-confirmDialog>
