import { Injectable } from '@angular/core';
import { Product } from '../../../../_share/_models/entities/schema';
import { map, Subject } from 'rxjs';
import { ApiService } from '../../../../_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  productsQuery$: any;
  products: Product[];
  products$: Subject<Product[]> = new Subject<Product[]>();
  constructor(public api: ApiService) {
    this.products = [];
  }

  getProducts(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }

    return this.api.getAPI<Product>('product', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addProduct(value: any) {
    const new_value = { ...value };
    for (let i = 0; i < new_value.equipment_types.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.equipment_types[i] = { id: new_value.equipment_types[i] };
    }

    console.log('new_value', new_value);

    this.productsQuery$ = this.api.postAPI<Product>('product', new_value);
    return this.productsQuery$;
  }

  updateProduct(id: any, value: any) {
    const new_value = { ...value };
    for (let i = 0; i < new_value.equipment_types.length; i++) {
      // Convert list of vnos into list of objects containing a key-value pair of id and number
      new_value.equipment_types[i] = { id: new_value.equipment_types[i] };
    }

    console.log('new_value', new_value);

    this.productsQuery$ = this.api.putAPI<Product>('product', id, new_value);
    return this.productsQuery$;
  }

  // todo: delete product method
}
