import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-summary',
  templateUrl: './statistics-summary.component.html',
  styleUrls: ['./statistics-summary.component.scss'],
})
export class StatisticsSummaryComponent implements OnInit {
  @Input() summaryTopic: string = 'device';
  @Input() summaryData: any = {};

  mapOptions: any = {};

  constructor() {}

  ngOnInit(): void {
    this.mapOptions = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12,
    };
  }
}
