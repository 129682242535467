import { Injectable } from '@angular/core';
import { ApiService } from '../../../../_core/_services/api.service';
import { map, Subject } from 'rxjs';
import { Contact } from 'src/app/_share/_models/entities/schema';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  contactsQuery$: any;

  contacts: any[] = [];
  contacts$: Subject<any[]> = new Subject<any[]>();

  constructor(public api: ApiService) {}

  getContacts(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<Contact[]>('contact', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addContact(value: any) {
    const new_value = { ...value };
    this.contactsQuery$ = this.api.postAPI<any>('contact', new_value);
    return this.contactsQuery$;
  }

  updateContact(id: any, value: any) {
    const new_value = { ...value };
    this.contactsQuery$ = this.api.putAPI<any>('contact', id, new_value);
    return this.contactsQuery$;
  }

  deleteContact(id: any) {
    this.api.deleteAPI<any>('contact', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
