<div class="surface-card px-1 py-1">
  <div class="surface-card p-4 p-fluid">
    <form [formGroup]="supportForm" (ngSubmit)="onSupportSubmit()">
      <div class="grid formgrid p-fluid">
        <ng-container *ngFor="let control of controlList">
          <div
            class="field mb-4 col-12 lg:col-{{
              control.width ? control.width : 12
            }}"
          >
            <label
              *ngIf="control.type != 'checkbox'"
              for="{{ control.name }}"
              class="font-medium text-900"
              >{{ control.label
              }}<span *ngIf="control.validators.required" class="text-red-600"
                >*</span
              ></label
            >
            <input
              [id]="control.name"
              type="text"
              pInputText
              [formControlName]="control.name"
              *ngIf="control.type === 'text'"
              (blur)="supportForm.controls[control.name].markAsDirty()"
            />

            <input
              *ngIf="control.type === 'email'"
              [id]="control.name"
              type="email"
              pInputText
              [formControlName]="control.name"
              (blur)="supportForm.controls[control.name].markAsDirty()"
              pKeyFilter="email"
            />
            <input
              *ngIf="control.type === 'tel'"
              [id]="control.name"
              type="tel"
              pInputText
              [formControlName]="control.name"
              (blur)="supportForm.controls[control.name].markAsDirty()"
              [pValidateOnly]="true"
              [pKeyFilter]="ukPhoneNumberRegex"
              [pattern]="ukPhoneNumberRegex"
            />
            <small
              *ngIf="control.type == 'tel'"
              id="mobile_number-help"
              class="block"
              >You can start with a 0 or +44 when entering your mobile
              number</small
            >

            <p-autoComplete
              formControlName="park_name"
              [suggestions]="this.results"
              (completeMethod)="search($event)"
              *ngIf="control.type === 'autocomplete'"
              [showClear]="true"
              (onBlur)="supportForm.controls[control.name].markAsDirty()"
            ></p-autoComplete>
            <small *ngIf="control.type === 'autocomplete'">
              Start by typing the name of the park and choose the name when it
              pops up</small
            >
            <p-dropdown
              [id]="control.name"
              *ngIf="control.type === 'dropdown'"
              [placeholder]="control.label"
              [options]="control.options"
              [formControlName]="control.name"
              [filter]="control.filter"
              filterBy="label"
              optionLabel="label"
              optionValue="value"
              (onBlur)="supportForm.controls[control.name].markAsDirty()"
            >
            </p-dropdown>

            <textarea
              pInputTextarea
              *ngIf="control.type === 'textarea'"
              [id]="control.name"
              [rows]="5"
              [formControlName]="control.name"
              (blur)="supportForm.controls[control.name].markAsDirty()"
            ></textarea>
            <p-checkbox
              [id]="control.name"
              *ngIf="control.type === 'checkbox'"
              [binary]="true"
              [value]="false"
              [label]="control.label"
              labelStyleClass="text-900 font-medium"
              [formControlName]="control.name"
            ></p-checkbox>

            <p
              *ngIf="control.description > '' && control.type === 'checkbox'"
              class="col-12 block text-md"
            >
              {{ control.description }}
              <span
                *ngIf="
                  control.type === 'checkbox' && control.validators.required
                "
                class="text-red-600"
              >
                * This is a required field
              </span>
            </p>

            <div id="errors">
              <small
                *ngIf="
                  supportForm.controls[control.name].hasError('email') &&
                  supportForm.controls[control.name].touched &&
                  supportForm.controls[control.name].invalid
                "
                [id]="control.name"
                class="p-error m-1"
                >Please enter a valid email address</small
              >
              <small
                *ngIf="
                  supportForm.controls[control.name].hasError('minlength') &&
                  supportForm.controls[control.name].touched &&
                  supportForm.controls[control.name].invalid
                "
                [id]="control.name"
                class="p-error m-1"
                >The field
                <span class="font-bold">{{ control.label }}</span> must be at
                least {{ control.minlength }} characters</small
              >
              <small
                *ngIf="
                  supportForm.controls[control.name].hasError('maxlength') &&
                  supportForm.controls[control.name].touched &&
                  supportForm.controls[control.name].invalid
                "
                [id]="control.name"
                class="p-error m-1"
                >The field
                <span class="font-bold">{{ control.label }}</span> must be at
                most {{ control.maxlength }} characters</small
              >
              <small
                *ngIf="
                  supportForm.controls[control.name].hasError('required') &&
                  supportForm.controls[control.name].touched &&
                  supportForm.controls[control.name].invalid
                "
                [id]="control.name"
                class="p-error m-1"
                >The field <span class="font-bold">{{ control.label }}</span> is
                required</small
              >
            </div>
          </div>
        </ng-container>
        <!-- recaptcha -->
        <div class="field col-12">
          <p-captcha
            siteKey="6LcLjKYpAAAAAItbVBTCWev_86245jVCNEJ7kZNH"
            (onResponse)="setValiditity($event)"
          ></p-captcha>
        </div>
        <!-- errors -->
        <div class="field col-12">
          <p-message
            *ngIf="supportForm.errors"
            severity="error"
            [text]="supportForm.errors | json"
            class="w-full"
          ></p-message>
          <p-message
            *ngIf="formErrors"
            severity="error"
            text="The form is not valid"
            class="w-full"
          ></p-message>
        </div>
        <div class="field col-12">
          <button
            type="submit"
            pButton
            label="Submit"
            class="p-button-info p-button-rounded p-button-lg w-6 lg:w-1"
            [disabled]="!notARobot"
            [loading]="submitLoading"
          ></button>
        </div>
      </div>
      <!-- formgrid -->
    </form>
  </div>
</div>
