import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';

@Component({
  selector: 'app-subscriptions-update-dialog',
  templateUrl: './subscriptions-update-dialog.component.html',
  styleUrls: ['./subscriptions-update-dialog.component.scss'],
})
export class SubscriptionsUpdateDialogComponent implements OnInit {
  formSubscriptionUpdate: FormGroup<any> = this.fb.group({
    date_billing_next: [
      formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      Validators.required,
    ],
  });

  formFields: any[] = [
    {
      controlName: 'date_billing_next',
      label: 'Next Billing Date',
      type: 'date',
      errorMessage: '* Please enter the billing date',
      validators: { required: true },
    },
  ];
  subscription: any;

  defaultDate: Date = new Date();
  minDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private pss: PortalSubscriptionService,
    public notidicationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.config.data.subscription;
    // this.defaultDate = new Date().setFullYear(
    //   this.subscription.billing_date_next,
    // );
  }

  onSubscriptionUpdateSubmit() {
    console.log(
      'onSubscriptionUpdateSubmit',
      this.formSubscriptionUpdate.value,
    );

    let formValuesToSubmit = this.formSubscriptionUpdate.value;

    formValuesToSubmit = {
      ...formValuesToSubmit,
      date_billing_next: formatDate(
        formValuesToSubmit.date_billing_next,
        'yyyy-MM-dd',
        'en',
      ),
    };

    this.pss
      .updatePortalSubscription(this.subscription.id, formValuesToSubmit)
      .subscribe(
        (res) => {
          console.log('res update ', res);
          this.notidicationService.mainSuccess(
            'Successfully updated next billing date to ' +
              formatDate(
                formValuesToSubmit.date_billing_next,
                'yyyy-MM-dd',
                'en',
              ),
          );
          this.ref.close(res);
        },
        (error) => {
          console.log('error update ', error);
          this.notidicationService.mainError(
            'Failed to update next billing date - ' + error.error.message,
          );
        },
      );
  }
}
