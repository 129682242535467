import { Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';
import { ApiService } from '../../../../_core/_services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TocsService {
  tocsQuery$: any;

  tocs: any;
  tocs$: Subject<any[]> = new Subject<any[]>();

  constructor(private api: ApiService) {}

  getTocs(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI('toc', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addToc(value: any) {
    // fixme: get a better way to do this
    delete value.toc_file;
    const new_value = { ...value };
    this.tocsQuery$ = this.api.postAPI('toc', new_value);
    return this.tocsQuery$;
  }

  updateToc(id: any, value: any) {
    console.log('value with file', value);

    delete value.toc_file;
    console.log('value', value);

    // change toc.capture_date from Mon Jul 22 2024 10:49:51 GMT+0200 to "2024-07-04T08:39:22.303000"
    const originalDate = new Date(value.capture_date);

    const formattedDate = originalDate.toISOString();

    value.capture_date = formattedDate;

    const new_value = { ...value };
    // for some reason, we need the serial number in the body even though it is not in the model
    new_value.serial_no = '1234';

    this.tocsQuery$ = this.api.putAPI('toc', id, new_value);
    return this.tocsQuery$;
  }

  deleteToc(id: any) {
    this.tocsQuery$ = this.api.deleteAPI('toc', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
