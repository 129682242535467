<p-tabView (onChange)="changeSalesTab($event)">
  <p-tabPanel header="Active Leads" class="max-h-screen">
    <div class="grid mb-3">
      <div
        class="surface-card border-1 border-200 border-round m-1 p-2 col-12 flex flex-row align-items-center justify-content-start"
      >
        <div class="p-input-icon-left flex-grow-0">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [(ngModel)]="filterValue"
            (ngModelChange)="filterLeads()"
          />
        </div>
        <div class="flex-grow-0 px-2">
          <p-checkbox
            label="Show only leads with upcoming interactions"
            [binary]="true"
            [(ngModel)]="showOnlyWithInteraction"
            (onChange)="filterLeadsOnlyNextInteraction()"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="grid">
      <!-- active lead details -->
      <div
        class="surface-card border-1 border-200 border-round p-4 col-12 lg:col-4"
      >
        <p-progressBar
          *ngIf="leadsService.isLeadsActiveLoading$.getValue()"
          mode="indeterminate"
        ></p-progressBar>

        <div>
          <app-lead-detail
            [lead]="activeLead"
            [loading]="leadsService.isLeadsActiveLoading$.getValue()"
          ></app-lead-detail>
          <button
            pButton
            type="button"
            label="Add Action"
            icon="pi pi-plus"
            class="p-button-rounded p-button-success w-12 mt-3 ml-1"
            (click)="showAddAction($event)"
          ></button>

          <button
            pButton
            pRipple
            label="First Lead"
            class="p-button-rounded w-2 my-1"
            icon="pi pi-angle-double-left"
            iconPos="left"
            (click)="firstLead()"
            [disabled]="!(this.activeIndex >= 1)"
          ></button>
          <button
            pButton
            pRipple
            label="Previous Lead"
            class="p-button-rounded w-3 my-1"
            icon="pi pi-angle-left"
            iconPos="left"
            (click)="previousLead()"
            [disabled]="!(this.activeIndex >= 1)"
          ></button>
          <button
            pButton
            pRipple
            [label]="activeIndex + 1 + ' of ' + leads.length"
            class="p-button-rounded p-button-text w-2 my-1"
            [disabled]="true"
          ></button>

          <button
            pButton
            pRipple
            label="Next Lead"
            class="p-button-rounded w-3 my-1"
            icon="pi pi-angle-right"
            iconPos="right"
            (click)="nextLead()"
            [disabled]="!(this.activeIndex < this.leads.length - 1)"
          ></button>
          <button
            pButton
            pRipple
            label="Last Lead"
            class="p-button-rounded w-2 my-1"
            icon="pi pi-angle-double-right"
            iconPos="right"
            (click)="lastLead()"
            [disabled]="!(this.activeIndex < this.leads.length - 1)"
          ></button>
        </div>

        <!-- end leads -->
      </div>

      <!-- script -->
      <div class="border-1 border-200 border-round p-4 col-12 lg:col-4">
        <div>
          <app-lead-script></app-lead-script>
        </div>
      </div>

      <!-- active lead timeline -->
      <div class="border-1 border-200 border-round p-4 col-12 lg:col-4">
        <div>
          <p-progressBar
            *ngIf="leadsService.isLeadsActiveLoading$.getValue()"
            mode="indeterminate"
          ></p-progressBar>
          <div>
            <app-lead-timeline [lead]="activeLead"></app-lead-timeline>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Parked Leads">
    <div class="grid">
      <!-- active lead details -->
      <div
        class="surface-card border-1 border-200 border-round p-4 col-12 text-center py-5"
        *ngIf="!activeLeadPersonal"
      >
        <span class="text-800 text-3xl"
          >You have no qualified or parked leads at the moment</span
        >
      </div>
      <div
        class="surface-card border-1 border-200 border-round m-1 p-2 col-12 flex flex-row align-items-center justify-content-start"
      >
        <div *ngIf="activeLeadPersonal" class="p-input-icon-left flex-grow-0">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [(ngModel)]="filterPersonalValue"
            (ngModelChange)="filterPersonalLeads()"
          />
        </div>
      </div>

      <div
        class="surface-card border-1 border-200 border-round p-4 col-12 lg:col-6"
        *ngIf="activeLeadPersonal"
      >
        <app-lead-detail
          [lead]="activeLeadPersonal"
          [loading]="leadsService.isLeadsPersonalLoading$.getValue()"
        ></app-lead-detail>
        <button
          pButton
          type="button"
          label="Add Action"
          icon="pi pi-plus"
          class="p-button-rounded p-button-success w-12 mt-3 ml-1"
          (click)="showAddActionPersonal($event)"
        ></button>
        <button
          pButton
          pRipple
          label="Previous Lead"
          class="p-button-rounded w-4 my-1"
          icon="pi pi-arrow-left"
          iconPos="left"
          (click)="previousLeadPersonal()"
          [disabled]="!(this.activePersonalIndex >= 1)"
        ></button>
        <button
          pButton
          pRipple
          [label]="activePersonalIndex + 1 + ' of ' + leadsPersonal.length"
          class="p-button-rounded p-button-text w-4 my-1"
          [disabled]="true"
        ></button>
        <button
          pButton
          pRipple
          label="Next Lead"
          class="p-button-rounded w-4 my-1"
          icon="pi pi-arrow-right"
          iconPos="right"
          (click)="nextLeadPersonal()"
          [disabled]="
            !(this.activePersonalIndex < this.leadsPersonal.length - 1)
          "
        ></button>
      </div>

      <div
        class="surface-card border-1 border-200 border-round p-4 col-12 lg:col-6"
        *ngIf="activeLeadPersonal"
      >
        <!-- todo: list of parked/qualified leads -->
        <app-lead-timeline [lead]="activeLeadPersonal"></app-lead-timeline>
      </div>
    </div>
  </p-tabPanel>
  <!-- <p-tabPanel header="Closed Leads" *ngIf="false"></p-tabPanel> -->
</p-tabView>
