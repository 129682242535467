import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  pageAmountOptions: any = [50, 100, 200];
  userPreferences: any = { pageAmount: 50, theme: 'light' };

  constructor() {}

  getUserPreferences() {
    this.userPreferences = JSON.parse(
      localStorage.getItem('user-preferences') ??
        JSON.stringify(this.userPreferences)
    );
  }

  setUserPreferenceByKey(key: string, value: any) {
    console.log(this.userPreferences);
    this.userPreferences[key] = value;
    localStorage.setItem(
      'user-preferences',
      JSON.stringify(this.userPreferences)
    );
  }
}
