import { Injectable } from '@angular/core';
import { ApiService } from '../../../../_core/_services/api.service';
import { map, Subject } from 'rxjs';
import { EndCustomer } from 'src/app/_share/_models/entities/schema';

@Injectable({
  providedIn: 'root',
})
export class EndCustomersService {
  endCustomersQuery$: any;

  endCustomers: any[] = [];
  endCustomers$: Subject<any[]> = new Subject<any[]>();

  constructor(public api: ApiService) {}

  getEndCustomers(queryParams?: any) {
    if (queryParams) {
      queryParams = {
        ...queryParams,
      };
    } else {
      queryParams = {
        limit: 100,
        offset: 0,
        q: '',
        filter: [],
      };
    }
    return this.api.getAPI<EndCustomer>('endcustomer', queryParams).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  addEndCustomer(value: any) {
    const new_value = {
      ...value,
      // tenant_id: 1,
    };
    this.endCustomersQuery$ = this.api.postAPI<any>('endcustomer', new_value);
    return this.endCustomersQuery$;
  }

  updateEndCustomer(id: any, value: any) {
    const new_value = {
      ...value,
      tenant_id: 1,
    };
    this.endCustomersQuery$ = this.api.putAPI<any>(
      'endcustomer',
      id,
      new_value
    );

    return this.endCustomersQuery$;
  }

  deleteEndCustomer(id: any) {
    this.api.deleteAPI<any>('endcustomer', id).subscribe((res) => {
      //console.log('res', res);
    });
  }
}
