import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_share/_models/entities/user';
import { UserService } from 'src/app/_share/_services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  openEditUserProfileDialog() {
    throw new Error('Method not implemented.');
  }
  constructor(public userService: UserService) {}

  ngOnInit(): void {
    this.userService.getCurrentLoggedInUser();
  }
}
