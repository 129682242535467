import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PortalCustomersService } from 'src/app/features/portal/_services/portal-customers.service';
import { CustomerDialogComponent } from '../customer-dialog/customer-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { UpdateCustomerComponent } from './update-customer/update-customer.component';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { Table } from 'primeng/table';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  customers: any[] = [];
  customers$: Subject<any[]> = new Subject<any[]>();
  isLoading: boolean = true;
  selectedCustomer: any;
  items: MenuItem[] = [];
  isError: boolean = false;

  columns: any[] = [
    { field: 'first_name', header: 'Customer Name' },
    { field: 'last_name', header: 'Customer Surname' },
    { field: 'identification_reference', header: 'ID' },
    { field: 'identification_type', header: 'ID Type' },
    { field: 'mobile_number', header: 'Mobile' },
    { field: 'email', header: 'Email' },
    { field: 'complex_building', header: 'Complex Building' },
    { field: 'unit_number', header: 'Unit Number' },
    { field: 'street_address', header: 'Street Address' },
    { field: 'postal_address', header: 'Postal Address' },
    { field: 'suburb', header: 'Suburb' },
    { field: 'city', header: 'City' },
    { field: 'province', header: 'Province' },
    { field: 'postal_code', header: 'Postal Code' },
    { field: 'active', header: 'Active' },
  ];

  constructor(
    private customersService: PortalCustomersService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private portalUserService: PortalUsersService,
  ) {}

  ngOnInit(): void {
    this.retrievePortalcustomers();
    this.items = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: (event) => {
          this.showUpdateUserDialog(this.selectedCustomer);
        },
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: (event) => {
          this.confirmUser(event);
        },
      },
    ];
  }

  retrievePortalcustomers() {
    this.isLoading = true;
    this.customersService.getPortalCustomers().subscribe(
      (res: any) => {
        this.customers = res;
        console.log(this.customers);
        this.customers$.next(this.customers);
        this.isLoading = false;
      },
      (error) => {
        console.log('Error ', error);
        this.isLoading = false;
        this.isError = true;
      },
    );
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  showUserDetailsDialog(rowData: any, event: Event) {
    // Check if the target of the click event was the ellipsis button
    if ((event.target as HTMLElement).classList.contains('pi-ellipsis-v')) {
      // Clicked on the ellipsis button, don't open the dialog
      return;
    } else {
      // Clicked on the row, open the dialog
      const ref = this.dialogService.open(CustomerDialogComponent, {
        data: {
          user: rowData,
        },

        width: '90%',
      });
    }
  }

  showUpdateUserDialog(rowData: any) {
    const ref = this.dialogService.open(UpdateCustomerComponent, {
      data: {
        user: rowData,
      },
      width: '75%',
      contentStyle: {
        margin: '0',
        padding: '0',
        height: '100%',
        overflow: 'auto',
      },
    });
  }

  confirmUser($event: any) {
    console.log('Event: ', $event);

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      key: 'delete',
      accept: () => {
        this.portalUserService.deletePortalUser(this.selectedCustomer.id);
      },
      reject: () => {
        console.log('Delete cancelled');
      },
    });
  }

  clear(table: Table) {
    table.clear();
  }
}
