<p-toast position="top-left"></p-toast>
<div class="surface-card p-4 lg:col-6 col-12 lg:col-offset-3 flex-grow-1">
  <div class="text-center mb-5">
    <!-- <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
    <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
    <!-- <span class="text-600 font-medium line-height-3"
      >Don't have an account?</span
    >
    <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
      >Create today!</a
    > -->
  </div>

  <div>
    <form [formGroup]="portalUserForm" (ngSubmit)="onFormSubmit()">
      <label for="username" class="block text-900 font-medium mb-2"
        >Username</label
      >
      <input
        type="text"
        formControlName="username"
        class="form-control"
        pInputText
        class="w-full mb-3"
      />

      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
        <p-message
          *ngIf="f.username.errors['required']"
          severity="error"
          text="Incorrect Username"
          styleClass="w-full"
        ></p-message>
      </div>

      <label for="password" class="block text-900 font-medium mb-2"
        >Password</label
      >
      <input
        type="password"
        formControlName="password"
        class="form-control"
        pInputText
        class="w-full mb-3"
      />

      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <p-message
          *ngIf="f.password.errors['required']"
          severity="error"
          text="Incorrect Password"
          styleClass="w-full"
        ></p-message>
      </div>

      <div class="flex align-items-center justify-content-between mb-6">
        <span class="text-red-500 font-bold pt-3">{{ errorMessages }}</span>
      </div>

      <button
        pButton
        pRipple
        [disabled]="loading"
        label="Sign In"
        class="w-full mb-3"
        icon="pi pi-user"
        type="submit"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
        {{ error }}
      </div>
    </form>
  </div>
</div>
