import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
import { PortalAuthenticationService } from '../../features/portal/_services/portal-authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private portalAuthenticationService: PortalAuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.authenticationService.userValue;
    const isApiUrl =
      request.url.startsWith(environment.apiUrl) ||
      (request.url.startsWith(environment.apiUrl) &&
        request.url.includes('/portal/management/'));
    const isLoggedIn = user?.access_token;

    const portalUser = this.portalAuthenticationService.portalUserValue;
    const isPortalLoggedIn = portalUser?.access_token;
    const isPortalApiUrl =
      request.url.startsWith(environment.apiUrl) &&
      request.url.includes('/portal/');

    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
    } else if (isPortalLoggedIn && isPortalApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${portalUser.access_token}`,
        },
      });
    }

    return next.handle(request);
  }
}
