import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ERoles } from '../_models/entities/roles';

@Directive({
  selector: '[hasRoles]',
})
export class HasRoleDirective implements OnChanges {
  private visible: boolean = false;
  private roles: ERoles[] = [];
  private user: any;

  @Input() set hasRoles(roles: ERoles[]) {
    this.roles = roles;
  }

  @Input() set hasRolesFor(user: any) {
    this.user = user;
  }
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this.user || !this.user.role || !this.user.role.name) {
      return;
    }

    const userRole = this.user.role.name;

    if (this.roles.includes(userRole)) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.visible = true;
    } else {
      this.viewContainer.clear();
      this.visible = false;
    }
  }
}
