import { Pipe, PipeTransform } from '@angular/core';

const TRUNCATE_LENGTH: number = 20;

@Pipe({
  name: 'truncateFilename',
})
export class TruncateFilenamePipe implements PipeTransform {
  // transform(name: string): string {
  //   const ext: string = name
  //     .substring(name.lastIndexOf('.') + 1, name.length)
  //     .toLowerCase();
  //   let newName: string = name.replace('.' + ext, '');
  //   if (name.length <= TRUNCATE_LENGTH) {
  //     return name;
  //   }
  //   newName =
  //     newName.substring(0, TRUNCATE_LENGTH) +
  //     (name.length > TRUNCATE_LENGTH ? '...' : '');
  //   return newName + '.' + ext;
  // }

  transform(value: string, maxLength: number): string {
    const ellipsis = '...';

    if (value.length <= maxLength) {
      return value;
    } else {
      const truncatedLength = maxLength - ellipsis.length;
      const start = value.substr(0, Math.floor(truncatedLength / 2));
      const end = value.substr(value.length - Math.ceil(truncatedLength / 2));

      return `${start}${ellipsis}${end}`;
    }
  }
}
