import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubNavComponent } from './components/sub-nav/sub-nav.component';
import { NavigationComponent } from './pages/navigation/navigation.component';
import { PrimeModule } from '../prime.module';
import { HasRoleDirective } from './_directives/has-role.directive';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DateAsAgoPipe } from './_pipes/date-as-ago.pipe';
import { BoolAsStringPipe } from './_pipes/bool-as-string.pipe';
import { FilesizePipe } from './_pipes/filesize.pipe';
import { NestedKeyvaluePipe } from './_pipes/nested-keyvalue.pipe';
import { TruncateFilenamePipe } from './_pipes/truncate-filename.pipe';
import { ShareSubscriptionDetailsComponent } from './components/share-subscription-details/share-subscription-details.component';
import { RemoveSPipe } from './_pipes/remove-s.pipe';
import { ShareUserCustomerDetailsComponent } from './components/share-user-customer-details/share-user-customer-details.component';
import { ShareFormComponent } from './components/share-form/share-form.component';
import { AnyAsStatusStringPipe } from './_pipes/any-as-status-string';
import { ShareUserFilesComponent } from './components/share-user-files/share-user-files.component';
import { ShareSubscriptionActionBarComponent } from './components/share-subscription-action-bar/share-subscription-action-bar.component';
import { ShareTableComponent } from './components/share-table/share-table.component';

@NgModule({
  declarations: [
    NavigationComponent,
    SubNavComponent,

    LoginComponent,
    ForgotPasswordComponent,

    // directives
    HasRoleDirective,

    // pipes
    DateAsAgoPipe,
    BoolAsStringPipe,
    FilesizePipe,
    NestedKeyvaluePipe,
    TruncateFilenamePipe,
    RemoveSPipe,
    AnyAsStatusStringPipe,

    // components
    ShareSubscriptionDetailsComponent,
    ShareUserCustomerDetailsComponent,
    ShareFormComponent,
    ShareUserFilesComponent,
    ShareSubscriptionActionBarComponent,
    ShareTableComponent,
  ],
  imports: [CommonModule, PrimeModule],
  exports: [
    NavigationComponent,
    SubNavComponent,
    LoginComponent,
    ForgotPasswordComponent,
    HasRoleDirective,
    DateAsAgoPipe,
    BoolAsStringPipe,
    FilesizePipe,
    NestedKeyvaluePipe,
    TruncateFilenamePipe,
    RemoveSPipe,
    AnyAsStatusStringPipe,

    // components
    ShareSubscriptionDetailsComponent,
    ShareUserCustomerDetailsComponent,
    ShareFormComponent,
    ShareUserFilesComponent,
    ShareSubscriptionActionBarComponent,
    ShareTableComponent,
  ],
})
export class ShareModule {}
