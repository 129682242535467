<div class="grid" style="max-width: 100vw">
  <div class="col-4">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">General Settings</div>
      <div class="font-medium text-900 mb-3"></div>
    </div>
  </div>
  <div class="col-4">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">User Preferences</div>
      <div class="font-medium text-900 mb-3"></div>
      <app-user-preferences></app-user-preferences>
    </div>
  </div>
  <div class="col-4">
    <div class="surface-card shadow-1 border-round p-4">
      <div class="text-3xl font-medium text-900 mb-3">User Profile</div>
      <div class="font-medium text-900 mb-3"></div>
      <app-user-profile></app-user-profile>
    </div>
  </div>
</div>

