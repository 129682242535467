import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessSalesViewComponent } from './pages/business-sales-view/business-sales-view.component';
import { BusinessSalesRoutingModule } from './business-sales-routing.module';
import { PrimeModule } from 'src/app/prime.module';
import { ShareModule } from 'src/app/_share/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessSalesQuestionsComponent } from './components/business-sales-questions/business-sales-questions.component';
import { BusinessSalesComponent } from './pages/business-sales/business-sales.component';
import { BusinessSalesInfoComponent } from './components/business-sales-info/business-sales-info.component';
import { BusinessSalesSummaryComponent } from './components/business-sales-summary/business-sales-summary.component';
import { BusinessSalesScriptComponent } from './components/business-sales-script/business-sales-script.component';

@NgModule({
  declarations: [
    BusinessSalesComponent,
    BusinessSalesViewComponent,
    BusinessSalesQuestionsComponent,
    BusinessSalesInfoComponent,
    BusinessSalesSummaryComponent,
    BusinessSalesScriptComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    PrimeModule,

    BusinessSalesRoutingModule,
    ShareModule,
  ],
  exports: [],
  providers: [],
})
export class BusinessSalesModule {}
