<ng-container *ngIf="config.data.status && config.data.status == 'success'">
  <ng-container
    *ngIf="
      config.data.status && config.data.accountdata.status == 'new-user';
      else existingUser
    "
  >
    <div class="flex flex-column align-items-center my-4">
      <span
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
        style="width: 64px; height: 64px"
      >
        <i class="pi pi-check text-5xl"></i>
      </span>
      <!-- <div class="font-medium text-2xl text-900">{{ this.config.data | json }}</div> -->
    </div>
    <p class="line-height-3 p-0 m-0 text-xl text-gray-600 text-center">
      Your payment for the order has been successfully processed and your access
      provisioned for the
      <span class="text-blue-600">{{
        config.data.accountdata?.package?.name
      }}</span
      >.
    </p>
    <h4 class="text-green-700 text-2xl text-center">
      Please keep the following information safe to access your Wi-Fi service.
    </h4>
    <p class="p-0 m-0 text-xl text-gray-600 text-center">
      (You can take a screenshot of this page for your reference)
    </p>
    <div class="flex flex-column align-items-center my-4">
      <div
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
      >
        <i class="pi pi-user text-5xl"></i>
      </div>
      <div class="font-medium text-2xl text-900">Username:</div>
      <div class="text-xl text-gray-600">
        {{ config.data.accountdata?.solidcode }}
      </div>

      <div
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3 mt-5"
      >
        <i class="pi pi-key text-5xl"></i>
      </div>
      <div class="font-medium text-2xl text-900">Password:</div>
      <div class="text-xl text-gray-600">
        {{ config.data.accountdata?.customer?.password }}
      </div>
    </div>
    <div class="flex align-items-center justify-content-center py-2">
      <p-toggleButton
        [offLabel]="
          'Tick here to confirm you\'ve taken note of your credentials as you\'ll need these to connect'
        "
        [onLabel]="
          'I confirm I\'ve taken note of my credentials as I\'ll need these to connect (copied to your clipboard)'
        "
        [offIcon]="'pi pi-check'"
        [onIcon]="'pi pi-times'"
        [(ngModel)]="checkedCredentials"
        styleClass="{{
          checkedCredentials ? 'bg-cyan-100 text-cyan-800' : ''
        }}  text-xl"
        (onChange)="copyToClipboard()"
      ></p-toggleButton>
    </div>
    <div class="flex align-items-center justify-content-center py-2">
      <button
        *ngIf="checkedCredentials"
        pButton
        class="p-button-danger p-button-lg"
        label="Dismiss"
        (click)="dismissDialog()"
      ></button>
    </div>
  </ng-container>
  <ng-template #existingUser>
    <div class="flex flex-column align-items-center my-4">
      <span
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
        style="width: 64px; height: 64px"
      >
        <i class="pi pi-check text-5xl"></i>
      </span>
      <!-- <div class="font-medium text-2xl text-900">{{ this.config.data | json }}</div> -->
    </div>
    <p class="line-height-3 p-0 m-0 text-xl text-gray-600 text-center">
      Your payment for the order has been successfully processed and your access
      provisioned for the
      <span class="text-blue-600">{{
        config.data.accountdata?.package?.name
      }}</span
      >.
    </p>
    <h4 class="text-green-700 text-2xl text-center">
      Please keep the following information safe to access your Wi-Fi service.
    </h4>

    <div class="flex flex-column align-items-center my-4">
      <div
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
      >
        <i class="pi pi-user text-5xl"></i>
      </div>
      <div class="font-medium text-2xl text-900">Username:</div>
      <div class="text-xl text-gray-600">
        {{ config.data.accountdata?.solidcode }}
      </div>

      <div
        class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3 mt-5"
      >
        <i class="pi pi-key text-5xl"></i>
      </div>
      <div class="font-medium text-2xl text-900">Password:</div>
      <div class="text-xl text-gray-600">
        (The password was previously set and has not changed.)
      </div>
    </div>
    <div class="flex align-items-center justify-content-center py-2">
      <p-toggleButton
        [offLabel]="
          'Tick here to confirm you\'ve taken note of your credentials as you\'ll need these to connect'
        "
        [onLabel]="
          'I confirm I\'ve taken note of my credentials as I\'ll need these to connect'
        "
        [offIcon]="'pi pi-check'"
        [onIcon]="'pi pi-times'"
        [(ngModel)]="checkedCredentials"
        styleClass="{{
          checkedCredentials ? 'bg-cyan-100 text-cyan-800' : ''
        }}  text-xl"
      ></p-toggleButton>
    </div>
    <div class="flex align-items-center justify-content-center py-2">
      <button
        *ngIf="checkedCredentials"
        pButton
        class="p-button-danger p-button-lg"
        label="Dismiss"
        (click)="dismissDialog()"
      ></button>
    </div>
  </ng-template>
</ng-container>
