import { Component, Input, OnInit } from '@angular/core';
import { PortalSubscriptions } from '../../_models/portal/portal-subscriptions.model';

type NestedPortalSubscriptions =
  | keyof PortalSubscriptions
  | 'portal_product.product_type'
  | 'portal_product.speed_down_mbps'
  | 'portal_product.speed_up_mbps'
  | 'portal_product.recurring_description'
  | 'portal_product.renewal_type'
  | 'portal_product.duration_days';

interface SubscriptionField {
  field: NestedPortalSubscriptions;
  header: string;
  type: 'text' | 'date' | 'string' | 'number' | 'currency' | 'status';
}

@Component({
  selector: 'share-subscription-details',
  templateUrl: './share-subscription-details.component.html',
  styleUrls: ['./share-subscription-details.component.scss'],
})
export class ShareSubscriptionDetailsComponent implements OnInit {
  subscriptionFieldsToDisplay: SubscriptionField[] = [
    { field: 'id', header: 'ID', type: 'number' },
    {
      field: 'subscription_account_reference',
      header: 'Account Reference',
      type: 'text',
    },
    { field: 'date_activated', header: 'Start Date', type: 'date' },
    { field: 'date_initialised', header: 'Date Initialised', type: 'date' },
    { field: 'date_billing_next', header: 'Next Billing Date', type: 'date' },
    {
      field: 'portal_product.product_type',
      header: 'Product Type',
      type: 'text',
    },
    {
      field: 'portal_product.speed_down_mbps',
      header: 'Download Speed',
      type: 'number',
    },
    {
      field: 'portal_product.speed_up_mbps',
      header: 'Upload Speed',
      type: 'number',
    },
    {
      field: 'portal_product.recurring_description',
      header: 'Description',
      type: 'text',
    },
    {
      field: 'portal_product.renewal_type',
      header: 'Renewal Type',
      type: 'text',
    },
    {
      field: 'portal_product.duration_days',
      header: 'Duration Days',
      type: 'number',
    },
    { field: 'account_status', header: 'Account Status', type: 'status' },
    {
      field: 'subscription_status',
      header: 'Subscription Status',
      type: 'status',
    },
    { field: 'billing_auto_renew', header: 'Auto Renew', type: 'status' },
    {
      field: 'preferred_payment_method',
      header: 'Payment Method',
      type: 'text',
    },
    // { field: 'application_type', header: 'Application Type', type: 'text' },
    { field: 'device_reference', header: 'Device Reference', type: 'text' },
    {
      field: 'subscription_reference_external',
      header: 'External Reference',
      type: 'text',
    },

    { field: 'billing_cycle_type', header: 'Billing Cycle', type: 'text' },
    {
      field: 'subscription_reference',
      header: 'Subscription Reference',
      type: 'text',
    },
  ];

  @Input() subscription: PortalSubscriptions | undefined;

  constructor() {}

  ngOnInit(): void {}

  getFieldValue(
    subscription: PortalSubscriptions | undefined,
    field: NestedPortalSubscriptions
  ): any {
    if (!subscription) {
      return null;
    }

    const fieldParts = field.split('.');
    let value: any = subscription;
    for (const part of fieldParts) {
      if (value) {
        value = value[part];
      } else {
        return null;
      }
    }
    return value;
  }

  // todo: implement severity check for account status / subscription status
  getSeverity(status: string): string {
    switch (status) {
      case 'paid':
        return 'success';
      case 'unpaid':
        return 'danger';
      case 'overdue':
        return 'warning';
      default:
        return 'info';
    }
  }
}
