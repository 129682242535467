import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalActionsService {
  constructor(private apiService: ApiService) {}

  public getPortalActions() {
    return this.apiService
      .getAPI('portal/management/subscriptions/actions')
      .pipe(
        map((res: any) => {
          console.log('res sub actions ', res);
          return res.body.data;
        })
      );
  }

  public getUserActions() {
    return this.apiService.getAPI('portal/management/user/actions').pipe(
      map((res: any) => {
        console.log('res subs ', res);
        return res.body.data;
      })
    );
  }
}
