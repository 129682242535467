import { Component, OnInit } from '@angular/core';
import { PortalActionsService } from 'src/app/features/portal/_services/portal-actions.service';
import { BehaviorSubject } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-subscription-actions',
  templateUrl: './subscription-actions.component.html',
  styleUrls: ['./subscription-actions.component.scss'],
})
export class SubscriptionActionsComponent implements OnInit {
  subscriptionActions: any[] = [];
  subscriptionActions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  isError: boolean = false;
  isLoading: boolean = true;

  columns: any[] = [
    { field: 'action_date', header: 'Action Date' },
    { field: 'action_description', header: 'Action Description' },
    { field: 'action_type', header: 'Action Type' },
    { field: 'id', header: 'ID' },
    { field: 'portal_subscription_id', header: 'Subscription ID' },
  ];

  constructor(private portalActionsService: PortalActionsService) {}

  ngOnInit(): void {
    this.portalActionsService.getPortalActions().subscribe(
      (res: any) => {
        this.subscriptionActions = res;
        this.subscriptionActions$.next(this.subscriptionActions);
        this.isLoading = false;
      },
      (error) => {
        console.log('Error ', error);
        this.isError = true;
      }
    );
  }

  clear(table: Table) {
    table.clear();
  }

  getNumberofColumns() {
    return this.columns.length;
  }
}
