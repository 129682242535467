import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-successful-login-dialog',
  templateUrl: './successful-login-dialog.component.html',
  styleUrls: ['./successful-login-dialog.component.scss'],
})
export class SuccessfulLoginDialogComponent implements OnInit {
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {}
}
