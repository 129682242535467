import { Injectable } from '@angular/core';

import { ApiService } from '../../../_core/_services/api.service';

import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalRegisterService {
  constructor(private api: ApiService) {}

  registerUser(data: any) {
    return this.api.postAPI('portal/register-user', data).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  signupUser(data: any) {
    return this.api.postAPI('portal/signup-user', data).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }

  createSupportRequest(data: any) {
    return this.api.postAPI('portal/support-request', data).pipe(
      catchError((err) => {
        throw err;
      }),
    );
  }
}
