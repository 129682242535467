<form [formGroup]="keyIndividualForm" (ngSubmit)="onKeyIndividualSubmit()">
  <div class="grid formgrid p-fluid justify-content-center align-items-center">
    <!-- name -->
    <div class="field w-12 col-12 md:w-6 md:col-6">
      <label for="name">Name</label>
      <input id="name" pInputText formControlName="name" />
    </div>
    <!-- email -->
    <div class="field w-12 col-12 md:w-6 md:col-6">
      <label for="email">Email Address</label>
      <input id="email" pInputText formControlName="email" />
    </div>
    <!-- phone-->
    <div class="field w-12 col-12 md:w-6 md:col-6">
      <label for="phone">Phone</label>
      <input id="phone" pInputText formControlName="phone" />
    </div>
    <!-- job_title -->
    <div class="field w-12 col-12 md:w-6 md:col-6">
      <label for="job_title">Job Title</label>
      <input id="job_title" pInputText formControlName="job_title" />
    </div>

    <div hidden>
      <input id="company_id" pInputText formControlName="company_id" />
    </div>
    <button
      pButton
      type="submit"
      label="Save Key Individual"
      icon="pi pi-save"
      class="p-button-rounded p-button-success my-1"
    ></button>
    <button
      *ngIf="keyIndividualForm.get('id')?.value"
      pButton
      label="Delete Key Individual"
      icon="pi pi-trash"
      class="p-button-rounded p-button-danger p-button-outlined my-1"
      (click)="onKeyIndividualDelete()"
      type="button"
    ></button>
  </div>
</form>
