<!-- 


 -->
<p-contextMenu #cm [model]="items"></p-contextMenu>
<div>
  <p-table
    styleClass="p-datatable-striped "
    #packagesTable
    id="packagesTable"
    [value]="(packages$ | async) || []"
    scrollHeight="40vh"
    [contextMenu]="cm"
    [(contextMenuSelection)]="selectedProduct"
    [scrollable]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file"
          (click)="packagesTable.exportCSV()"
          class="mr-2"
          pTooltip="CSV"
          tooltipPosition="bottom"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          (click)="exportExcel()"
          class="p-button-success mr-2"
          pTooltip="XLS"
          tooltipPosition="bottom"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-pdf"
          (click)="exportPdf()"
          class="p-button-warning mr-2"
          pTooltip="PDF"
          tooltipPosition="bottom"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-filter"
          (click)="packagesTable.exportCSV({ selectionOnly: true })"
          class="p-button-info"
          pTooltip="Selection Only"
          tooltipPosition="bottom"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          label="New Product"
          class="p-button-success ml-auto"
          (click)="addPackage()"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          <div class="flex align-items-center">
            {{ col.header }}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package>
      <tr
        style="cursor: pointer"
        [pSelectableRow]="package"
        [pContextMenuRow]="package"
      >
        <td *ngFor="let col of columns">
          <div
            class="flex align-items-center"
            *ngIf="
              col.field != 'recurring_price_incl' &&
              col.field != 'recurring_price_excl' &&
              col.field != 'onceoff_price_excl' &&
              col.field != 'onceoff_price_incl'
            "
          >
            {{ package[col.field] }}
          </div>

          <div
            class="flex align-items-center"
            *ngIf="
              col.field == 'recurring_price_incl' ||
              col.field == 'recurring_price_excl' ||
              col.field == 'onceoff_price_excl' ||
              col.field == 'onceoff_price_incl'
            "
          >
            {{ package[col.field] | currency : "R" }}
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or
              offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-confirmDialog header="Delete"></p-confirmDialog>
</div>
