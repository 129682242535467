import { Component, OnInit } from '@angular/core';
import { PortalFilesService } from 'src/app/features/portal/_services/portal-files.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { PortalCustomersService } from 'src/app/features/portal/_services/portal-customers.service';
import { PortalSubscriptionService } from 'src/app/features/portal/_services/portal-subscription.service';
import { PortalTransactionsService } from 'src/app/features/portal/_services/portal-transactions.service';
import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { CacheService } from 'src/app/_core/_services/cache.service';
import { PortalSubscriptions } from 'src/app/_share/_models/portal/portal-subscriptions.model';
import { PortalFiles } from 'src/app/_share/_models/portal/interfaces';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.scss'],
})
export class CustomerDialogComponent implements OnInit {
  files: any = [];
  files$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  columns: any = [{ field: 'unique_file_reference', header: 'download file' }];
  portal_user: any;

  portalSubscription: PortalSubscriptions[] = [];
  portalSubscription$: Subject<any> = new Subject<any>();

  portalCustomer: any;
  portalCustomer$: Subject<any> = new Subject<any>();

  portalTransactions: any;
  portalTransactions$: Subject<any> = new Subject<any>();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private portalFilesService: PortalFilesService,
    private portalCustomersService: PortalCustomersService,
    private portalSubscriptionService: PortalSubscriptionService,
    private portalUsersService: PortalUsersService,
    private cacheService: CacheService
  ) {}

  ngOnInit(): void {
    const user = this.config.data.user;
    console.log('portal user: ', user);
    this.getFiles(user.id);

    const portalUser = this.cacheService.getItem('portalUser');
    console.log('portalUser: ', portalUser);
    this.portalSubscriptionService
      .getPortalSubscriptionByCustomer(user && user.id)
      .subscribe((res) => {
        this.portalSubscription = res;
        this.portalSubscription$.next(res);
        console.log('portalSubscription: ', this.portalSubscription);
      });

    this.portalUsersService
      .getPortalUserById(user && user.id)
      .subscribe((res) => {
        this.portalCustomer = res;
        this.portalCustomer$.next(res);
        console.log('portalCustomer: ', this.portalCustomer);
      });
  }

  getFiles(portal_customer_id: number) {
    this.portalFilesService
      .getFiles(Number(portal_customer_id))
      .subscribe((data: PortalFiles[]) => {
        this.files = data;
        this.files$.next(this.files);
      });
  }

  downloadFile(file_id: string, fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    this.portalFilesService.downloadFile(file_id, extension);
  }
}
